/* eslint-disable no-console */
'use client';

import {
  Box,
  Checkbox,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  FormLabel,
  Heading,
  Menu,
  MenuButton,
  MenuList,
  Text,
  useClipboard,
  useDisclosure,
} from '@chakra-ui/react';
import { formatPhoneNumber } from '@cryptofi/core-ui';
import dayjs from 'dayjs';
import { camelizeKeys } from 'humps';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { KYCStatusEnum } from '~/codegen/types';
import { useFeatureSetEnabled, useGetFiInfo, useGetSystemStatus, useGetUser, useGlobalStore } from '~/hooks';
// eslint-disable-next-line no-restricted-imports
import useAxios from '~/hooks/api/useAxios';
import isDev from '~/utils/isDev';

import Actions from './Actions';
import Button from './Button';
import ConsoleButton from './ConsoleButton';
import DataGrid from './DataGrid';
import Links from './Links';
import MenuItem from './MenuItem';
import SystemStatus from './SystemStatus';

const DevConsole = ({
  setShowBreakpointDebugger,
}: {
  setShowBreakpointDebugger: Dispatch<SetStateAction<boolean>>;
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [userAuthInfo] = useGlobalStore((state) => [state.userAuthInfo]);
  const fiInfo = useGetFiInfo();
  const user = useGetUser();
  const status = useGetSystemStatus();
  const statusData = camelizeKeys(status.data); // TODO move camelize to hook
  const axios = useAxios();
  const { isEnabled } = useFeatureSetEnabled();

  const [enableRefetch, setEnableRefetch] = useState(true);
  const [hideButton, setHideButton] = useState(false);
  const [hasSystemOverride, setHasSystemOverride] = useState(false);

  const { isLocalDev } = isDev();
  const uiColor = isLocalDev ? 'green' : 'yellow';

  // check if refetch is disabled in local storage
  useEffect(() => {
    const enable = localStorage.getItem('enableRefetch');
    setEnableRefetch(!enable);
  }, [setEnableRefetch]);

  // listen for ctrl + d to toggle dev console
  useEffect(() => {
    const handleKeydown = (e: KeyboardEvent) => {
      if (e.ctrlKey && e.key === 'd') {
        if (isOpen) {
          onClose();
        } else {
          onOpen();
        }
      }
    };

    document.addEventListener('keydown', handleKeydown);

    return () => {
      document.removeEventListener('keydown', handleKeydown);
    };
  }, [isOpen, onClose, onOpen]);

  // listen for ctrl + j to toggle button (for product demos, looms, etc)
  useEffect(() => {
    const handleKeydown = (e: KeyboardEvent) => {
      if (e.ctrlKey && e.key === 'j') {
        if (hideButton) {
          setHideButton(false);
        } else {
          setHideButton(true);
        }
      }
    };

    document.addEventListener('keydown', handleKeydown);

    return () => {
      document.removeEventListener('keydown', handleKeydown);
    };
  }, [hideButton, setHideButton]);

  useEffect(() => {
    if (localStorage.getItem('systemState')) {
      setHasSystemOverride(true);
    }
  }, []);

  const fiRows = [
    { label: 'FI ID', value: user.data?.fiId },
    { label: 'Name', value: fiInfo.data?.fiName },
    {
      label: 'Crypto Enabled',
      value: (
        <Box as="span" fontFamily="body" color={isEnabled(['crypto']) ? 'inherit' : 'gray.500'}>
          {isEnabled(['crypto']) ? '✔' : '✘'}
        </Box>
      ),
    },
    {
      label: 'Securities Enabled',
      value: (
        <Box as="span" fontFamily="body" color={isEnabled(['securities']) ? 'inherit' : 'gray.500'}>
          {isEnabled(['securities']) ? '✔' : '✘'}
        </Box>
      ),
    },
  ];

  const getUserName = () => {
    const { firstName, middleName, lastName } = user.data?.userInfo || {};
    return [firstName, middleName, lastName].filter(Boolean).join(' ');
  };

  const userRows = [
    {
      label: 'Account ID',
      value: user.data?.userAccountId,
    },
    {
      label: 'Name',
      value: getUserName(),
    },
    {
      label: 'Email',
      value: user.data?.userInfo?.email,
    },
    {
      label: 'Phone',
      value: formatPhoneNumber(user.data?.userInfo?.phone || ''),
    },
    {
      label: 'Crypto KYC Status',
      value: user.data?.cryptoKycStatus,
    },
    {
      label: 'Securities KYC Status',
      value: user.data?.securitiesKycStatus,
    },
    {
      label: 'Securities Risk Profile',
      value: user.data?.securitiesRiskProfile,
    },
  ];

  const systemRows = [
    {
      label: 'Crypto Available',
      value: <SystemStatus enableRefetch={enableRefetch} status={statusData?.isCryptoAvailable} />,
    },
    {
      label: 'Securities Available',
      value: <SystemStatus enableRefetch={enableRefetch} status={statusData?.isSecuritiesAvailable} />,
    },
    {
      label: 'Securities Trading Open',
      value: <SystemStatus enableRefetch={enableRefetch} status={statusData?.securitiesTradingOpen} />,
    },
  ];

  const authExpiry = dayjs.unix(userAuthInfo?.expiryDate || 0).format('hh:mm a, MMM DD');

  const apiUrl = process.env.NEXT_PUBLIC_API_URL ? process.env.NEXT_PUBLIC_API_URL.replace(/\/$/, '') : '';
  const { hasCopied, onCopy } = useClipboard(userAuthInfo?.idToken || '');
  const kycStates = Object.keys(KYCStatusEnum).sort();

  const updateKyc = (kycState: KYCStatusEnum) => {
    axios
      .post(
        `${apiUrl}/v2/dev-tools/change-user-state`,
        {},
        {
          headers: {
            't-c-version': kycState === 'NOT_STARTED' ? 'none' : 'current',
            'kyc-state': kycState,
            'fi-id': user.data?.fiId,
            'user-account-id': user.data?.userAccountId,
          },
        },
      )
      .then(() => {
        window.location.reload();
      })
      .catch(() => {
        console.error('Error resetting onboarding');
      });
  };

  const overrideSystemState = () => {
    const isCryptoAvailable = (document.querySelector('[name=cryptoAvailable]') as HTMLInputElement)?.checked;
    const isSecuritiesAvailable = (document.querySelector('[name=securitiesAvailable]') as HTMLInputElement)?.checked;
    const securitiesTradingOpen = (document.querySelector('[name=securitiesTradingOpen]') as HTMLInputElement)?.checked;

    localStorage.setItem(
      'systemState',
      JSON.stringify({ isCryptoAvailable, isSecuritiesAvailable, securitiesTradingOpen }),
    );

    window.location.reload();
  };

  const resetSystemState = () => {
    localStorage.removeItem('systemState');
    window.location.reload();
  };

  return (
    <>
      <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="lg">
        <DrawerOverlay />

        <DrawerContent bg="blackAlpha.900" color={`${uiColor}.500`} fontFamily="monospace" className="dev-console">
          <DrawerCloseButton />

          <DrawerHeader>
            <Flex gap="4" alignItems="center">
              <Heading as="h1" fontFamily="monospace" fontSize="xl" p="0">
                Dev Console
              </Heading>
            </Flex>
          </DrawerHeader>

          <DrawerBody fontSize="11px">
            <Flex gap={8} flexDir="column">
              <Actions
                uiColor={uiColor}
                idToken={userAuthInfo?.idToken}
                enableRefetch={enableRefetch}
                setEnableRefetch={setEnableRefetch}
                setShowBreakpointDebugger={setShowBreakpointDebugger}
                onDrawerClose={onClose}
              />

              <DataGrid
                data={userRows}
                heading="User Info"
                uiColor={uiColor}
                buttons={
                  <>
                    <Button onClick={onCopy} uiColor={uiColor}>
                      <>
                        {hasCopied && (
                          <span
                            style={{
                              position: 'absolute',
                              left: 0,
                              width: '100%',
                              textAlign: 'center',
                            }}
                          >
                            Copied
                          </span>
                        )}

                        <span style={{ visibility: hasCopied ? 'hidden' : 'visible' }}>Copy token</span>
                      </>
                    </Button>

                    <Menu>
                      <MenuButton as={Button} uiColor={uiColor}>
                        Change KYC state
                      </MenuButton>

                      <MenuList bg="black" borderColor={`${uiColor}.500`} fontSize="xs">
                        <MenuItem
                          kycState={'NOT_STARTED' as KYCStatusEnum}
                          onClick={() => {
                            updateKyc('NOT_STARTED' as KYCStatusEnum);
                          }}
                        />

                        <MenuItem
                          kycState={'APPROVED' as KYCStatusEnum}
                          onClick={() => {
                            updateKyc('APPROVED' as KYCStatusEnum);
                          }}
                        />

                        <Divider my="2" />

                        {kycStates
                          .filter((state) => state !== 'APPROVED' && state !== 'NOT_STARTED')
                          .map((state) => {
                            return (
                              <MenuItem
                                key={state}
                                kycState={state as KYCStatusEnum}
                                onClick={() => {
                                  updateKyc(state as KYCStatusEnum);
                                }}
                              />
                            );
                          })}
                      </MenuList>
                    </Menu>
                  </>
                }
              />

              <DataGrid data={fiRows} heading="FI Info" uiColor={uiColor} />

              <DataGrid
                data={systemRows}
                heading="System Info"
                uiColor={uiColor}
                buttons={
                  <>
                    {hasSystemOverride && (
                      <Button
                        uiColor={uiColor}
                        onClick={resetSystemState}
                        outline="solid 1px"
                        position="relative"
                        _after={{
                          bg: 'purple.500',
                          borderRadius: 'full',
                          width: '3',
                          height: '3',
                          position: 'absolute',
                          top: '-0.25rem',
                          right: '-0.25rem',
                          zIndex: '1',
                          content: '""',
                        }}
                      >
                        Clear override
                      </Button>
                    )}

                    {!hasSystemOverride && (
                      <Menu>
                        <MenuButton as={Button} uiColor={uiColor}>
                          Override system state
                        </MenuButton>

                        <MenuList
                          bg="black"
                          borderColor={`${uiColor}.500`}
                          fontSize="xs"
                          textTransform="uppercase"
                          px="4"
                          py="3"
                        >
                          <Flex mb="2" flexDir="column" gap="1">
                            <FormLabel
                              fontFamily="mono"
                              color={`${uiColor}.500`}
                              fontSize="xs"
                              cursor="pointer"
                              w="full"
                            >
                              <Checkbox
                                size="sm"
                                mr="2"
                                name="cryptoAvailable"
                                className={`dev-checkbox ${uiColor}`}
                                defaultChecked={statusData?.isCryptoAvailable}
                              />
                              Crypto Available
                            </FormLabel>

                            <FormLabel
                              fontFamily="mono"
                              color={`${uiColor}.500`}
                              fontSize="xs"
                              cursor="pointer"
                              w="full"
                              className={`dev-checkbox ${uiColor}`}
                            >
                              <Checkbox
                                size="sm"
                                mr="2"
                                name="securitiesAvailable"
                                defaultChecked={statusData?.isSecuritiesAvailable}
                              />
                              Securities available
                            </FormLabel>

                            <FormLabel
                              fontFamily="mono"
                              color={`${uiColor}.500`}
                              fontSize="xs"
                              cursor="pointer"
                              w="full"
                              className={`dev-checkbox ${uiColor}`}
                            >
                              <Checkbox
                                size="sm"
                                mr="2"
                                name="securitiesTradingOpen"
                                defaultChecked={statusData?.securitiesTradingOpen}
                              />
                              Securities trading open
                            </FormLabel>
                          </Flex>

                          <Button uiColor={uiColor} w="full" onClick={overrideSystemState}>
                            Apply
                          </Button>
                        </MenuList>
                      </Menu>
                    )}
                  </>
                }
              />

              <Links uiColor={uiColor} />
            </Flex>
          </DrawerBody>

          <DrawerFooter
            justifyContent="space-between"
            fontSize="11px"
            borderTop="dashed 1px"
            borderColor={`${uiColor}.800`}
          >
            <Flex flexDir="column">
              <Text>auth expiry: {authExpiry}</Text>
            </Flex>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>

      <ConsoleButton
        hideButton={isOpen || hideButton}
        enableRefetch={enableRefetch}
        hasSystemOverride={hasSystemOverride}
        onOpen={onOpen}
        uiColor={uiColor}
      />
    </>
  );
};

export default DevConsole;
