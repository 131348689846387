import { useContext } from 'react';

import { AxiosContext } from '~/components/Providers/AxiosProvider';

export const useAxiosContext = () => {
  const context = useContext(AxiosContext);

  if (context === undefined) {
    throw new Error('useAxiosContext must be used within a AxiosProvider');
  }

  return context;
};
