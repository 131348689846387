'use client';

import { Flex, FlexProps, Heading, Text } from '@chakra-ui/react';
import { CfPrettyWrap, uiColors } from '@cryptofi/core-ui';

import { OnboardingStep as Step } from '~/customTypes';
import { useOnboardingInfo } from '~/hooks';
interface Props extends FlexProps {
  stepData: Step;
  showDescription?: boolean;
}

const OnboardingStep = ({ stepData, showDescription, ...rest }: Props) => {
  const { getSectionProgress } = useOnboardingInfo();

  const progress = getSectionProgress(stepData.stepNumber);

  return (
    <Flex w="full" direction={{ base: 'row', md: 'column' }} gap={2} {...rest}>
      {/* Progress bar */}
      <Flex
        height={{ base: 20, md: 2 }}
        width={{ base: 2, md: '100%' }}
        borderRadius={16}
        bgColor="gray.200"
        overflow="hidden"
      >
        <Flex
          borderTopLeftRadius={{ base: 16, md: 'none' }}
          borderTopRightRadius={{ base: 16, md: 'none' }}
          height={{
            base: `${progress}%`,
            md: '100%',
          }}
          width={{
            base: '100%',
            md: `${progress}%`,
          }}
          bgColor="brand.500"
        ></Flex>
      </Flex>

      {/* Progress title and description */}
      <Flex w="full" direction="column" justifyContent="center" gap="1">
        <Heading as="h2" size="sm" color={progress && progress > 0 && progress < 100 ? 'brand.500' : ''}>
          {stepData.title}
        </Heading>

        {showDescription && (
          <CfPrettyWrap>
            <Text fontSize="sm" lineHeight="normal" color={uiColors.sonicSilver()}>
              {stepData.description}
            </Text>
          </CfPrettyWrap>
        )}
      </Flex>
    </Flex>
  );
};

export default OnboardingStep;
