import { BrandColors } from '~/customTypes';

export type FiKeys = 'alkami' | 'bluestone' | 'doge' | 'frankenmuth' | 'ufcu' | 'westreet';

type Config = {
  [K in FiKeys]: {
    id?: string;
    brand: BrandColors;
    iconPath: string;
    title: string; // HTML title
  };
};

const fiConfigs: Config = {
  alkami: {
    brand: {
      '50': '#76b9fe',
      '100': '#43a0fd',
      '200': '#2a93fd',
      '300': '#1186fd',
      '400': '#0379f1',
      '500': '#0260bf',
      '600': '#01478d',
      '700': '#013a73',
      '800': '#012d5a',
      '900': '#001428',
    },
    iconPath: '',
    title: 'Orb Financial (Alkami Demo)',
  },
  bluestone: {
    brand: {
      '50': '#E6F7FB',
      '100': '#5deaff',
      '200': '#43e6ff',
      '300': '#2ae3ff',
      '400': '#10dfff',
      '500': '#00bfdc',
      '600': '#3895B7',
      '700': '#1C6E8E',
      '800': '#044368',
      '900': '#032F4B',
    },
    iconPath: 'bluestoneFavicon.ico',
    title: 'Bluestone Federal Credit Union',
  },
  doge: {
    id: '00000', // 00000 combined, 24128 crypto, 24129 securities
    brand: {
      '50': '#c2efeb',
      '100': '#97ddd7',
      '200': '#4eeee1',
      '300': '#45dbcf',
      '400': '#3ec8bd',
      '500': '#0d9488',
      '600': '#09655d',
      '700': '#074e47',
      '800': '#053632',
      '900': '#010707',
    },
    iconPath: 'defaultFavicon.png',
    title: 'InvestiFi - DBP v2',
  },
  frankenmuth: {
    brand: {
      '50': '#d8faf8',
      '100': '#97eee7',
      '200': '#55e3d8',
      '300': '#3fdfd3',
      '400': '#2adbce',
      '500': '#1eb4a9',
      '600': '#178880',
      '700': '#13726b',
      '800': '#0f5d57',
      '900': '#08312e',
    },
    iconPath: 'frankenmuthFavicon.ico',
    title: 'Frankenmuth Credit Union',
  },
  ufcu: {
    id: '24092',
    brand: {
      '50': '#e4def3',
      '100': '#c6b9e5',
      '200': '#b7a6de',
      '300': '#a794d7',
      '400': '#9881d0',
      '500': '#7a5cc2',
      '600': '#5f40ab',
      '700': '#553998',
      '800': '#4b3286',
      '900': '#362461',
    },
    iconPath: 'ufcuFavicon.jpeg',
    title: 'United Financial Credit Union',
  },
  westreet: {
    id: '23177',
    brand: {
      '50': '#fae3ef',
      '100': '#ea8dbf',
      '200': '#e677b3',
      '300': '#e262a7',
      '400': '#de4c9b',
      '500': '#bb2375',
      '600': '#a51f67',
      '700': '#901b5a',
      '800': '#7a174c',
      '900': '#4f0f31',
    },
    iconPath: 'westreetFavicon.ico',
    title: 'WeStreet Credit Union',
  },
};

export default fiConfigs;
