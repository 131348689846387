import { useQuery } from '@tanstack/react-query';
import { camelizeKeys } from 'humps';

import { AllAssetIds, SecurityAsset } from '~/customTypes';
import { useAxiosContext, useFeatureSetEnabled, useGlobalStore } from '~/hooks';
import { apiRoutes } from '~/routes';

const useGetSecurity = ({
  ticker = '',
  refetchInterval = false,
  enabled = true,
}: {
  ticker?: AllAssetIds;
  refetchInterval?: number | false;
  enabled?: boolean;
}) => {
  const { apiClient } = useAxiosContext();
  const url = apiRoutes.redis.getSecurity({ ticker });
  const [userAuthInfo] = useGlobalStore((state) => [state.userAuthInfo]);
  const disableRefetch = localStorage.getItem('enableRefetch') === 'false';
  const { isEnabled } = useFeatureSetEnabled();

  return useQuery({
    enabled: enabled && Boolean(userAuthInfo) && isEnabled(['securities']) && Boolean(ticker),
    queryKey: [url, ticker, apiClient, refetchInterval],
    refetchInterval: disableRefetch ? false : refetchInterval,
    queryFn: async () => {
      const response = await apiClient!.get(url);

      const formattedData: SecurityAsset = camelizeKeys(response?.data);

      return formattedData;
    },
  });
};

export default useGetSecurity;
