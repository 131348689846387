import {
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Text,
  useCheckbox,
  useCheckboxGroup,
  UseCheckboxProps,
} from '@chakra-ui/react';
import { CfTooltip, uiColors } from '@cryptofi/core-ui';
import { capitalize } from 'lodash';
import { Dispatch, SetStateAction, useState } from 'react';

import { cryptoOnboardingUnavailableMessage, securitiesOnboardingUnavailableMessage } from '~/constants';
import { OnboardingModalViews, Product } from '~/customTypes';
import { useGetSystemStatus } from '~/hooks';
import { isFalse } from '~/utils';

// TODO: make ProductCheckbox reusable and add to core ui when needed elsewhere
const ProductCheckbox = ({
  useCheckboxProps,
  title,
  description,
  isDisabled,
  disabledMessage,
}: {
  useCheckboxProps: UseCheckboxProps;
  title: string;
  description: string;
  isDisabled?: boolean;
  disabledMessage: string;
}) => {
  const { state, getInputProps, getLabelProps } = useCheckbox(useCheckboxProps);

  return (
    <CfTooltip
      label={
        isDisabled ? (
          <Text maxW="12rem" textAlign="center">
            {disabledMessage}
          </Text>
        ) : null
      }
    >
      <FormLabel
        aria-checked={isDisabled ? false : state.isChecked}
        display="flex"
        flexDirection="column"
        gap="4"
        px="4"
        py="6"
        w="full"
        h="full"
        borderStyle="solid"
        borderWidth="2px"
        borderColor={state.isInvalid ? 'red.500' : uiColors.coolElegance()}
        borderRadius="0.75rem"
        role="checkbox"
        cursor="pointer"
        background={isDisabled ? 'gray.100' : 'transparent'}
        opacity={isDisabled ? 0.6 : 1}
        _hover={{ boxShadow: `0px 2px 8px 3px ${uiColors.black(0.08)} !important` }}
        _focus={{ boxShadow: `0px 2px 8px 3px ${uiColors.black(0.08)} !important` }}
        _checked={{
          borderColor: 'brand.500',
        }}
      >
        <input {...getInputProps({ id: title })} disabled={isDisabled} />

        <Flex justifyContent="space-between" alignItems="center" w="full">
          <Heading {...getLabelProps({ 'aria-label': 'true' })} as="h3" color="black" fontSize="xl">
            {capitalize(title)}
          </Heading>

          <Checkbox
            size="lg"
            isChecked={isDisabled ? false : state.isChecked}
            tabIndex={-1}
            pointerEvents="none"
            filter={isDisabled ? 'grayscale(1)' : 'none'}
            bg={isDisabled ? 'transparent' : 'white'}
          />
        </Flex>

        <Text color={uiColors.sonicSilver()}>{description}</Text>
      </FormLabel>
    </CfTooltip>
  );
};

const SelectProductsView = ({
  onboardingProducts,
  setOnboardingProducts,
  setModalView,
}: {
  onboardingProducts: Product[];
  setOnboardingProducts: (products: Product[]) => void;
  setModalView: Dispatch<SetStateAction<OnboardingModalViews>>;
}) => {
  const systemStatus = useGetSystemStatus();
  const isDisabledCryptoOnboarding = isFalse(systemStatus.data?.isCryptoAvailable);
  const isDisabledSecuritiesOnboarding = isFalse(systemStatus.data?.isSecuritiesAvailable);

  const initialProducts = () => {
    let onboardingProducts = ['securities', 'crypto'];

    if (isDisabledCryptoOnboarding) {
      onboardingProducts = onboardingProducts.filter((product) => product !== 'crypto');
    }

    if (isDisabledSecuritiesOnboarding) {
      onboardingProducts = onboardingProducts.filter((product) => product !== 'securities');
    }

    return onboardingProducts as Product[];
  };

  const [products, setProducts] = useState<Product[]>(
    onboardingProducts.length > 0 ? onboardingProducts : initialProducts(),
  );

  const { getCheckboxProps } = useCheckboxGroup({
    defaultValue: products,
    onChange: (value) => {
      setProducts(value as Product[]);
    },
  });

  const handleGetStarted = () => {
    setOnboardingProducts(products);
    setModalView('termsAndConditions');
  };

  return (
    <Flex flexDir="column" w="full" gap="6" alignItems="center" py="2" pb="6">
      <Flex flexDir="column" w="full" gap="4">
        <Heading as="h1" size={{ base: 'lg', md: 'xl' }} color="brand.500">
          Welcome to your investing experience
        </Heading>
      </Flex>

      <Flex flexDir="column" w="full" gap="4">
        <Heading as="h3" color="black" fontSize="xl">
          Please select which account type(s) you would like to set up.
        </Heading>

        <Flex gap="2" flexWrap={{ base: 'wrap', md: 'nowrap' }}>
          <FormControl isInvalid={products.length === 0}>
            <ProductCheckbox
              useCheckboxProps={getCheckboxProps({
                value: 'securities',
              })}
              title="Securities"
              description="Get started with traditional investments: Trade Stocks & ETFs"
              isDisabled={isDisabledSecuritiesOnboarding}
              disabledMessage={securitiesOnboardingUnavailableMessage}
            />

            <FormErrorMessage hidden>Please select at least one product</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={products.length === 0}>
            <ProductCheckbox
              useCheckboxProps={getCheckboxProps({
                value: 'crypto',
              })}
              title="Cryptocurrencies"
              description="Easily trade top cryptocurrencies with us, knowing your holdings are safe and secure."
              isDisabled={isDisabledCryptoOnboarding}
              disabledMessage={cryptoOnboardingUnavailableMessage}
            />

            <FormErrorMessage hidden>Please select at least one product</FormErrorMessage>
          </FormControl>
        </Flex>

        <FormControl aria-hidden isInvalid={products.length === 0}>
          <FormErrorMessage>Please select at least one product</FormErrorMessage>
        </FormControl>
      </Flex>

      <Flex w={{ base: 'full', md: 'auto' }} alignSelf="flex-end">
        <Button w="full" isDisabled={products.length === 0} onClick={handleGetStarted}>
          Get started
        </Button>
      </Flex>

      <Text fontSize="2xs" color={uiColors.sonicSilver()}>
        Self-directed digital assets and securities are available to you. You may select to have access to both digital
        assets and securities or one or the other. If you select to have access to only one, you may not have the option
        to add the other right away. Adding access to digital assets and/or securities does not require you to purchase
        either. Your information will be provided to SAFE, Inc for a digital assets account and to CDS for a securities
        account. Although offered by different companies, for your convenience, all of your selections will be available
        in your self-directed investments portal within online banking.
      </Text>
    </Flex>
  );
};

export default SelectProductsView;
