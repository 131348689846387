import { useQuery } from '@tanstack/react-query';
import { Camelized, camelizeKeys } from 'humps';

import { EnabledFeatureSet, FinancialInstitutionInfoUserResponse } from '~/codegen/types';
import { useAxiosContext, useGlobalStore } from '~/hooks';
import { apiRoutes } from '~/routes';
import { ignoreUpperCaseKeys } from '~/utils';
interface FiEnabledFeatureSet
  extends Omit<
    Camelized<EnabledFeatureSet>,
    'enabledAssets' | 'enabledSecurities' | 'enabledSecuritiesTransactionTypes'
  > {
  enabledAssets?: string[];
  enabledSecurities?: string[];
  enabledSecuritiesTransactionTypes?: string[];
}

interface FiInfo extends Omit<Camelized<FinancialInstitutionInfoUserResponse>, 'enabledFeatureSet'> {
  enabledFeatureSet: FiEnabledFeatureSet;
}

const useGetFiInfo = () => {
  const [userAuthInfo] = useGlobalStore((state) => [state.userAuthInfo]);
  const { apiClient } = useAxiosContext();
  const queryEnabled = Boolean(userAuthInfo);

  const { data, isError } = useQuery({
    enabled: queryEnabled,
    queryKey: [apiRoutes.fiInfo(), apiClient],
    queryFn: async () => {
      return apiClient!.get(apiRoutes.fiInfo()).then((r) => {
        const camelized = camelizeKeys(r.data, {
          process: ignoreUpperCaseKeys,
        }) as Camelized<FinancialInstitutionInfoUserResponse>;

        // copy API data to custom frontend type

        const enabledFeatureSet: FiEnabledFeatureSet = {
          ...camelized.enabledFeatureSet,

          enabledAssets: Object.entries(camelized.enabledFeatureSet.enabledAssets)
            .filter(([key, value]) => Boolean(key) && Boolean(value))
            .map(([key]) => key),

          enabledSecurities: Object.entries(camelized.enabledFeatureSet.enabledSecurities)
            .filter(([key, value]) => Boolean(key) && Boolean(value))
            .map(([key]) => key),

          enabledSecuritiesTransactionTypes: Object.entries(
            camelized.enabledFeatureSet.enabledSecuritiesTransactionTypes,
          )
            .filter(([key, value]) => Boolean(key) && Boolean(value))
            .map(([key]) => key),
        };

        const updatedData: FiInfo = {
          ...camelized,
          enabledFeatureSet: enabledFeatureSet,
        };

        return updatedData;
      });
    },
  });

  // blocking request - omits isLoading and other unused properties
  return {
    data,
    isError,
  };
};

export default useGetFiInfo;
