'use client';

import { Box, Flex, TableCellProps, Td, Text } from '@chakra-ui/react';
import { uiColors } from '@cryptofi/core-ui';

// dotted connector between label and value
// attached to the table cells because Safari doesn't support position relative on tr
const rowStyles: TableCellProps = {
  position: 'relative',
  py: '2.5',
  _after: {
    content: '""',
    display: 'block',
    width: 'calc(100% - 2px)',
    height: '1px',
    borderBottom: 'dotted 2px',
    borderColor: uiColors.coolElegance(),
    position: 'absolute',
    bottom: '48%',
    left: 0,
    zIndex: 0,
  },
};

export const LabelTd = ({ children }: { children: React.ReactNode }) => {
  return (
    <Td pl="0" {...rowStyles}>
      <Text
        position="relative"
        bg={uiColors.lighthouse()}
        zIndex="2"
        width="fit-content"
        pr="3"
        fontSize="md"
        color={uiColors.sonicSilver()}
      >
        {children}
      </Text>
    </Td>
  );
};

export const ValueTd = ({ children }: { children: React.ReactNode }) => {
  return (
    <Td pr="0" {...rowStyles}>
      <Flex justifyContent="flex-end">
        <Box
          position="relative"
          bg={uiColors.lighthouse()}
          zIndex="2"
          width="fit-content"
          pl="3"
          fontFamily="heading"
          fontSize="md"
          textAlign="right"
        >
          {children}
        </Box>
      </Flex>
    </Td>
  );
};
