import { Alert, Box, Heading, Text } from '@chakra-ui/react';
import { CfPrettyWrap, IconInfo, uiColors } from '@cryptofi/core-ui';

import { Product } from '~/customTypes';

const KycAlert = ({ product }: { product: Product }) => {
  return (
    <Alert colorScheme="white" borderColor={uiColors.coolElegance()}>
      <Box>
        <IconInfo
          mr="4"
          __css={{
            path: { fill: uiColors.coolElegance() },
          }}
        />
      </Box>

      <CfPrettyWrap>
        <Heading as="h3" fontSize="md" lineHeight="normal">
          {product === 'securities' && 'Securities application under review'}

          {product === 'crypto' && 'Crypto application under review'}

          {product === 'combined' && 'Crypto and securities application under review'}
        </Heading>

        <Text lineHeight="normal">
          Your application is currently under review. You will receive an email from our support team if additional
          information is required.
        </Text>
      </CfPrettyWrap>
    </Alert>
  );
};

export default KycAlert;
