import { useQuery } from '@tanstack/react-query';

import { useAxiosContext } from '~/hooks';
import { apiRoutes } from '~/routes';

const useGetUserReport = ({ reportId, gcTime }: { reportId: string; gcTime: number }) => {
  const { apiClient } = useAxiosContext();
  const reportEncodedId = encodeURIComponent(reportId);

  return useQuery({
    gcTime,
    enabled: false,
    queryKey: [apiRoutes.report(reportEncodedId), reportId, apiClient],
    queryFn: async () => {
      return apiClient!.get(apiRoutes.report(reportEncodedId)).then((r) => r.data as string);
    },
  });
};

export default useGetUserReport;
