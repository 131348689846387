import { Button, Flex, Heading, IconButton, useDisclosure, useTheme } from '@chakra-ui/react';
import { CfPrettyWrap, IconClose } from '@cryptofi/core-ui';
import { capitalize } from 'lodash';

import { Product } from '~/customTypes';
import { useGlobalModalContext } from '~/hooks';

const NewProductAvailable = ({ product }: { product: Product }) => {
  const theme = useTheme();
  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });
  const { onOpenModal } = useGlobalModalContext();

  if (!isOpen || localStorage.getItem('hideNewProductAvailable') === 'true') {
    return null;
  }

  return (
    <Flex
      flexDir="column"
      gap="6"
      padding="6"
      position="relative"
      borderRadius="md"
      background={`linear-gradient(119.36deg, transparent 0%, ${theme.colors.brand['50']} 100%)`}
    >
      <IconButton
        position="absolute"
        top="6"
        right="6"
        onClick={() => {
          onClose();
          localStorage.setItem('hideNewProductAvailable', 'true');
        }}
        size="xs"
        icon={<IconClose />}
        aria-label="Dismiss this message"
      />

      <Heading as="h2" size="sm">
        {capitalize(product)} trading is now available!
      </Heading>

      <Flex
        gap="6"
        flexDir={{
          base: 'column',
          lg: 'row',
        }}
      >
        <CfPrettyWrap>Get started by setting up your {product} trading account.</CfPrettyWrap>

        <Button
          variant="outline"
          bg="white"
          onClick={onOpenModal}
          ml="auto"
          my={{
            base: '0',
            lg: 'auto',
          }}
          width={{
            base: 'full',
            lg: 'auto',
          }}
        >
          Get started
        </Button>
      </Flex>
    </Flex>
  );
};

export default NewProductAvailable;
