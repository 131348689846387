import { Button as ChakraButton, ButtonProps, forwardRef } from '@chakra-ui/react';

interface Props extends ButtonProps {
  uiColor: string;
}

const Button = forwardRef<Props, 'button'>((props, ref) => {
  const { children, uiColor, ...rest } = props;

  return (
    <ChakraButton
      py="1"
      px="2"
      height="auto"
      ref={ref}
      size="xs"
      bgColor={`${uiColor}.500 !important`}
      color="black"
      transition="background-color 0.25s"
      sx={{
        '&:not(:disabled):hover, &:not(:disabled):focus, &:not(:disabled)[data-active]': {
          bgColor: `${uiColor}.300 !important`,
        },
      }}
      fontSize="12px"
      {...rest}
    >
      {children}
    </ChakraButton>
  );
});

export default Button;
