'use client';
import { Flex } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';

import { AssetsSearch } from '~/components';
import { useModalContext } from '~/components/InvestModal/ModalContext';
import { AllAssetIds } from '~/customTypes';
export default function InvestAssetsSearch() {
  const {
    setModalView,
    setBuyCurrency,
    setSelectedAssetId,
    transactionType,
    setSellCurrency,
    amountAvailableAsset,
    amountAvailableUsd,
  } = useModalContext();

  const { setValue } = useFormContext();

  const setSelectedAsset = ({ assetId }: { assetId: AllAssetIds }) => {
    if (transactionType === 'buy') {
      setSelectedAssetId(assetId);
      setBuyCurrency('USD');
      setValue('buyIsUsd', true);
    }

    if (transactionType === 'sell') {
      setSelectedAssetId(assetId);
      setSellCurrency('USD');
      setValue('sellIsUsd', true);

      // update hidden inputs
      setValue('sellAmountAvailableUsd', amountAvailableUsd);
      setValue('sellAmountAvailableAsset', amountAvailableAsset);
    }

    setModalView('startTransaction');
  };

  const handleClick = (asset: AllAssetIds) => {
    setSelectedAsset({ assetId: asset });
  };

  return (
    <Flex height="58vh">
      <AssetsSearch onClickCryptoAsset={handleClick} onClickSecurity={handleClick} />
    </Flex>
  );
}
