import { invert } from 'lodash';

import { DividendStatusEnum, OrderStatusEnum, TokenEnum, TokenNameEnum } from '~/codegen/types';

import defaultEducationImages from './defaultEducationImages';
import { introContentsCrypto, introContentsSecurities } from './introductionContent';
import onboardingSteps from './onboardingSteps.json';
import usStates from './usStates.json';

// re export the following modules
export { defaultEducationImages, introContentsCrypto, introContentsSecurities, onboardingSteps, usStates };

// Yup email validation allows some formats we don't support, see https://github.com/jquense/yup/issues/507
// regex from https://stackoverflow.com/a/4964766
export const emailRegex = /^\S+@\S+\.\S+$/;

export const defaultStaleTime = 300_000; // 5 minutes, https://tanstack.com/query/latest/docs/framework/react/guides/important-defaults
export const cryptoRefetchInterval = 5_000; // 5 seconds, pickup new crypto prices
export const securitiesRefetchInterval = 20_000; // 20 seconds, pickup new securities prices
export const bankAccountRefetchInterval = 60_000; // 1 minute, pickup account balance changes
export const statusRefetchInterval = 30_000; // 30 seconds, pickup system status changes

export const contentMaxWidth = '68.75rem';

export const defaultRecordsPerPage = 10;

export const investButtonLabels = {
  trade: 'Trade',
  invest: 'Invest',
};

export const defaultEducationImage = 'Crypto101';

export const cryptoUnavailableMessage = 'Crypto trading is temporarily unavailable';
export const securitiesUnavailableMessage = 'Securities trading is temporarily unavailable';
export const systemOnboardingUnavailableMessage = 'New account creation is temporarily unavailable';
export const cryptoOnboardingUnavailableMessage = 'Crypto onboarding is temporarily unavailable';
export const securitiesOnboardingUnavailableMessage = 'Securities onboarding is temporarily unavailable';

export const tokenIdToName = {
  [TokenEnum.BTC]: TokenNameEnum.Bitcoin,
  [TokenEnum.BCH]: TokenNameEnum.BitcoinCash,
  [TokenEnum.ETH]: TokenNameEnum.Ethereum,
  [TokenEnum.LTC]: TokenNameEnum.Litecoin,
  [TokenEnum.XLM]: TokenNameEnum.Stellar,
  [TokenEnum.LINK]: TokenNameEnum.Chainlink,
  [TokenEnum.AVAX]: TokenNameEnum.Avalanche,
  [TokenEnum.XRP]: TokenNameEnum.Ripple,
  [TokenEnum.ALGO]: TokenNameEnum.Algorand,
  [TokenEnum.MKR]: TokenNameEnum.Maker,
};

export const tokenNameToId = invert(tokenIdToName);

// Statuses are from this doc: https://www.notion.so/cryptofi/Transaction-Statuses-0a6df45ea62a4f13881d4da8a9bc6633?pvs=6&utm_content=0a6df45e-a62a-4f13-881d-4da8a9bc6633&utm_campaign=T02943LHG83&n=slack&n=slack_link_unfurl
export const FrontEndOrderStatus = {
  [OrderStatusEnum.NONE]: 'Pending',
  [OrderStatusEnum.OK]: 'Complete',
  [OrderStatusEnum.OK_ERROR]: 'Order Failed',
  [OrderStatusEnum.OK_FRAUD]: 'Fraud',
  [OrderStatusEnum.OK_LIQUIDATED]: 'Liquidated',
  [OrderStatusEnum.OK_PARTIAL]: 'Complete',
  [OrderStatusEnum.CANCELED]: 'Order Failed',
  [OrderStatusEnum.RECEIVED]: 'Pending',
  [OrderStatusEnum.OK_BANK_TRANSFER]: 'Pending',
  [OrderStatusEnum.PARTIAL_FILL_BUY]: 'Pending',
  [OrderStatusEnum.PARTIAL_FILL_SELL]: 'Pending',
  [OrderStatusEnum.PENDING_ORDER]: 'Pending',
  [OrderStatusEnum.PENDING_SELL_SETTLEMENT]: 'Complete',
  [OrderStatusEnum.PENDING_BUY_SETTLEMENT_SELL_UNAVAILABLE]: 'Complete',
  [OrderStatusEnum.PENDING_BUY_SETTLEMENT]: 'Complete',
  [OrderStatusEnum.PENDING_BUY_SETTLEMENT_PARTIAL]: 'Complete',
  [OrderStatusEnum.PENDING_SELL_SETTLEMENT_PARTIAL]: 'Complete',
  [OrderStatusEnum.PENDING_XFR_SELL_SETTLED]: 'Complete',
  [OrderStatusEnum.ERROR]: 'Order failed',
  [OrderStatusEnum.ERROR_BUY_RECONCILED]: 'Order failed',
  [OrderStatusEnum.ERROR_BUY_OK_XFR]: 'Incomplete, contact support',
  [OrderStatusEnum.ERROR_BUY_ERROR_XFR]: 'Incomplete, contact support',
  [OrderStatusEnum.ERROR_SELL]: 'Order failed',
  [OrderStatusEnum.ERROR_XFR_OK_SELL]: 'Incomplete, contact support',
  [DividendStatusEnum.PENDING_SETTLEMENT]: 'Pending',
  [DividendStatusEnum.BANK_TRANSFER_ERROR]: 'Error',
};
