import React from 'react';

import InvestModal from '~/components/InvestModal/InvestModal';
import OnboardingModal from '~/components/Onboarding/Modal/Modal'; // TODO rename
import { AllAssetIds } from '~/customTypes';
import { useGlobalModalContext, useOnboardingInfo } from '~/hooks';

import DisclosuresModal from './DisclosuresModal';

const GlobalModals = ({ defaultAssetId }: { defaultAssetId?: AllAssetIds }) => {
  const onboardingInfo = useOnboardingInfo();
  const { hasNewTerms } = onboardingInfo;
  const { isOnboardingModalOpen, onOnboardingModalClose, isInvestModalOpen, onInvestModalClose, resumeOnboarding } =
    useGlobalModalContext();

  return (
    <>
      <DisclosuresModal />

      <OnboardingModal
        onboardingInfo={onboardingInfo}
        isOpen={isOnboardingModalOpen}
        onClose={onOnboardingModalClose}
        hasNewTerms={hasNewTerms}
      />

      {defaultAssetId && (
        <InvestModal
          isOpen={isInvestModalOpen}
          onClose={onInvestModalClose}
          defaultAssetId={defaultAssetId}
          resumeOnboarding={resumeOnboarding}
        />
      )}
    </>
  );
};

export default GlobalModals;
