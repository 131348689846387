import { Flex } from '@chakra-ui/react';
import { CfSpinner } from '@cryptofi/core-ui';

import { useLoadingMessages } from '~/hooks';

const KycLoading = ({ showLoadingMessages }: { showLoadingMessages: boolean }) => {
  // KYC request can take ~15 seconds when onboarding for securities
  const { loadingMessages } = useLoadingMessages({
    messages: ['Sending...', 'Verifying your info...', 'Completing verification...', 'Just a few more seconds...'],
  });

  return (
    <>
      <Flex flexDir="column" gap="4" w="full" alignItems="center">
        <CfSpinner />

        {showLoadingMessages && loadingMessages}
      </Flex>
    </>
  );
};

export default KycLoading;
