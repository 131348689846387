import mixpanel from 'mixpanel-browser';
import { useEffect } from 'react';

const useMixpanel = () => {
  useEffect(() => {
    mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_TOKEN || '', {
      debug: false,
      // eslint-disable-next-line camelcase
      track_pageview: false,
      persistence: 'localStorage',
    });
  }, []);
};

export default useMixpanel;
