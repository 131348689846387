import {
  Flex,
  Heading,
  // eslint-disable-next-line no-restricted-imports
  Link,
  List,
  ListItem,
} from '@chakra-ui/react';

const Links = ({ uiColor }: { uiColor: string }) => {
  return (
    <Flex flexDir="column">
      <Heading as="h3" fontSize="md" borderBottom="solid 1px" borderColor={`${uiColor}.700`} mb="2" fontFamily="mono">
        Links
      </Heading>

      <List listStyleType="disc" listStylePosition="inside">
        <ListItem>
          <Link href="https://api.cryptofi-dev.com/v2/docs" target="api-docs" textTransform="uppercase">
            CWS API Documentation
          </Link>
        </ListItem>

        <ListItem>
          <Link
            href="https://cryptofi-core-ui.vercel.app/?path=/docs/intro--docs"
            target="storybook"
            textTransform="uppercase"
          >
            Core UI Storybook
          </Link>
        </ListItem>
      </List>
    </Flex>
  );
};
export default Links;
