import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Text,
  useRadio,
  useRadioGroup,
  UseRadioProps,
} from '@chakra-ui/react';
import { CfSelectOption, uiColors } from '@cryptofi/core-ui';
import { Camelized } from 'humps';
import { capitalize } from 'lodash';
import { FieldErrors, UseFormGetValues, UseFormRegister, UseFormSetValue } from 'react-hook-form';

import { KycFormField } from '~/customTypes';

import { KycFormValues } from './kycSchema';

const RiskRadioCard = (props: { option: Camelized<CfSelectOption>; radioProps: UseRadioProps; isError: boolean }) => {
  const { option, radioProps, isError } = props;
  const { state, getRootProps, getInputProps, getLabelProps } = useRadio(radioProps);
  const radioDescription = {
    'Very Conservative':
      "I'm focused on keeping my money safe, even if returns are low. I stick to low-risk stuff like fixed income ETFs, knowing there's some risk.",
    Conservative:
      'I want steady income with low risk, even if returns are smaller. I stick to S&P 500 stocks and safe ETFs, aware of the risks.',
    Moderate:
      "I'm after a mix of growth and income, so I'm okay with some risk. I invest in ETFs and big-name stocks, knowing I could lose money.",
    Aggressive:
      "I'm all about growth and willing to take big risks for higher returns. I invest in ETFs and various stocks, knowing I might face big swings.",
  }?.[option.name];

  return (
    <FormLabel {...getRootProps()} cursor="pointer" w="full" maxW={{ base: '100%', md: 'calc(50% - 0.5rem)' }} m="0">
      <Flex
        aria-checked={state.isChecked}
        display="flex"
        flexDirection="column"
        gap="4"
        w="full"
        h="full"
        borderStyle="solid"
        borderWidth="1px"
        borderColor={isError ? 'red.500' : uiColors.coolElegance()}
        _hover={{ boxShadow: `0px 2px 8px 0px ${uiColors.black(0.08)}` }}
        _focus={{ boxShadow: `0px 2px 8px 0px ${uiColors.black(0.08)}` }}
        borderRadius="0.75rem"
        px="4"
        py="6"
        role="radio"
        _checked={{
          borderColor: 'brand.400',
        }}
      >
        <Flex justifyContent="space-between" alignItems="center">
          <Heading {...getLabelProps({ 'aria-label': 'true' })} as="h3" color="black" fontSize="xl">
            {capitalize(option.name)}
          </Heading>

          <input {...getInputProps()} style={{ opacity: 0 }} />

          <Flex
            p="1"
            h="1.5rem"
            w="1.5rem"
            alignItems="center"
            borderRadius="100%"
            border="2px solid"
            borderColor="brand.400"
          >
            {radioProps.isChecked && <Box w="full" h="full" bgColor="brand.400" borderRadius="100%" />}
          </Flex>
        </Flex>

        <Text fontSize="sm" fontFamily="body">
          {radioDescription}
        </Text>
      </Flex>
    </FormLabel>
  );
};

const RiskRadioCardGroup = ({
  field,
  register,
  errors,
  setValue,
  getValues,
}: {
  field: Camelized<KycFormField>;
  register: UseFormRegister<KycFormValues>;
  errors: FieldErrors;
  setValue: UseFormSetValue<KycFormValues>;
  getValues?: UseFormGetValues<KycFormValues>;
}) => {
  const { onChange, ...rest } = register(field.name as keyof KycFormValues);
  const { getRootProps, getRadioProps } = useRadioGroup({
    onChange: (value) => {
      setValue(field.name as keyof KycFormValues, value, {
        shouldTouch: true,
        shouldDirty: true,
        shouldValidate: true,
      });
      onChange({ target: { value } });
    },
    value: getValues?.(field.name as keyof KycFormValues),
    ...rest,
  });

  return (
    <Flex flexDirection="column" {...getRootProps({ 'aria-hidden': 'false', 'aria-required': 'true' })}>
      <FormControl isRequired isInvalid={Boolean(errors[field.name]?.message)}>
        <FormLabel mb="2" color={uiColors.sonicSilver()}>
          Risk tolerance
        </FormLabel>
      </FormControl>

      <Text mb="4" fontSize="sm" color={uiColors.sonicSilver()}>
        What kind of investor are you?
      </Text>

      <Flex justifyContent="space-between" gap="4" flexWrap="wrap" w="full">
        {field.options.map((option) => (
          <RiskRadioCard
            key={option.value}
            option={option}
            radioProps={getRadioProps({ value: option.value })}
            isError={Boolean(errors[field.name])}
          />
        ))}
      </Flex>

      {errors[field.name] && (
        <FormControl isInvalid={Boolean(errors[field.name]?.message)}>
          <FormErrorMessage>{errors[field.name]?.message as string}</FormErrorMessage>
        </FormControl>
      )}
    </Flex>
  );
};

export default RiskRadioCardGroup;
