'use client';

import { Flex, Stat, StatLabel, StatNumber, StatProps } from '@chakra-ui/react';
import { IconArrowDown, IconArrowUp, uiColors, useCfBreakpoint } from '@cryptofi/core-ui';

interface Props extends StatProps {
  label: string;
  value: number | string;
  showArrow?: boolean;
  isIncrease?: boolean;
  size?: 'sm' | 'md' | 'lg';
}

const InvestmentStat = ({ label, value, isIncrease, showArrow, size = 'sm', ...rest }: Props) => {
  const { isAboveSmallBreakpoint } = useCfBreakpoint();

  const styles = {
    sm: {
      label: {
        fontSize: 'sm',
      },
      number: {
        fontSize: 'sm',
      },
    },
    md: {
      label: {
        fontSize: 'md',
      },
      number: {
        fontSize: 'md',
      },
    },
    lg: {
      label: {
        fontSize: isAboveSmallBreakpoint ? undefined : 'md',
      },
      number: {
        fontSize: isAboveSmallBreakpoint ? undefined : 'md',
      },
    },
  };

  return (
    <Stat gap="3" flexGrow="0" justifyContent="flex-start" {...rest}>
      <Flex direction={isAboveSmallBreakpoint ? 'column' : 'row'} justifyContent="space-between">
        <StatLabel
          fontSize={styles[size].label.fontSize}
          display="flex"
          alignItems="center"
          justifyContent="flex-start"
          whiteSpace="nowrap"
        >
          {label}

          {showArrow && !isIncrease && (
            <IconArrowDown boxSize="4" __css={{ path: { fill: uiColors.heroicRed() } }} m="0" ml="1" />
          )}

          {showArrow && isIncrease && (
            <IconArrowUp boxSize="4" __css={{ path: { fill: uiColors.mint() } }} m="0" ml="1" />
          )}
        </StatLabel>

        <StatNumber
          fontFamily="heading"
          color={uiColors.black()}
          fontSize={styles[size].label.fontSize}
          textAlign="left"
        >
          {value}
        </StatNumber>
      </Flex>
    </Stat>
  );
};

export default InvestmentStat;
