import { Flex, Heading, SimpleGrid, Text } from '@chakra-ui/react';
import { Fragment, ReactNode } from 'react';

const DataGrid = ({
  data,
  heading,
  uiColor,
  buttons,
}: {
  data: any[];
  heading?: string;
  uiColor: string;
  buttons?: ReactNode;
}) => {
  return (
    <Flex flexDir="column">
      <Flex borderBottom="solid 1px" borderColor={`${uiColor}.700`} mb="2" justifyContent="flex-end">
        {heading && (
          <Heading as="h3" fontSize="md" fontFamily="mono">
            {heading}
          </Heading>
        )}

        <Flex ml="auto" mb="5px" gap="2">
          {buttons}
        </Flex>
      </Flex>

      <SimpleGrid columns={2} gap={1}>
        {data.map((row) => (
          <Fragment key={row.label}>
            <Text textTransform="uppercase">{row.label}</Text>

            <Text>{row.value}</Text>
          </Fragment>
        ))}
      </SimpleGrid>
    </Flex>
  );
};

export default DataGrid;
