import { useQuery } from '@tanstack/react-query';
import { Camelized, camelizeKeys } from 'humps';

import { RedisSecurityPricingInfo } from '~/codegen/types';
import { PriceIntervalMinutes } from '~/customTypes';
import { useAxiosContext, useFeatureSetEnabled, useGlobalStore } from '~/hooks';
import { apiRoutes } from '~/routes';

const useGetSecurityDailyPrice = ({
  ticker,
  interval = '15m',
}: {
  ticker: string;
  interval?: PriceIntervalMinutes; // refetch logic is based on this interval being in minutes
}) => {
  const { apiClient } = useAxiosContext();
  const url = apiRoutes.intrinio.getSecurityDailyPrice({ ticker, interval });
  const [userAuthInfo] = useGlobalStore((state) => [state.userAuthInfo]);
  const { isEnabled } = useFeatureSetEnabled();

  return useQuery({
    // eslint-disable-next-line no-restricted-syntax
    refetchInterval: parseInt(interval) * 60 * 1000,
    enabled: Boolean(userAuthInfo) && isEnabled(['securities']) && Boolean(ticker),
    queryKey: [url, ticker, apiClient],
    queryFn: async () => {
      const response = await apiClient!.get(url);

      const formattedData: Camelized<RedisSecurityPricingInfo>[] = response?.data.map(
        (value: RedisSecurityPricingInfo) => {
          return camelizeKeys(value);
        },
      );

      return formattedData;
    },
  });
};

export default useGetSecurityDailyPrice;
