import { useFeatureSetEnabled, useGetSystemStatus } from '~/hooks';
import { isFalse } from '~/utils';

/**
 *
 * @description Determine relevant system status based on enabled features.
 */
const useSystemDownByFeature = () => {
  const systemStatus = useGetSystemStatus();
  const { isEnabled } = useFeatureSetEnabled();

  const isSystemDownByFeature = () => {
    if (isEnabled(['crypto', 'securities'])) {
      return isFalse(systemStatus.data?.isCryptoAvailable) && isFalse(systemStatus.data?.isSecuritiesAvailable);
    }

    if (isEnabled(['crypto'])) {
      return isFalse(systemStatus.data?.isCryptoAvailable);
    }

    if (isEnabled(['securities'])) {
      return isFalse(systemStatus.data?.isSecuritiesAvailable);
    }
  };

  return {
    isSystemDownByFeature,
  };
};

export default useSystemDownByFeature;
