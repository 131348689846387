'use client';

import { Link } from '@chakra-ui/next-js';
import { Divider, Flex, Heading, Text } from '@chakra-ui/react';
import { uiColors } from '@cryptofi/core-ui';

import { CryptoDisclosure, SecuritiesDisclosure } from '~/components';
import { useFeatureSetEnabled, useGetFiInfo } from '~/hooks';

const TermsLink = ({ url, children }: { url: string; children: string }) => {
  return (
    <Link whiteSpace="nowrap" fontSize="xs" href={url} target={url} textDecor="underline">
      {children}
    </Link>
  );
};

export default function Footer() {
  const { data: fiInfo } = useGetFiInfo();
  const { isEnabled } = useFeatureSetEnabled();

  if (!fiInfo) {
    return null;
  }

  return (
    <Flex
      as="footer"
      width="100%"
      color={uiColors.sonicSilver()}
      borderTop="solid 1px"
      borderColor={uiColors.coolElegance()}
      bg="white"
      px={4}
      pt={4}
      pb={8}
      gap={4}
      flexDir="column"
      fontSize="2xs"
    >
      <Flex gap={3} flexWrap="wrap" alignContent="flex-start" mb="4">
        {fiInfo.termsAndConditions?.investifi?.policies &&
          fiInfo.termsAndConditions.investifi.policies.map((policy) => (
            <TermsLink key={policy.url} url={policy.url}>
              {policy.label}
            </TermsLink>
          ))}

        <TermsLink url="https://investifi.co/legal-disclosures">Legal Disclosures</TermsLink>
      </Flex>

      {isEnabled(['securities']) && (
        <>
          <SecuritiesDisclosure fiName={fiInfo.fiName} />

          <Divider />
        </>
      )}

      {isEnabled(['crypto']) && (
        <>
          <CryptoDisclosure fiName={fiInfo.fiName} />

          {isEnabled(['securities']) && <Divider />}
        </>
      )}

      {isEnabled(['securities']) && (
        <Flex
          gap="2"
          flexDirection="column"
          mb="4"
          sx={{
            a: {
              textDecoration: 'underline',
            },
          }}
        >
          <Heading as="h4" fontSize="2xs">
            Educational Content
          </Heading>

          <Text>
            InvestiFi “Learn” content is provided by AdvisiFi, LLC (“AdvisiFi”), an investment adviser registered with
            the Securities and Exchange Commission (“SEC”). “Learn” content is provided for informational purposes only,
            does not constitute tax or investment advice, and is not intended to be a solicitation for the purchase or
            sale of any security or financial product or a recommendation for any security or trading strategy. While
            the information is deemed reliable, AdvisiFi makes no representations or warranties with respect to the
            accuracy or completeness of any information provided, and it is subject to change without notice.
          </Text>
        </Flex>
      )}
    </Flex>
  );
}
