'use client';

import { Flex, FlexProps, Tooltip } from '@chakra-ui/react';
import { CfTag, IconInfo, uiColors } from '@cryptofi/core-ui';
import { Camelized } from 'humps';

import { Transaction } from '~/codegen/types';
import { FrontEndOrderStatus } from '~/constants';
import { isCrypto } from '~/utils';

interface Props extends FlexProps {
  transaction: Camelized<Transaction>;
}

const TransactionStatus = ({ transaction, ...rest }: Props) => {
  const isSellSecurity = transaction.transactionName === 'Sell' && !isCrypto(transaction.asset);
  const tooltip = isSellSecurity ? 'Proceeds from sales are processed on the following business day.' : '';
  const label = FrontEndOrderStatus[transaction.status as keyof typeof FrontEndOrderStatus];

  return (
    <Tooltip hasArrow label={tooltip}>
      <Flex alignItems="center" {...rest}>
        <CfTag label={label} fontSize="xs" />

        {isSellSecurity && transaction.status === 'PENDING_SELL_SETTLEMENT' && (
          <IconInfo
            ml="2"
            boxSize="4"
            __css={{
              path: { fill: uiColors.sonicSilver() },
            }}
          />
        )}
      </Flex>
    </Tooltip>
  );
};

export default TransactionStatus;
