import fiConfigs, { FiKeys } from '~/theme/fiConfigs';

export const getFiName = () => {
  const fiName = window.location.host.split('.')[0].replace('-dev', '') as FiKeys;

  if (!isFiName(fiName)) {
    return 'doge';
  }

  return fiName;
};

export const isFiName = (fiName: FiKeys) => {
  return Object.keys(fiConfigs).includes(fiName);
};
