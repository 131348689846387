import { useQuery } from '@tanstack/react-query';
import { Camelized, camelizeKeys } from 'humps';

import { FeeBreakdown } from '~/codegen/types';
import { useAxiosContext, useGlobalStore } from '~/hooks';
import { apiRoutes } from '~/routes';
import { ignoreUpperCaseKeys } from '~/utils';

const useGetFees = ({ price, enabled = true }: { price: number; enabled?: boolean }) => {
  const userAuthInfo = useGlobalStore((state) => state.userAuthInfo);
  const { apiClient } = useAxiosContext();
  const url = apiRoutes.fees(price);

  return useQuery({
    enabled: Boolean(userAuthInfo) && enabled,
    queryKey: [url, apiClient],
    queryFn: async () => {
      return apiClient!.get(url).then((r) => {
        const data = camelizeKeys(r.data, {
          process: ignoreUpperCaseKeys,
        }) as Camelized<FeeBreakdown>;

        return data;
      });
    },
  });
};

export default useGetFees;
