'use client';

import { Box, Flex, Grid, GridItem, Heading, Skeleton, Text } from '@chakra-ui/react';
import { dateFormats, formatUsd, IconMinus, IconPlus, uiColors, utcDateToLocal } from '@cryptofi/core-ui';
import { range } from 'lodash';

import { TokenEnum, TransactionName } from '~/codegen/types';
/* eslint-disable-next-line no-restricted-imports */
import { tokenIdToName } from '~/constants';
import { AllAssetIds } from '~/customTypes';
import { useGetSearchTransactions, useGetSecurityFields } from '~/hooks';
import { isCrypto } from '~/utils';

const AssetLabel = ({ assetId }: { assetId: AllAssetIds }) => {
  const security = useGetSecurityFields({
    ticker: assetId,
    enabled: !isCrypto(assetId),
    fields: ['name', 'image'],
  });

  return (
    <Flex h="full" grow="1" flexDir="column" justifyContent="space-between">
      <Heading size="sm"> {assetId}</Heading>

      <Text color={uiColors.sonicSilver()} fontSize="xs" noOfLines={1}>
        {isCrypto(assetId) ? tokenIdToName[assetId as TokenEnum] : security.data?.name}
      </Text>
    </Flex>
  );
};

export default function LatestTransactions() {
  // UI logic is based on a max count of 4 transactions from param recordsPerPage of 4;
  const { data: transactions, isLoading, isSuccess, isError } = useGetSearchTransactions({ recordsPerPage: 4 });
  const transactionCount = transactions?.items?.length;
  return (
    <Grid
      templateColumns={{
        base: '1fr',
        md: '1fr 1fr',
        xl: `repeat(${transactionCount || 4}, 1fr)`,
      }}
      w={transactionCount === 1 ? { xl: '50%' } : 'full'}
      borderRight={transactionCount === 1 ? { xl: `2px solid ${uiColors.lighthouse()}` } : 'none'}
      gridGap="2px"
      bgColor={!isLoading ? uiColors.lighthouse() : 'auto'}
    >
      {isLoading &&
        range(4).map((_, i) => (
          <GridItem key={i} h="4.75rem">
            <Skeleton h="full" />
          </GridItem>
        ))}

      {isSuccess && !transactionCount && (
        <GridItem colSpan={4} h="full" textAlign="center" bgColor={uiColors.white()}>
          You have not placed any trades
        </GridItem>
      )}

      {isSuccess &&
        transactions?.items?.map((tx) => (
          <GridItem key={tx.orderTxId} bgColor={uiColors.white()} p="4">
            <Flex h="full" gap="4" alignItems="center" justifyContent="space-between" w="full">
              <Flex h="full" gap="4" alignItems="center">
                {tx.transactionName === TransactionName.Buy ? (
                  <Box borderRadius="100" bgColor={uiColors.aijiroWhite()} p="1">
                    <IconPlus h="4" w="4" __css={{ path: { fill: uiColors.mint() } }} />
                  </Box>
                ) : (
                  <Box borderRadius="100" bgColor={uiColors.redRemains()} p="1">
                    <IconMinus h="3" w="3" __css={{ path: { fill: uiColors.heroicRed() } }} />
                  </Box>
                )}

                <AssetLabel assetId={tx.asset as AllAssetIds} />
              </Flex>

              <Flex h="full" grow="1" flexDir="column" alignItems="end" justifyContent="space-between">
                <Heading size="xs">{formatUsd({ amount: tx.usdAmountGross || 0 })}</Heading>

                <Text size="sm">
                  {utcDateToLocal({
                    dateFormat: dateFormats.dateOnly,
                    timeString: tx.transactTime,
                  })}
                </Text>
              </Flex>
            </Flex>
          </GridItem>
        ))}

      {/* for certain transaction counts, we add an empty grid item at certain breakpoints to properly show gray gaps between items*/}
      {(transactionCount === 3 || transactionCount === 1) && (
        <GridItem
          display={{ base: 'none', md: 'block', xl: 'none' }}
          h="full"
          w="full"
          bgColor={uiColors.white()}
        ></GridItem>
      )}

      {isError && (
        <GridItem h="full" textAlign="center" bgColor={uiColors.white()}>
          There was an error loading your transactions. Please try again later.
        </GridItem>
      )}
    </Grid>
  );
}
