'use client';
import { Link } from '@chakra-ui/next-js';
import { Flex, Grid, Heading } from '@chakra-ui/react';

import { TopPerformersCard } from '~/components';

export default function TopPerformersCards() {
  return (
    <Flex flexDir="column" gap={4}>
      <Heading size="sm">Top performers</Heading>

      <Grid gap={2} gridTemplateColumns="repeat(3, 1fr)">
        <TopPerformersCard />

        <TopPerformersCard />

        <TopPerformersCard />
      </Grid>

      <Link textAlign="center" href="/">
        Show more...
      </Link>
    </Flex>
  );
}
