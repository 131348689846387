'use client';

import { IconButton, TableRowProps, Td, Tr } from '@chakra-ui/react';
import { formatUsd, IconCaretRight } from '@cryptofi/core-ui';
import { useRouter } from 'next/navigation';

import { AssetLogo } from '~/components';
import { CryptoAsset } from '~/customTypes';
import { uiRoutes } from '~/routes';

interface Props extends TableRowProps {
  token: CryptoAsset;
}

const AvailableTokenRow = ({ token, ...rest }: Props) => {
  const router = useRouter();

  return (
    <Tr
      onClick={() => router.push(uiRoutes.assetDetailsCrypto(token?.baseToken))}
      _hover={{ cursor: 'pointer' }}
      role="group"
      {...rest}
    >
      <Td>{token && <AssetLogo assetId={token?.baseToken} showId />}</Td>

      <Td>
        {formatUsd({
          amount: Number(token.buyPrice),
        })}
      </Td>

      <Td textAlign="right">
        <IconButton
          aria-label={`Navigate to asset details for ${token.tokenName}`}
          icon={<IconCaretRight />}
          variant="ghost"
          _groupHover={{
            background: 'brand.50',
          }}
        />
      </Td>
    </Tr>
  );
};

export default AvailableTokenRow;
