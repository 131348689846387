'use client';

import { Button, Flex, Heading, Text } from '@chakra-ui/react';
import { useEffect } from 'react';

import { logError } from '~/utils';

const DefaultErrorMessage = ({ error, reset }: { error: Error & { digest?: string }; reset: () => void }) => {
  useEffect(() => {
    logError({ error });
  }, [error]);

  return (
    <Flex
      height="100vh"
      alignItems="center"
      justifyContent="center"
      flexDir="column"
      gap="6"
      width="50vw"
      marginX="auto"
    >
      <Heading as="h1" size="md">
        Something went wrong!
      </Heading>

      <Text textAlign="center" maxW="24rem">
        Our team has been notified and will investigate as soon as possible.
      </Text>

      <Button
        onClick={
          // Attempt to recover by trying to re-render the segment
          () => reset()
        }
      >
        Try again
      </Button>
    </Flex>
  );
};

export default DefaultErrorMessage;
