import { Button, Divider, Flex, Heading } from '@chakra-ui/react';
import { CfModal, CfPrettyWrap, uiColors } from '@cryptofi/core-ui';
import { useEffect, useState } from 'react';

import { CondensedSecuritiesDisclosure, CryptoDisclosure } from '~/components';
import { useFeatureSetEnabled, useGetFiInfo } from '~/hooks';

const DisclosuresModal = () => {
  const fiInfo = useGetFiInfo();
  const { isEnabled } = useFeatureSetEnabled();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (!sessionStorage.getItem('hideDisclosuresModal')) {
      setIsOpen(true);
    }
  }, []);

  return (
    <CfModal
      closeOnEsc={false}
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={() => {
        setIsOpen(false);
        sessionStorage.setItem('hideDisclosuresModal', 'true');
      }}
      headerContent={
        <Heading as="h1" fontSize="xs" color={uiColors.sonicSilver()} mr="4">
          <CfPrettyWrap>You are leaving online banking and entering the investments portal</CfPrettyWrap>
        </Heading>
      }
    >
      <Flex flexDir="column" gap="4" fontSize="2xs" color={uiColors.sonicSilver()} mb="2" mt="-2">
        {isEnabled(['securities']) && <CondensedSecuritiesDisclosure fiName={fiInfo.data?.fiName || ''} />}

        {isEnabled(['crypto', 'securities']) && <Divider />}

        {isEnabled(['crypto']) && <CryptoDisclosure fiName={fiInfo.data?.fiName || ''} isCondensed />}

        <Button
          fontSize="xs"
          variant="outline"
          onClick={() => {
            setIsOpen(false);
            sessionStorage.setItem('hideDisclosuresModal', 'true');
          }}
        >
          Dismiss
        </Button>
      </Flex>
    </CfModal>
  );
};

export default DisclosuresModal;
