'use client';

import { Button, ButtonGroupProps } from '@chakra-ui/react';
import { CfButtonGroup, uiColors } from '@cryptofi/core-ui';
import { Dispatch, SetStateAction } from 'react';

import { ChartTimeRange } from '~/customTypes';

interface Props extends ButtonGroupProps {
  selectedTimeRange: ChartTimeRange;
  setSelectedTimeRange: Dispatch<SetStateAction<ChartTimeRange>>;
}

const TimeRangeSelector = ({ selectedTimeRange, setSelectedTimeRange, ...rest }: Props) => {
  return (
    <CfButtonGroup w="full" p="2" gap="1" border="none" {...rest}>
      {[
        // { range: 'pastHour', text: '1H' },
        { range: 'pastDay', text: '1D' },
        { range: 'pastWeek', text: '1W' },
        { range: 'pastMonth', text: '1M' },
        { range: 'pastYear', text: '1Y' },
        { range: 'allTime', text: 'All' },
      ].map(({ range, text }) => {
        return (
          <Button
            key={range}
            marginInlineStart="0 !important"
            variant="none"
            flexGrow="1"
            fontSize="sm"
            fontFamily="heading"
            color={selectedTimeRange === range ? uiColors.black() : uiColors.coolElegance()}
            bg={selectedTimeRange === range ? uiColors.lighthouse() : 'transparent'}
            border="none"
            boxShadow="none"
            _hover={{
              bg: uiColors.lighthouse(),
            }}
            _focus={{
              bg: uiColors.lighthouse(),
            }}
            onClick={() => setSelectedTimeRange(range as ChartTimeRange)}
          >
            {text}
          </Button>
        );
      })}
    </CfButtonGroup>
  );
};

export default TimeRangeSelector;
