import { Box, Button, Flex, Heading, Text } from '@chakra-ui/react';
import { CfPrettyWrap, IconAlert, IconCheck, uiColors } from '@cryptofi/core-ui';
import { AxiosError } from 'axios';

import { getErrorDetail, isCrypto } from '~/utils';

import { useModalContext } from './ModalContext';

const TransactionResults = () => {
  const { selectedAssetId, sellQuantity, transactionType, netBuyQuantity, transactionState, buyCrypto, sellCrypto } =
    useModalContext();
  const isBuy = transactionType === 'buy';
  const errorData = isBuy ? buyCrypto.error : sellCrypto.error;

  const netBuyRounded = isCrypto(selectedAssetId) ? netBuyQuantity.toFixed(8) : netBuyQuantity.toFixed(5);
  const sellQuantityRounded = isCrypto(selectedAssetId) ? sellQuantity.toFixed(8) : sellQuantity.toFixed(5);
  const isSecuritySell = !isBuy && !isCrypto(selectedAssetId);

  return (
    <Flex py="4">
      <Flex alignItems="center" justifyContent="center" w="full" minHeight="14rem" flexDirection="column">
        {transactionState?.isSuccess && (
          <>
            <Box bg={uiColors.mint()} padding="3" borderRadius="full">
              <IconCheck
                __css={{
                  path: { fill: 'white' },
                }}
              />
            </Box>

            <Heading as="h2" textAlign="center" my="8">
              <Text fontSize="md" color={uiColors.sonicSilver()}>
                {isBuy ? 'Buy' : 'Sell'}
              </Text>

              <Text fontSize="2xl">
                {isBuy && `${netBuyRounded} ${selectedAssetId}`}

                {!isBuy && `${sellQuantityRounded} ${selectedAssetId}`}
              </Text>
            </Heading>

            <CfPrettyWrap textAlign="center">
              <Flex gap="4" flexDir="column">
                <Text>Your order is being processed.</Text>

                <Text>
                  {isSecuritySell
                    ? 'Please allow 1–2 business days for the proceeds of your sale to be credited to your account.'
                    : 'It may take a few minutes for your balances to update.'}
                </Text>
              </Flex>
            </CfPrettyWrap>
          </>
        )}

        {transactionState?.isError && (
          <>
            <Box bg={uiColors.heroicRed()} padding="3" borderRadius="full">
              <IconAlert
                __css={{
                  path: { fill: 'white' },
                }}
              />
            </Box>

            <Heading as="h2" textAlign="center" mt="8" fontFamily="body">
              <Text fontSize="md" color={uiColors.sonicSilver()}>
                {getErrorDetail(errorData as AxiosError)}
              </Text>
            </Heading>
          </>
        )}
      </Flex>
    </Flex>
  );
};

export default TransactionResults;

// external sub components

const FooterContent = () => {
  const { setModalView } = useModalContext();

  return (
    <Button
      width="full"
      variant="outline"
      onClick={() => {
        setModalView('startTransaction');
      }}
    >
      Try again
    </Button>
  );
};
TransactionResults.FooterContent = FooterContent;
