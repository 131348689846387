'use client';

import { Flex, FlexProps, Heading, IconButton } from '@chakra-ui/react';
import { IconCaretLeft, layoutMinWidth, uiColors } from '@cryptofi/core-ui';
import { useRouter } from 'next/navigation';

import GlobalModals from '~/components/GlobalModals/GlobalModals';
import { AllAssetIds } from '~/customTypes';

import Footer from './Footer';
import MobileNav from './MobileNav';
import PrimaryNav from './PrimaryNav';

interface Props extends FlexProps {
  showNav?: boolean;
  navHeading?: string;
  navSubHeading?: string;
  isSubPage?: boolean;
  noPadding?: boolean;
  bgColor?: string;
  hideTopNavBar?: boolean;
  defaultAssetId?: AllAssetIds;
}

const PageLayout = ({
  children,
  showNav = true,
  navHeading,
  navSubHeading,
  isSubPage,
  noPadding,
  bgColor,
  hideTopNavBar,
  defaultAssetId,
  ...rest
}: Props) => {
  const router = useRouter();

  return (
    <>
      <Flex flexDir={{ base: 'column', lg: 'row' }} minH="100vh" minWidth={layoutMinWidth} {...rest}>
        {showNav && !isSubPage && (
          <PrimaryNav
            display={{
              base: 'none',
              lg: 'flex',
            }}
          />
        )}

        {showNav && (
          <MobileNav
            heading={navHeading}
            subHeading={navSubHeading}
            isSubPage={isSubPage}
            display={{
              base: 'flex',
              lg: 'none',
            }}
          />
        )}

        <Flex
          alignItems="flex-start"
          justifyContent="flex-start"
          flexDir="column"
          width="full"
          marginX="auto"
          backgroundColor={bgColor || uiColors.white()}
          flexGrow="1"
        >
          {!hideTopNavBar && (
            <Flex
              w="full"
              justifyContent={isSubPage ? 'space-between' : 'center'}
              alignItems="center"
              px="3"
              py="4"
              display={{ base: 'none', lg: 'flex' }}
              backgroundColor={uiColors.white()}
              borderBottom="1px"
              borderBottomColor={uiColors.coolElegance()}
              height="16"
            >
              <IconButton
                position="absolute" // so heading is centered
                display={isSubPage ? 'flex' : 'none'}
                aria-label="Back to previous page"
                icon={<IconCaretLeft />}
                variant="ghost"
                size="sm"
                onClick={() => router.back()}
              />

              {typeof navHeading === 'string' && (
                <Flex flex={1} justifyContent="center" alignItems="center" gap={2}>
                  <Heading as="h1" size="md" textAlign="center">
                    {navHeading}
                  </Heading>

                  {navSubHeading && (
                    <Heading as="h2" fontSize="md" noOfLines={1} color={uiColors.sonicSilver()} fontFamily="body">
                      {navSubHeading}
                    </Heading>
                  )}
                </Flex>
              )}

              {typeof navHeading !== 'string' && navHeading}
            </Flex>
          )}

          <Flex
            w="full"
            padding={noPadding ? '0' : '4'}
            direction="column"
            flexGrow="1"
            pb="10"
            minHeight={{
              // push footer to bottom
              base: '87vh',
              md: '88.5vh',
            }}
          >
            {children}
          </Flex>

          <Footer />
        </Flex>
      </Flex>

      <GlobalModals defaultAssetId={defaultAssetId} />
    </>
  );
};

export default PageLayout;
