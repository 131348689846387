import { useQuery } from '@tanstack/react-query';
import { Camelized, camelizeKeys } from 'humps';

import { DbpAuthResponse } from '~/codegen/types';
import { useAxiosContext } from '~/hooks';
import { apiRoutes } from '~/routes';

const useGetAuth = ({ authCode }: { authCode: string | null }) => {
  const { apiClient } = useAxiosContext();

  return useQuery({
    queryKey: [apiRoutes.auth(), apiClient],
    enabled: !Boolean(authCode),
    queryFn: async () => {
      return apiClient!.get(apiRoutes.auth()).then((r) => {
        return camelizeKeys(r.data) as Camelized<DbpAuthResponse>;
      });
    },
  });
};

export default useGetAuth;
