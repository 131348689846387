import { tokenIdToName } from '~/constants';
import { CryptoAssetIds } from '~/customTypes';

const getMatchingCryptoAssetIds = ({
  cryptoIds,
  query,
}: {
  cryptoIds: CryptoAssetIds[] | undefined;
  query: string;
}) => {
  if (!cryptoIds) {
    return [];
  }

  return cryptoIds.filter(
    (assetId) =>
      new RegExp(`.*${query}.*`, 'i').test(assetId) ||
      new RegExp(`.*${query.split('').join('.*')}.*`, 'i').test(tokenIdToName[assetId as CryptoAssetIds]),
  ) as CryptoAssetIds[];
};

export default getMatchingCryptoAssetIds;
