import { Text } from '@chakra-ui/react';

const AVAX = () => (
  <>
    <Text>
      Avalanche (AVAX) is a decentralized platform that aims to provide a scalable and interoperable ecosystem for
      building custom blockchain networks and decentralized applications (DApps). Launched in September 2020 by Ava
      Labs, Avalanche distinguishes itself through its unique consensus protocol, Avalanche consensus, which offers high
      throughput, low latency, and security guarantees. With its focus on performance and flexibility, Avalanche aims to
      address the scalability limitations of existing blockchain platforms and enable a wide range of use cases,
      including DeFi, enterprise solutions, and asset tokenization.
    </Text>

    <Text>
      One of the key features of Avalanche is its subnets architecture, which allows developers to create custom
      blockchain networks tailored to their specific requirements. These subnets can interoperate seamlessly with the
      Avalanche mainnet, providing interoperability and composability between different blockchain networks.
      Avalanche&apos;s architecture also enables efficient cross-chain asset transfers and communication, facilitating
      decentralized exchange and liquidity provision across multiple networks.
    </Text>

    <Text>
      Avalanche has quickly gained traction within the blockchain community, attracting developers, investors, and users
      seeking scalable and interoperable solutions. Its growing ecosystem includes a diverse range of projects and
      applications, from decentralized finance protocols to decentralized identity solutions and gaming platforms. As
      Avalanche continues to evolve and expand its capabilities, it is poised to play a significant role in shaping the
      future of decentralized technology and driving innovation across various industries.
    </Text>
  </>
);

const BCH = () => (
  <>
    <Text>
      Bitcoin Cash (BCH) emerged as a fork of Bitcoin in August 2017, aiming to address scalability issues and improve
      transaction speed and cost-effectiveness. Advocates of Bitcoin Cash believed that increasing the block size limit
      would enable more transactions to be processed on the blockchain, thereby enhancing its utility as a peer-to-peer
      electronic cash system. Since its inception, Bitcoin Cash has attracted a dedicated community of supporters who
      champion its vision of a scalable, low-cost digital currency.
    </Text>

    <Text>
      One of the key features of Bitcoin Cash is its commitment to on-chain scaling, which prioritizes larger block
      sizes to accommodate a higher volume of transactions. This approach diverges from Bitcoins Lightning Network
      solution, which seeks to facilitate off-chain transactions for scalability. Proponents of Bitcoin Cash argue that
      on-chain scaling preserves the decentralized nature of the network and ensures that transactions remain accessible
      to all participants without relying on third-party solutions.
    </Text>

    <Text>
      Despite its vision and technical innovations, Bitcoin Cash has faced challenges and controversies within the
      broader cryptocurrency community. Differences in opinion regarding the direction of the project have led to
      contentious debates and splits within the community. However, Bitcoin Cash continues to evolve, with developers
      working on improvements to enhance its scalability, security, and usability. As the cryptocurrency landscape
      evolves, Bitcoin Cash remains a prominent player, offering an alternative vision for the future of digital cash.
    </Text>
  </>
);

const BTC = () => (
  <>
    <Text>
      Bitcoin emerged as a beacon of innovation and resilience in the aftermath of the 2008 financial crisis,
      representing a revolutionary approach to currency and financial systems. Satoshi Nakamoto&apos;s visionary
      whitepaper laid the foundation for a decentralized digital currency that would empower individuals and bypass the
      limitations of traditional centralized systems. Since its inception in 2009, Bitcoin has experienced remarkable
      growth, fueled by a vibrant community of developers, entrepreneurs, and enthusiasts who believe in its
      transformative potential.
    </Text>

    <Text>
      Despite initial skepticism, Bitcoin has steadily gained mainstream acceptance and adoption, transcending its niche
      origins to become a global phenomenon. Its decentralized nature ensures financial sovereignty for individuals,
      offering a censorship-resistant and borderless means of transacting value. The emergence of Bitcoin sparked a wave
      of innovation, inspiring countless entrepreneurs to explore the possibilities of blockchain technology and
      decentralized finance.
    </Text>

    <Text>
      Bitcoin&apos;s journey has been characterized by resilience in the face of challenges and adversity. Despite price
      volatility and regulatory uncertainties, its fundamental principles of scarcity, security, and transparency have
      remained steadfast. As Bitcoin continues to mature and evolve, it serves as a catalyst for positive change in the
      financial world, fostering greater financial inclusion, innovation, and economic empowerment on a global scale.
    </Text>
  </>
);

const ETH = () => (
  <>
    <Text>
      Ethereum (ETH) is a decentralized platform that enables developers to build and deploy smart contracts and
      decentralized applications (DApps). Launched in 2015 by Vitalik Buterin and a team of developers, Ethereum
      introduced a groundbreaking concept by extending blockchain technology beyond simple transactions. Smart
      contracts, self-executing contracts with the terms of the agreement directly written into code, allow for a wide
      range of applications, including decentralized finance (DeFi), non-fungible tokens (NFTs), and decentralized
      autonomous organizations (DAOs).
    </Text>

    <Text>
      One of Ethereum&apos;s defining features is its Turing-complete programming language, Solidity, which provides
      developers with flexibility and versatility in creating complex smart contracts and DApps. The Ethereum Virtual
      Machine (EVM) executes these smart contracts on the blockchain, enabling decentralized and trustless execution of
      code. This programmability has led to a thriving ecosystem of projects and innovations built on the Ethereum
      platform, ranging from decentralized exchanges (DEXs) to prediction markets and gaming applications.
    </Text>

    <Text>
      Despite its innovative capabilities, Ethereum has faced scalability challenges, particularly with high gas fees
      and network congestion during periods of high demand. To address these issues, Ethereum is undergoing a transition
      to Ethereum 2.0, a major upgrade that aims to improve scalability, security, and sustainability. Ethereum&apos;s
      continued evolution and commitment to innovation position it as a leading platform for decentralized applications,
      fueling the growth of the broader blockchain ecosystem and driving the future of finance and technology.
    </Text>
  </>
);

const LINK = () => (
  <>
    <Text>
      Chainlink (LINK) is a decentralized oracle network that connects smart contracts with real-world data, enabling
      blockchain applications to securely interact with external sources of information. Launched in 2017 by Sergey
      Nazarov and Steve Ellis, Chainlink addresses the oracle problem, which refers to the challenge of securely and
      reliably obtaining data from off-chain sources and integrating it into smart contracts. By providing a
      decentralized infrastructure for data feeds and APIs, Chainlink ensures the integrity and reliability of data
      inputs, enhancing the functionality and trustworthiness of smart contract applications.
    </Text>

    <Text>
      One of Chainlink&apos;s key features is its decentralized network of oracle nodes, which retrieve and verify data
      from various sources, including APIs, web services, and IoT devices. These nodes use cryptographic techniques and
      economic incentives to ensure the accuracy and reliability of data feeds, mitigating the risk of manipulation or
      tampering. Chainlink also offers a wide range of data aggregation and aggregation services, enabling developers to
      access a diverse array of data points and customize their smart contract applications.
    </Text>

    <Text>
      Chainlink has emerged as a critical infrastructure component for decentralized finance (DeFi), gaming, insurance,
      and supply chain applications, among others. Its robust and secure oracle solutions have garnered widespread
      adoption and integration within the blockchain ecosystem, with numerous projects leveraging Chainlink&apos;s
      services to enhance the functionality and reliability of their smart contracts. As the demand for trusted data and
      interoperability grows, Chainlink is poised to play a pivotal role in bridging the gap between blockchain networks
      and the external world.
    </Text>
  </>
);

const LTC = () => (
  <>
    <Text>
      Litecoin (LTC) is a peer-to-peer cryptocurrency created by Charlie Lee in October 2011 as a fork of the Bitcoin
      Core client. Designed to complement Bitcoin rather than compete with it, Litecoin aims to offer faster transaction
      confirmation times and improved scalability. It achieves this through its use of the Scrypt hashing algorithm,
      which allows for more efficient mining and quicker block generation times compared to Bitcoin&apos;s SHA-256
      algorithm. With its focus on speed and efficiency, Litecoin has established itself as one of the oldest and most
      widely adopted cryptocurrencies in the market.
    </Text>

    <Text>
      One of Litecoin&apos;s key features is its commitment to maintaining a high level of decentralization and
      security. Like Bitcoin, Litecoin operates on a decentralized network of nodes, ensuring censorship-resistant
      transactions and network integrity. Additionally, Litecoin has implemented various improvements over the years,
      including Segregated Witness (SegWit) and the Lightning Network, to enhance its scalability and transaction
      throughput. These upgrades have further solidified Litecoin&apos;s position as a reliable and efficient digital
      currency.
    </Text>

    <Text>
      Litecoin has garnered a strong community of supporters and users who value its fast transaction speeds, low fees,
      and robust security features. It has also found utility as a means of transferring value and a store of wealth,
      serving as a viable alternative to traditional fiat currencies. As the cryptocurrency landscape continues to
      evolve, Litecoin remains a prominent player, offering a stable and reliable option for individuals and businesses
      seeking to participate in the digital economy.
    </Text>
  </>
);

const XLM = () => (
  <>
    <Text>
      Stellar Lumens (XLM) is a decentralized platform designed to facilitate cross-border payments and asset transfers
      quickly and at low cost. Founded by Jed McCaleb and Joyce Kim in 2014, Stellar aims to provide financial inclusion
      to the unbanked and underbanked populations worldwide by connecting financial institutions, payment systems, and
      individuals through its open network. Unlike traditional banking systems that rely on correspondent banking,
      Stellar&apos;s blockchain enables direct peer-to-peer transactions, eliminating intermediaries and reducing
      transaction fees and settlement times.
    </Text>

    <Text>
      One of Stellar&apos;s key features is its consensus mechanism, which employs the Stellar Consensus Protocol (SCP)
      to reach agreement on the validity of transactions among network participants. SCP enables fast and scalable
      transaction processing while maintaining decentralization and security. Stellar also supports the issuance and
      trading of digital assets, known as tokens, which can represent various assets, including fiat currencies,
      commodities, and securities.
    </Text>

    <Text>
      Stellar has gained recognition for its partnerships with major financial institutions, payment processors, and
      non-profit organizations to facilitate cross-border payments and remittances. Its user-friendly interface and
      developer-friendly tools have made it accessible to a wide range of users and developers, fostering innovation and
      adoption within the Stellar ecosystem. As Stellar continues to expand its network and capabilities, it remains
      dedicated to its mission of creating an inclusive financial system that empowers individuals and promotes economic
      opportunity worldwide.
    </Text>
  </>
);

const XRP = () => (
  <>
    <Text>
      Ripple (XRP) is a digital payment network and cryptocurrency platform that aims to revolutionize cross-border
      transactions and remittances. At its core is XRP, the native cryptocurrency of the Ripple network, which serves as
      a bridge currency for facilitating fast and low-cost international money transfers.
    </Text>

    <Text>
      One of Ripple&apos;s key benefits is its lightning-fast transaction speed. While traditional bank transfers can
      take days to settle, Ripple transactions are typically confirmed within 4-5 seconds. This rapid settlement time
      makes it an attractive option for financial institutions and businesses that require quick cross-border payments.
    </Text>

    <Text>
      Ripple also has low transaction fees. The cost to complete a transaction on the Ripple network is just 0.00001
      XRP, which is a fraction of a penny at current rates. This makes it highly cost-effective for processing large
      volumes of transactions, especially when compared to traditional banking systems.
    </Text>

    <Text>
      Ripple&apos;s versatility is also noteworthy. The network can process transactions not only in XRP but also in
      other fiat currencies and cryptocurrencies. This flexibility allows for seamless currency exchanges and enhances
      its utility in the global financial ecosystem. Ripple has gained traction among large financial institutions.
      Major banks and payment providers have adopted Ripple&apos;s technology, demonstrating its potential for
      widespread institutional adoption.
    </Text>

    <Text>
      In December 2020, the SEC sued Ripple Labs, claiming XRP should be classified as a security. The case focused on
      whether XRP sales on exchanges violated securities laws. In July of 2023, a judge ruled in Ripple&apos;s favor,
      stating that these sales were not investment contract offers, thus not breaching securities laws. This was seen as
      a major win for Ripple and the cryptocurrency sector, influencing how digital assets are regulated and classified.
    </Text>
  </>
);

const ALGO = () => (
  <>
    <Text>
      Algorand (ALGO) is a decentralized, open-source blockchain platform designed to address the blockchain trilemma by
      offering scalability, security, and decentralization without compromise. Founded by Turing Award winner Silvio
      Micali in 2017, Algorand utilizes a unique consensus mechanism known as Pure Proof of Stake (PPoS), which allows
      for fast and efficient transaction processing while ensuring that even small holders of ALGO tokens can
      participate in the network&apos;s governance.
    </Text>

    <Text>
      Algorand&apos;s PPoS consensus mechanism is designed to avoid the energy-intensive processes of traditional Proof
      of Work systems, making it an environmentally friendly blockchain solution. The platform supports a variety of use
      cases, including decentralized finance (DeFi), tokenized assets, and enterprise applications. Its high throughput
      and low transaction costs make it suitable for applications requiring fast settlement times, such as payments and
      remittances.
    </Text>

    <Text>
      Algorand has gained significant adoption due to its focus on innovation and performance, with partnerships across
      industries, including finance, real estate, and supply chain management. The Algorand Foundation, which oversees
      the development of the ecosystem, also supports a robust developer community through grants and educational
      initiatives. As the ecosystem continues to grow, Algorand aims to enable a more equitable and accessible global
      economy by providing the infrastructure for decentralized applications and financial products.
    </Text>
  </>
);

const MKR = () => (
  <>
    <Text>
      Maker (MKR) is the governance token of the MakerDAO and Maker Protocol, which are part of the decentralized
      finance (DeFi) ecosystem. MakerDAO is a decentralized autonomous organization that enables users to create the Dai
      stablecoin, a cryptocurrency pegged to the value of the US dollar. MKR token holders have the ability to
      participate in the governance of the protocol by voting on key decisions such as risk parameters, collateral
      types, and upgrades to the system.
    </Text>

    <Text>
      One of the main features of the Maker Protocol is its ability to allow users to generate Dai by locking collateral
      such as Ethereum (ETH) and other digital assets in smart contracts known as Collateralized Debt Positions (CDPs).
      This system provides a decentralized alternative to traditional stablecoins, which are often backed by reserves
      held by a central authority. MKR plays a crucial role in maintaining the stability of Dai, as it is used to
      recapitalize the system in the event of insufficient collateral, ensuring the solvency of the protocol.
    </Text>

    <Text>
      MakerDAO and the MKR token have gained significant recognition for their pioneering role in the DeFi space. As one
      of the earliest and most widely used decentralized stablecoin platforms, Maker has established a robust ecosystem
      of users, developers, and partners. The platform’s decentralized governance model and the flexibility of its
      multi-collateral Dai system make it a key component of the growing decentralized finance landscape.
    </Text>
  </>
);

// components are exported as an object in order to lookup descriptions by asset ID
// eslint-disable-next-line import/no-anonymous-default-export
export default { AVAX, BCH, BTC, ETH, LINK, LTC, XLM, XRP, ALGO, MKR };
