'use client';

import { Flex, FlexProps, Heading, Show } from '@chakra-ui/react';
import { uiColors } from '@cryptofi/core-ui';

import { OnboardingStep as Step } from '~/customTypes';
import { uiConstants } from '~/theme';

interface Props extends FlexProps {
  stepData: Step;
  currentStep: number;
  isOnboarded: boolean;
  getSectionProgress: (step: number) => 0 | 10 | 100 | undefined;
}

const ProgressBarSection = ({ stepData, currentStep, isOnboarded, getSectionProgress, ...rest }: Props) => {
  // TODO: make sure progress is for the correct product in all scenarios
  const progress = getSectionProgress(stepData.stepNumber);

  // animate progress bar width changes for the current step
  const transition = stepData.stepNumber === currentStep ? `width ${uiConstants.transitionDuration}` : undefined;

  return (
    <Flex w="full" direction="column" gap={2} {...rest}>
      {/* Progress bar */}
      <Flex height={2} width="100%" borderRadius={16} bgColor="gray.200" overflow="hidden">
        <Flex height="100%" width={`${progress}%`} bgColor="brand.500" transition={transition} />
      </Flex>

      {/* Progress title and description */}
      <Show above="sm">
        <Flex w="full" direction="column" justifyContent="center">
          <Heading
            as="h2"
            color={stepData.stepNumber === currentStep && !isOnboarded ? 'brand.500' : uiColors.sonicSilver()}
            size="xs"
          >
            {stepData.title}
          </Heading>
        </Flex>
      </Show>
    </Flex>
  );
};

export default ProgressBarSection;
