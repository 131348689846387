import { HumpsProcessor } from 'humps';

/**
 * @description Helper function to bypass camelizeKeys on identifiers from our api that are all uppercase
 * i.e. BTC, ETH, etc.
 **/
export const ignoreUpperCaseKeys: HumpsProcessor = (key, convert, options) => {
  // the options argument past into convert is for recursion, it essentially will call this function
  // on keys of nested objects. DO NOT REMOVE
  return /^[A-Z0-9]+$/.test(key) ? key : convert(key, options);
};
