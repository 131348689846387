'use client';

import { CardProps, Divider, Flex, Heading, IconButton } from '@chakra-ui/react';
import { CfCard, CfPrettyWrap, IconCaretRight } from '@cryptofi/core-ui';
import { Camelized } from 'humps';
// eslint-disable-next-line no-restricted-imports
import NextLink from 'next/link';

import { FinancialInstitutionEducationContentSection } from '~/codegen/types';
import { defaultEducationImage, defaultEducationImages } from '~/constants';
import { uiRoutes } from '~/routes';
import { uiConstants } from '~/theme';

interface Props extends CardProps {
  section?: Camelized<FinancialInstitutionEducationContentSection>;
}

const EducationCard = ({ section, ...rest }: Props) => {
  const SectionImage = defaultEducationImages[section?.image || defaultEducationImage];

  return (
    section?.enabled && (
      <CfCard
        p={0}
        transition={`background-color ${uiConstants.transitionDuration}`}
        _hover={{
          background: uiConstants.hoverBackground,
        }}
        {...rest}
      >
        <Flex role="group" direction="column" height="full" as={NextLink} href={uiRoutes.sectionDetails(section.title)}>
          <Flex justifyContent="center">
            <SectionImage color="brand.400" objectFit="cover" h="10rem" w="auto" maxW="100%" />
          </Flex>

          <Divider />

          <Flex w="full" p={3} justifyContent="space-between" alignItems="center" gap="1">
            <CfPrettyWrap>
              <Heading as="h3" fontSize="small">
                {section?.title}
              </Heading>
            </CfPrettyWrap>

            <IconButton
              _groupHover={{
                background: 'brand.50',
              }}
              aria-label="Navigate to topic"
              icon={<IconCaretRight />}
              variant="ghost"
            />
          </Flex>
        </Flex>
      </CfCard>
    )
  );
};

export default EducationCard;
