import { UseMutationResult } from '@tanstack/react-query';
import { Camelized } from 'humps';
import { useRouter, useSearchParams } from 'next/navigation';
import pkceChallenge from 'pkce-challenge';
import Cookies from 'universal-cookie';

import { DbpAuthRequest, DbpTokenResponse } from '~/codegen/types';

const cookies = new Cookies();

const useAuthenticateUser = () => {
  const router = useRouter();
  const searchParams = useSearchParams();

  return ({
    authCode,
    redirectUrl,
    postAuth,
  }: {
    authCode: string | null;
    postAuth: UseMutationResult<Camelized<DbpTokenResponse>, Error, Camelized<DbpAuthRequest>, unknown>;
    redirectUrl?: string;
  }) => {
    if (!authCode && redirectUrl) {
      // User first visit the app, and useGetAuth is done fetching
      signInRedirect(redirectUrl);
    } else if (authCode) {
      // User got redirected back to our app,
      // and we DO NOT fetch useGetAuth again
      // We only post to retrieve the jwt-token
      const body = getPostBody(searchParams.get('code'));
      if (body && !postAuth.isPending) {
        // Ensure only one post request is sent at a time
        postAuth.mutate(body);

        // Reset the browser url, used only when not in an iframe
        if (window === window.parent) {
          router.push('/');
        }
      }
    }
  };
};

const signInRedirect = async (redirectUrl: string) => {
  const newUrl = new URL(redirectUrl);

  if (newUrl.searchParams.get('pkce_required') === 'True') {
    // Currently, required for Banno
    const challenge = await pkceChallenge(50);
    cookies.set('verifier', challenge.code_verifier);
    newUrl.searchParams.set('code_challenge', challenge.code_challenge);
  }

  window.location.replace(newUrl.toString());
};

const getPostBody = (authCode: string | null): Camelized<DbpAuthRequest> | undefined => {
  if (authCode) {
    let body: Camelized<DbpAuthRequest> = {
      code: authCode,
    };

    if (authCode && cookies.get('verifier')) {
      body = {
        ...body,
        codeVerifier: cookies.get('verifier'),
      };
    }

    return body;
  }
};

export default useAuthenticateUser;
