import { Flex, Heading } from '@chakra-ui/react';
import { ReactNode } from 'react';

const Disclosure = ({ heading, children }: { heading?: string; children: ReactNode }) => {
  return (
    <Flex
      gap="2"
      flexDirection="column"
      sx={{
        a: {
          textDecoration: 'underline',
        },
      }}
    >
      {heading && (
        <Heading as="h4" fontSize="2xs">
          {heading}
        </Heading>
      )}

      {children}
    </Flex>
  );
};

export default Disclosure;
