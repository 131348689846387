import { createIcon } from '@chakra-ui/react';

const IconCrypto101 = createIcon({
  displayName: 'Icon Crypto 101',
  viewBox: '0 0 500 500',
  path: (
    <>
      <g>
        <path
          fill="#CACACA"
          d="M132.36 86.75s-61.84 50.89-84.82 113.76S42.25 334 95.42 377.93s132.09 19.64 206.42 38.69S449 412.8 465.91 334.93s-36.63-94.08-62.13-174.5S280.19-24.69 132.36 86.75z"
        ></path>

        <path
          fill="#fff"
          d="M132.36 86.75s-61.84 50.89-84.82 113.76S42.25 334 95.42 377.93s132.09 19.64 206.42 38.69S449 412.8 465.91 334.93s-36.63-94.08-62.13-174.5S280.19-24.69 132.36 86.75z"
          opacity="0.7"
        ></path>
      </g>

      <g fill="none" stroke="#263238" strokeMiterlimit="10">
        <path d="M49.92 425.46L462 425.46"></path>

        <path d="M32.4 425.46L38.85 425.46"></path>
      </g>

      <g fill="none" stroke="#263238" strokeMiterlimit="10">
        <rect width="54.96" height="34.57" x="173.33" y="99.81" rx="1.66"></rect>

        <path d="M173.33 120.2H228.29000000000002V128.18H173.33z"></path>

        <circle cx="219.65" cy="106.46" r="3.77"></circle>

        <circle cx="213.89" cy="106.46" r="3.77"></circle>

        <path d="M178.21 105.35L193.28 105.35"></path>

        <rect width="54.96" height="34.57" x="56.7" y="254" rx="1.66"></rect>

        <path d="M56.7 274.39H111.66V282.37H56.7z"></path>

        <path d="M106.78 260.65a3.77 3.77 0 11-3.77-3.77 3.77 3.77 0 013.77 3.77z"></path>

        <circle cx="97.25" cy="260.65" r="3.77"></circle>

        <path d="M61.57 259.54L76.64 259.54"></path>

        <path d="M366.56 66.56H400.71V100.71000000000001H366.56z"></path>

        <path d="M394.19 81.01L385.48 81.01 385.48 72.31 381.79 72.31 381.79 81.01 373.09 81.01 373.09 84.7 381.79 84.7 381.79 93.41 385.48 93.41 385.48 84.7 394.19 84.7 394.19 81.01z"></path>

        <path d="M400.64 66.56H434.78999999999996V100.71000000000001H400.64z"></path>

        <path d="M408.61 81.01H426.8V84.17H408.61z"></path>

        <path d="M366.56 100.65H400.71V134.8H366.56z"></path>

        <path d="M389.79 108.18L383.64 114.33 377.49 108.18 374.88 110.79 381.03 116.94 374.88 123.09 377.49 125.7 383.64 119.55 389.79 125.7 392.4 123.09 386.25 116.94 392.4 110.79 389.79 108.18z"></path>

        <path d="M400.64 100.65H434.78999999999996V134.8H400.64z"></path>

        <path d="M408.61 115.09H426.8V118.25H408.61z"></path>

        <path d="M417.71 112.2a2.29 2.29 0 10-2.29-2.29 2.29 2.29 0 002.29 2.29z"></path>

        <circle cx="417.71" cy="123.8" r="2.29"></circle>

        <path d="M36.08 366H57.65V387.57H36.08z"></path>

        <path d="M53.52 375.13L48.03 375.13 48.03 369.63 45.7 369.63 45.7 375.13 40.2 375.13 40.2 377.46 45.7 377.46 45.7 382.95 48.03 382.95 48.03 377.46 53.52 377.46 53.52 375.13z"></path>

        <path d="M57.6 366H79.17V387.57H57.6z"></path>

        <path d="M62.64 375.13H74.13V377.13H62.64z"></path>

        <path d="M36.08 387.53H57.65V409.09999999999997H36.08z"></path>

        <path d="M50.75 392.28L46.86 396.17 42.98 392.28 41.33 393.93 45.22 397.82 41.33 401.7 42.98 403.35 46.86 399.46 50.75 403.35 52.4 401.7 48.51 397.82 52.4 393.93 50.75 392.28z"></path>

        <path d="M57.6 387.53H79.17V409.09999999999997H57.6z"></path>

        <path d="M62.64 396.65H74.13V398.65H62.64z"></path>

        <circle cx="68.38" cy="393.38" r="1.44"></circle>

        <path d="M68.38 400.7a1.45 1.45 0 101.45 1.45 1.45 1.45 0 00-1.45-1.45zM297.48 109.2a.24.24 0 00-.2.23v1.48a.49.49 0 01-.56.56h-1.95a.49.49 0 01-.56-.56l.07-1.51a.26.26 0 00-.2-.24c-3.76-.46-5.93-2.73-5.93-5.9v-1.41a.5.5 0 01.56-.56h3.42c.37 0 .57.16.57.42v.47a3 3 0 003.32 3.13c2.11 0 3-1.29 3-2.61 0-2.34-4.28-2.7-7.45-4.74-2-1.26-3.46-2.87-3.46-5.67 0-3.46 2.15-5.9 5.94-6.46a.25.25 0 00.19-.23l-.06-1.78a.49.49 0 01.56-.56h1.88a.5.5 0 01.56.56v1.74a.23.23 0 00.19.23c4 .43 6.2 2.84 6.2 6.23v1.09a.51.51 0 01-.56.56h-3.46c-.36 0-.56-.13-.56-.33V93a3.09 3.09 0 00-3.3-3.33c-1.84 0-3 1-3 2.44 0 2.14 3 2.47 6.09 3.83 3.4 1.51 4.88 3.62 4.88 6.52.05 3.64-2.16 6.21-6.18 6.74z"></path>

        <circle cx="295.66" cy="97.54" r="18.74"></circle>

        <path d="M147.33 74.35a.17.17 0 00-.14.16v1a.33.33 0 01-.38.37h-1.3a.33.33 0 01-.38-.37l.05-1a.17.17 0 00-.13-.15c-2.52-.31-4-1.83-4-4v-.95a.35.35 0 01.38-.38h2.29c.25 0 .38.11.38.29v.31a2 2 0 002.23 2.1 1.76 1.76 0 002-1.75c0-1.56-2.87-1.81-5-3.18a4.16 4.16 0 01-2.25-3.8 4.21 4.21 0 014-4.33.17.17 0 00.14-.15l-.05-1.19a.33.33 0 01.38-.38h1.26a.34.34 0 01.37.38v1.17a.16.16 0 00.13.15c2.65.29 4.15 1.9 4.15 4.17v.73a.34.34 0 01-.46.45h-2.31c-.25 0-.38-.08-.38-.22v-.22a2.06 2.06 0 00-2.2-2.23c-1.24 0-2 .64-2 1.64 0 1.43 2 1.65 4.08 2.56 2.28 1 3.27 2.43 3.27 4.37.04 2.38-1.46 4.1-4.13 4.45z"></path>

        <circle cx="146.1" cy="66.55" r="12.55" transform="rotate(-45 146.103 66.542)"></circle>

        <path d="M50.19 200.07a.15.15 0 00-.13.15v1c0 .27-.13.38-.37.38H48.4c-.24 0-.37-.11-.37-.38v-1a.17.17 0 00-.13-.16c-2.52-.3-4-1.83-4-3.95v-1a.33.33 0 01.37-.37h2.3c.24 0 .37.11.37.29v.31a2 2 0 002.23 2.09 1.76 1.76 0 002-1.74c0-1.57-2.87-1.81-5-3.18a4.31 4.31 0 011.66-8.12.16.16 0 00.13-.16v-1.19a.32.32 0 01.37-.37h1.26a.34.34 0 01.38.37v1.17a.18.18 0 00.14.16c2.64.28 4.15 1.89 4.15 4.17v.73a.34.34 0 01-.38.37h-2.3c-.24 0-.37-.09-.37-.22v-.22A2.07 2.07 0 0049 187c-1.24 0-2 .64-2 1.63 0 1.44 2 1.66 4.09 2.56 2.27 1 3.26 2.43 3.26 4.37.01 2.44-1.47 4.15-4.16 4.51z"></path>

        <circle cx="48.97" cy="192.26" r="12.55" transform="rotate(-45 48.97 192.266)"></circle>

        <path d="M463.22 371.81a.15.15 0 00-.13.15v1c0 .27-.13.38-.37.38h-1.31c-.24 0-.37-.11-.37-.38v-1a.17.17 0 00-.13-.16c-2.52-.31-4-1.83-4-3.95v-.95a.33.33 0 01.37-.37h2.3c.24 0 .37.11.37.29v.3a2 2 0 002.23 2.1 1.76 1.76 0 002-1.74c0-1.57-2.87-1.81-5-3.18a4.31 4.31 0 011.66-8.12.17.17 0 00.13-.16v-1.19a.32.32 0 01.37-.37h1.26a.34.34 0 01.38.37V356a.16.16 0 00.14.15c2.64.29 4.15 1.9 4.15 4.18v.67a.34.34 0 01-.38.38h-2.32c-.24 0-.37-.09-.37-.22V361a2.06 2.06 0 00-2.21-2.23c-1.24 0-2 .64-2 1.63 0 1.44 2 1.66 4.09 2.56 2.27 1 3.26 2.43 3.26 4.37.05 2.4-1.43 4.12-4.12 4.48z"></path>

        <circle cx="462" cy="364" r="12.55"></circle>
      </g>

      <g>
        <path
          fill="#ccc"
          d="M421.85 448.51c0 3.34-76.58 6.05-171 6.05s-171-2.71-171-6.05 76.58-6 171.05-6 170.95 2.67 170.95 6z"
        ></path>

        <path
          fill="#fff"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M238.5 394.21s8.32 35.76 4.7 41.63c-4.74 7.69-55.15 12.32-55.15 12.32h111.16s13.93-3.91 9.74-15.11c-3.24-8.66-18.79-38.84-18.79-38.84z"
        ></path>

        <path
          fill="#fff"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M236.06 394.21s8.88 36.06 4.7 41.63-52.71 9.84-52.71 9.84h111.68s9.39-3.79 6.78-12.63-18.78-38.84-18.78-38.84z"
        ></path>

        <path
          fill="#263238"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M287.73 394.21h-51.67s5.26 21.36 5.77 33.74h62.75c-5.08-11.9-16.85-33.74-16.85-33.74z"
        ></path>

        <path
          fill="#fff"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M188.05 445.86H299.20000000000005V448.16H188.05z"
        ></path>

        <rect
          width="335.38"
          height="251.53"
          x="97.99"
          y="146.19"
          fill="#fff"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          rx="8.48"
        ></rect>

        <rect
          width="335.38"
          height="251.53"
          x="90.09"
          y="146.19"
          fill="#263238"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          rx="8.48"
        ></rect>

        <path
          fill="#fff"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M90.09 358.63h335.38v30.61a8.48 8.48 0 01-8.48 8.48H98.57a8.48 8.48 0 01-8.48-8.48v-30.61h0z"
        ></path>

        <path
          fill="#fff"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M106.96 161.84H408.59999999999997V339.55H106.96z"
        ></path>

        <path fill="#CACACA" d="M122.08 264.15H378.14V275.39H122.08z"></path>

        <path fill="#CACACA" d="M122.08 285.39H378.14V296.63H122.08z"></path>

        <path fill="none" stroke="#263238" strokeMiterlimit="10" d="M122.06 197.11L167.11 197.11"></path>

        <path fill="none" stroke="#263238" strokeMiterlimit="10" d="M122.06 203.73L167.11 203.73"></path>

        <path fill="none" stroke="#263238" strokeMiterlimit="10" d="M268.13 203.73L313.18 203.73"></path>

        <path fill="none" stroke="#263238" strokeMiterlimit="10" d="M268.13 210.32L313.18 210.32"></path>

        <path fill="none" stroke="#263238" strokeMiterlimit="10" d="M268.13 216.91L313.18 216.91"></path>

        <path fill="none" stroke="#263238" strokeMiterlimit="10" d="M122.06 210.36L167.11 210.36"></path>

        <path fill="none" stroke="#263238" strokeMiterlimit="10" d="M122.06 321.55L167.11 321.55"></path>

        <path fill="none" stroke="#263238" strokeMiterlimit="10" d="M122.06 327.69L167.11 327.69"></path>

        <path fill="none" stroke="#263238" strokeMiterlimit="10" d="M122.06 216.98L167.11 216.98"></path>

        <path fill="#CACACA" d="M122.06 223.61H230.04000000000002V235.53H122.06z"></path>

        <path fill="#CACACA" d="M320.79 211.68H377.76V217.64000000000001H320.79z"></path>

        <path
          fill="none"
          stroke="#263238"
          strokeMiterlimit="10"
          d="M320.79 196.45H377.76V217.64999999999998H320.79z"
        ></path>

        <path fill="none" stroke="#263238" strokeMiterlimit="10" d="M320.79 202.41H377.76V211.68H320.79z"></path>

        <path fill="none" stroke="#263238" strokeMiterlimit="10" d="M329.46 196.63L329.46 217.39"></path>

        <path fill="none" stroke="#263238" strokeMiterlimit="10" d="M122.08 243.54H378.14V315.36H122.08z"></path>

        <path fill="#CACACA" stroke="#263238" strokeMiterlimit="10" d="M122.08 243.54H378.14V254.78H122.08z"></path>

        <path fill="none" stroke="#263238" strokeMiterlimit="10" d="M302.85 243.81L302.85 315.24"></path>

        <path fill="none" stroke="#263238" strokeMiterlimit="10" d="M330.68 243.81L330.68 315.24"></path>

        <path fill="#CACACA" d="M302.62 319.63H330.59000000000003V336.26H302.62z"></path>

        <path
          fill="#263238"
          d="M313.18 325.93v1.28c0 1.57-.75 2.42-2.3 2.42h-.72v3.69h-1.54v-9.81h2.26c1.55 0 2.3.85 2.3 2.42zm-3-1v3.32h.72c.5 0 .76-.22.76-.92v-1.48c0-.7-.26-.92-.76-.92zM319.26 333.32h-1.56l-.26-1.78h-1.89l-.27 1.78h-1.41l1.56-9.81h2.26zm-3.52-3.11h1.49l-.74-5zM321.29 330.07l-2-6.56H321l1.18 4.47 1.17-4.47h1.48l-1.95 6.56v3.25h-1.54zM122.62 177.87h2.31v14.7h-2.31zM129.09 181.92v10.65H127v-14.7h2.9l2.37 8.8v-8.8h2.06v14.7H132zM139.9 189.86l1.77-12h2.12l-2.27 14.7h-3.44l-2.27-14.7h2.33zM145.07 181.4c0-2.36 1.24-3.7 3.51-3.7s3.5 1.34 3.5 3.7v7.6c0 2.35-1.23 3.69-3.5 3.69s-3.51-1.34-3.51-3.69zm2.31 7.79c0 1 .46 1.44 1.2 1.44s1.19-.4 1.19-1.44v-7.94c0-1.05-.46-1.45-1.19-1.45s-1.2.4-1.2 1.45zM154 177.87h2.31v14.7H154zM165.1 187.09V189c0 2.35-1.17 3.69-3.44 3.69s-3.44-1.34-3.44-3.69v-7.6c0-2.36 1.17-3.7 3.44-3.7s3.44 1.34 3.44 3.7v1.42h-2.18v-1.57c0-1.05-.46-1.45-1.2-1.45s-1.19.4-1.19 1.45v7.94c0 1 .46 1.42 1.19 1.42s1.2-.38 1.2-1.42v-2.1zM169.24 184.06h3.17v2.1h-3.17v4.31h4v2.1h-6.3v-14.7h6.3V180h-4z"
        ></path>

        <path
          fill="#CACACA"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M106.96 161.84H408.59999999999997V170.05H106.96z"
        ></path>

        <path
          fill="#263238"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M264 378.18a6.19 6.19 0 10-6.18 6.18 6.19 6.19 0 006.18-6.18z"
        ></path>
      </g>

      <g>
        <path
          fill="#ccc"
          d="M434.47 462.81c0 4-17.78 7.19-39.72 7.19S355 466.78 355 462.81s17.79-7.19 39.72-7.19 39.75 3.22 39.75 7.19z"
        ></path>

        <path
          fill="#CACACA"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M370.2 278.5s-4.28 1.6-5.61 7.62-4.15 24.2-5.62 32.76-9.36 36.36-9.36 36.36l4.15 2.81s10.16-14.84 13-21.79 5.61-11.77 6-17.12-.26-34.76-.26-37-1.09-3.91-2.3-3.64z"
        ></path>

        <path
          fill="#fff"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M374.08 453.07s-10.56 6.82-11.77 9.22 1.07 3.08 7 2.28 10-3.88 12.83-5.49 4.15-1.73 3.21-5.34-11.27-.67-11.27-.67zM415.39 454.14s11.64 7.88 11 9.62-4.15 2.54-10.3.54-7.49-3.61-10-5.75-1.6-4.81-1.6-6.69 10.9 2.28 10.9 2.28z"
        ></path>

        <path
          fill="#263238"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M368.6 332s-2.41 21.12-2.68 38 7.49 81.11 7.49 81.78v2a18.48 18.48 0 006.29 1.6 52.94 52.94 0 006.81-.27s0-11.9.27-21.12 1.74-51.3 1.74-51.3l.4-18.18 2.67 1.74 12.57 89.13 11.23-1.2s1.34-59.32 1.07-75.63-3.46-45.1-3.46-45.1z"
        ></path>

        <path
          fill="#CACACA"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M389.59 272.22s-17.78 5.48-19.39 6.28-2.67 5.08-2.67 10.43 2 31.82 1.74 35-1.87 7.22-.54 9.49 19 3.74 28.48 3.34 14.84.54 16.18-2.14 2.14-35.69 3.47-43.32 2.28-11.63-.4-13.77-16.84-4.68-20.32-5.48z"
        ></path>

        <path
          fill="#fff"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M389 268.85s-2.95 1-3.93 3.21-3.92 13.21-2.85 13.74 9.37-3.48 10.62-3.21 6.33 6.87 7.85 6 5.09-10.26 3.75-15.08-14.11-5.91-15.44-4.66z"
        ></path>

        <path
          fill="#ccc"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M387.38 265.82s-.09 4.9-.09 7.4 2.15 11.42 4 11.07 9.64-9.1 10.89-11.43 1.51-7.13 1.51-7.13-5.53 3.92-9.72 2.67a36.52 36.52 0 01-6.59-2.58z"
        ></path>

        <path
          fill="#263238"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M394 268.4a36.52 36.52 0 01-6.61-2.58s0 1.93-.06 4c.61 1.31 1.19 2.41 1.6 3.19a5.18 5.18 0 006.62 2.77 21.42 21.42 0 007.56-5.59 30.42 30.42 0 00.61-4.44s-5.54 3.9-9.72 2.65z"
        ></path>

        <path
          fill="#ccc"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M385.28 251.46s-.5-3.56-2.18-2.11-1.38 5.09-.58 6.84 1.67 1.53 1.89 1.45.87-6.18.87-6.18z"
        ></path>

        <path
          fill="#263238"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M408.56 254.88c.22-.36 2.91-9.38 3.06-14.26s-6.91-9.45-11.93-10.4-12.44.29-14 3.13-1.75 14-1.75 15.86 1.46 2.18 1.82 1.74 1.46-11.42 2.11-12.22 12.29-.43 14.11.07 2.84 2.41 2.48 5.17.07 2.62.8 4.22.72 2.18.72 4.36 1.02 4.8 2.58 2.33z"
        ></path>

        <path
          fill="#ccc"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M386.89 238.37a121.69 121.69 0 00-2.62 16.22c-.37 6.47 3.2 13.16 4.65 15.93s4.08 3.71 6.62 2.76a22.36 22.36 0 008.8-7c2.69-3.49 4.08-11.86 4.08-11.86s-1.1.58-1.17-.36.66-4.22-.07-6.33-2.33-2.62-1.38-4.66a13.61 13.61 0 001.31-3.63c0-.37-3.64-2.55-10.26-3.13s-9.6.24-9.96 2.06z"
        ></path>

        <path
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M387.61 244.33s1.95-1.69 4.66.26M397.45 245.1s4.66-1.87 6.53 1.44"
        ></path>

        <path
          fill="#ccc"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M407.69 255.46s1.45-3.27 3.56-3.42 1.82 4-.14 6.7-4.8 3.78-4.88 1.81"
        ></path>

        <path
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M393.43 249.57s-2.4 6.91-1.89 7.86 2.47 2 4.87.87M394.38 261.57a6.58 6.58 0 006.25-1.09"
        ></path>

        <path
          fill="#263238"
          d="M391 249.82c0 .79-.28 1.42-.62 1.42s-.62-.63-.62-1.42.28-1.41.62-1.41.62.59.62 1.41z"
        ></path>

        <ellipse cx="399.51" cy="250.77" fill="#263238" rx="0.62" ry="1.42"></ellipse>

        <path
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M393.5 249.39a4.18 4.18 0 11-4.18-4.18 4.18 4.18 0 014.18 4.18zM404.35 250.29a4.4 4.4 0 11-4.4-4.41 4.4 4.4 0 014.4 4.41zM393.5 248.62a1.67 1.67 0 012 .37"
        ></path>

        <path
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M404.27 250.73L409.51 252.92"
        ></path>

        <path
          fill="#fff"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M350.68 352.17L356.3 354.44"
        ></path>

        <path
          fill="#ccc"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M345.5 369.29s.79 4.62 1.33 4.87.73-2.25.55-3.83l-.18-1.58s.85 4.13 1.7 4.5.37-1.46.12-2.38l-.24-.91s2.13 2.25 2.31 1.7-1.28-3.28-1.34-3.46a22.49 22.49 0 01-.75-3.2c-.06-.85-1.27-4.26-2.49-2.13s-1.01 6.42-1.01 6.42z"
        ></path>

        <path
          fill="#ccc"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M349.61 355.24s-4 6.42-5.08 8.56-1.87 10.16-.93 10.7 1.19-1.42 1.41-2.77a21.07 21.07 0 011.7-4.63s-.57 5.12.23 5 1.47-4.81 2-6 3.75-2.94 4.82-8z"
        ></path>

        <path
          fill="#fff"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M346.71 367.1s0-.68.49-3.22"
        ></path>

        <path
          fill="#CACACA"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M415.93 277.3a20 20 0 016.68 8.95c2.54 6.29 11.23 33.7 11.5 34.23s0 5.88-4.28 7.62-6.28-2-8.82-6.28-8.42-23.27-8.42-23.27"
        ></path>

        <path
          fill="#CACACA"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M426.89 312.73l20.76-10.2 3.61 4.15s-15.26 18.61-18.62 20.75-7.49 1.61-9.09-2"
        ></path>

        <path
          fill="#fff"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M445.38 304L449.25 308.68"
        ></path>

        <path
          fill="#ccc"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M447.65 302.53s4.55-7.08 5.88-7.62 5.08-1.33 7.22-2.94 3.34-1.2 2.14.54-2.54 2-3.74 2.54a9.48 9.48 0 00-1.74.93s3.34.14 5.48.27a15.15 15.15 0 003.88-.27s5.48-2.4 4.14.54-1.2 4.81-6.55 6.41a38.67 38.67 0 01-10.69 1.48l-2.41 2.27z"
        ></path>

        <path
          fill="#ccc"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M468.64 300.93a5.89 5.89 0 003.61-3.74c.94-2.95.4-3.08-.8-2a14.29 14.29 0 00-2.81 4.15c-.64 1.32 0 1.59 0 1.59z"
        ></path>

        <path
          fill="#ccc"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M467.44 301.06s1.6 1.07 2.81-2.14 1.06-5.75 0-5.21-1.34.67-2.68 3.48-2.54 4-1.74 4.27a1.9 1.9 0 001.61-.4z"
        ></path>

        <path
          fill="#ccc"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M452.06 302.27s2-3.35 4.28-3.35a76.94 76.94 0 018.29 1.07s4.41-6.41 5.35-5.48-1.47 5.35-2.54 6.55-1.21 1.34-2.14 1.61"
        ></path>

        <path
          fill="none"
          stroke="#7d7d7d"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M388.92 364.47L382.77 361.53"
        ></path>
      </g>

      <rect width="500" height="500" fill="currentColor" style={{ mixBlendMode: 'overlay' }} />
    </>
  ),
});
export default IconCrypto101;
