import { ReactNode } from 'react';

import isDev from '~/utils/isDev';

const DevOnly = ({ children }: { children: ReactNode }) => {
  const { isLocalDev, isVercelDev } = isDev();

  return isLocalDev || isVercelDev ? children : null;
};

export default DevOnly;
