'use client';

import { Flex, Heading, Table, Tbody, Text } from '@chakra-ui/react';
import { CfLineChart, CfSpinner, uiColors } from '@cryptofi/core-ui';
import { size } from 'lodash';

import { TokenEnum } from '~/codegen/types';
import { AssetListItem } from '~/components';
import { securitiesRefetchInterval } from '~/constants';
import { CryptoAssetIds } from '~/customTypes';
import { useFeatureSetEnabled, useGetFiInfo, useGetSearchSecurities, useGetTokenPrices } from '~/hooks';
import { getMatchingCryptoAssetIds } from '~/utils';

import { SelectedFilter } from './AssetsSearch';

export default function AssetsSearchResults({
  selectedFilter,
  onClickCryptoAsset,
  onClickSecurity,
  query,
}: {
  selectedFilter: SelectedFilter;
  onClickCryptoAsset: (asset: TokenEnum) => void;
  onClickSecurity: (ticker: string) => void;
  query: string;
}) {
  const { isEnabled } = useFeatureSetEnabled();
  const fiInfo = useGetFiInfo();
  const securities = useGetSearchSecurities({ query, refetchInterval: securitiesRefetchInterval });
  const tokenPrices = useGetTokenPrices();
  const cryptoMatchIds = getMatchingCryptoAssetIds({
    cryptoIds: fiInfo.data?.enabledFeatureSet?.enabledAssets as CryptoAssetIds[],
    query,
  });
  const cryptoResults = tokenPrices.data?.filter((token) => cryptoMatchIds.includes(token.baseToken)) || [];

  const shouldShowCrypto = (selectedFilter === 'all' || selectedFilter === 'crypto') && isEnabled(['crypto']);
  const shouldShowSecurities =
    (selectedFilter === 'all' || selectedFilter === 'securities') && isEnabled(['securities']);
  const shouldShowCryptoHeader = cryptoResults.length > 0 && isEnabled(['crypto']);
  const shouldshowSecuritiesHeader = size(securities?.data) > 0 && isEnabled(['securities']);

  if (securities.isSuccess && !cryptoResults.length && !size(securities.data)) {
    return (
      <Flex justifyContent="center" alignItems="center" h="full" color={uiColors.sonicSilver()}>
        No matching results were found.
      </Flex>
    );
  }

  return (
    <Flex overflow="scroll" flexDir="column">
      {securities.isLoading && <CfSpinner />}

      {securities.isSuccess && shouldShowSecurities && (
        <>
          {shouldshowSecuritiesHeader && (
            <Flex
              justifyContent="space-between"
              alignItems="center"
              borderBottom="1px solid"
              borderColor={uiColors.coolElegance()}
              py={2}
            >
              <Heading size="xs" color={uiColors.sonicSilver()}>
                Stocks
              </Heading>

              <Text fontSize="2xs" color={uiColors.sonicSilver()}>
                Offered by CDS1 Securities LLC
              </Text>
            </Flex>
          )}

          <Table mb="4">
            <Tbody>
              {securities.data?.map((security, i) => (
                <AssetListItem
                  key={security.securitiesTicker}
                  assetId={security.securitiesTicker || ''}
                  previousClosingPrice={security.lastTradingDayInfo?.closePrice || undefined}
                  buyPrice={security.currentPrice?.value || undefined}
                  onAssetClick={() => onClickSecurity(security.securitiesTicker || '')}
                  // eslint-disable-next-line no-restricted-syntax
                  isLastRow={i + 1 === size(securities)}
                />
              ))}
            </Tbody>
          </Table>
        </>
      )}

      {/* defer showing crypto results until securities are loaded to avoid visual thrashing */}
      {securities.isSuccess && shouldShowCrypto && (
        <>
          {shouldShowCryptoHeader && (
            <Flex
              justifyContent="space-between"
              alignItems="center"
              borderBottom="1px solid"
              borderColor={uiColors.coolElegance()}
              py={2}
            >
              <Heading size="xs" color={uiColors.sonicSilver()}>
                Cryptocurrency
              </Heading>

              <Text fontSize="2xs" color={uiColors.sonicSilver()}>
                Offered by SAFE Trust Co.
              </Text>
            </Flex>
          )}

          <Table mb="4">
            <Tbody>
              {cryptoResults?.map((token, i) => (
                <AssetListItem
                  key={token.baseToken}
                  assetId={token.baseToken}
                  previousClosingPrice={token.sparkline?.[0].value}
                  buyPrice={token.buyPrice}
                  onAssetClick={() => onClickCryptoAsset(token.baseToken)}
                  // eslint-disable-next-line no-restricted-syntax
                  isLastRow={i + 1 === size(cryptoResults)}
                >
                  {token.sparkline && (
                    <CfLineChart
                      data={token.sparkline}
                      dataKey="value"
                      sampleRate={4}
                      style={{ pointerEvents: 'none', maxWidth: '12rem' }}
                    />
                  )}
                </AssetListItem>
              ))}
            </Tbody>
          </Table>
        </>
      )}
    </Flex>
  );
}
