'use client';

import { createContext, type ReactNode, useRef } from 'react';
import { type StoreApi } from 'zustand';

import { GlobalStore } from '~/customTypes';
import { createGlobalStore } from '~/stores';

// only access this store via the useGlobalStore hook
export const GlobalStoreContext = createContext<StoreApi<GlobalStore> | null>(null);

export const StoreProvider = ({ children }: { children: ReactNode }) => {
  const storeRef = useRef<StoreApi<GlobalStore>>();
  if (!storeRef.current) {
    storeRef.current = createGlobalStore();
  }

  return <GlobalStoreContext.Provider value={storeRef.current}>{children}</GlobalStoreContext.Provider>;
};
