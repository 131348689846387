import { formatUsd } from '@cryptofi/core-ui';
import Big from 'big.js';
import * as Yup from 'yup';

import { TransactionLimits } from '~/codegen/types';

// buy and sell forms use the same schema
// sell fields are made optional in buy mode and vice versa
export const transactionFormSchema = ({
  buyPrice,
  buyTransactionLimits,
  sellPrice,
  sellTransactionLimits,
}: {
  buyPrice: number;
  buyTransactionLimits: TransactionLimits;
  sellPrice: number;
  sellTransactionLimits: TransactionLimits;
}) => {
  const buyMin = Number(buyTransactionLimits.min);
  const buyMax = Number(buyTransactionLimits.max);
  const sellMin = Number(sellTransactionLimits.min);
  const sellMax = Number(sellTransactionLimits.max);

  const errors = {
    accountRequired: 'Bank account is required',
    minBuy: `Minimum buy amount is ${formatUsd({ amount: buyMin })}`,
    maxBuy: `Maximum buy amount is ${formatUsd({ amount: buyMax })}`,
    minSell: `Minimum sale amount is ${formatUsd({ amount: sellMin })}`,
    maxSell: `Maximum sale amount is ${formatUsd({ amount: sellMax })}`,
    positiveAmount: 'Amount must be greater than 0',
    insufficientBuyBalance: 'Please select an account with sufficient funds',
    insufficientSellBalance: 'Your available balance is not sufficient for this transaction.',
  };

  const buyFields = {
    buyIsUsd: Yup.boolean(),
    buyAvailableBalance: Yup.number(),

    buyCurrencyAmount: Yup.mixed()
      .required('') // no error message here because the button gets disabled if empty
      .when(['transactionType', 'buyIsUsd'], ([transactionType, buyIsUsd], schema, resolve) => {
        const currencyAmount = resolve.parent.buyCurrencyAmount;

        // validate USD input
        if (transactionType === 'buy' && buyIsUsd) {
          return Yup.number().positive(errors.positiveAmount).min(buyMin, errors.minBuy).max(buyMax, errors.maxBuy);
        }

        // validate asset input converted to USD
        if (transactionType === 'buy' && !buyIsUsd) {
          const usdAmount = Big(buyPrice)
            .mul(currencyAmount || 0)
            .toNumber();

          return Yup.number()
            .positive(errors.positiveAmount)
            .test('minCheck', errors.minBuy, () => {
              if (currencyAmount) {
                return usdAmount >= buyMin;
              }

              return true;
            })
            .test('maxCheck', errors.maxBuy, () => {
              if (currencyAmount) {
                return usdAmount <= buyMax;
              }

              return true;
            });
        }

        if (transactionType === 'sell') {
          return Yup.mixed().optional();
        }

        return schema;
      }),

    buyAccountId: Yup.string()
      .required(errors.accountRequired)
      .when(['transactionType', 'buyIsUsd'], ([transactionType, buyIsUsd], schema) => {
        const isUsd = transactionType === 'buy' && buyIsUsd;

        // validate available balance against currency amount in USD
        if (isUsd) {
          return Yup.string().test('balanceCheck', errors.insufficientBuyBalance, (_, context) => {
            const { buyAvailableBalance, buyCurrencyAmount } = context.parent;

            if (buyCurrencyAmount) {
              return buyAvailableBalance >= Number(buyCurrencyAmount);
            }

            return true;
          });
        }

        // validate available balance against currency amount in asset quantity converted to USD
        if (!isUsd) {
          return Yup.string().test('balanceCheck', errors.insufficientBuyBalance, (_, context) => {
            const { buyAvailableBalance, buyCurrencyAmount } = context.parent;

            if (buyCurrencyAmount) {
              const usdAmount = Big(buyPrice)
                .mul(buyCurrencyAmount || 0)
                .toNumber();

              return buyAvailableBalance >= Number(usdAmount);
            }

            return true;
          });
        }

        if (transactionType === 'sell') {
          return Yup.string().optional();
        }

        return schema;
      }),
  };

  const sellFields = {
    sellIsUsd: Yup.boolean(),
    sellAmountAvailableAsset: Yup.number(),
    sellAmountAvailableUsd: Yup.number(),

    sellCurrencyAmount: Yup.mixed()
      .required('') // no error message here because the button gets disabled if empty
      .when(
        ['transactionType', 'sellIsUsd', 'sellAmountAvailableUsd', 'sellAmountAvailableAsset'],
        ([transactionType, sellIsUsd, sellAmountAvailableUsd, sellAmountAvailableAsset], schema, resolve) => {
          const currencyAmount = resolve.parent.sellCurrencyAmount;

          // validate USD input
          if (transactionType === 'sell' && sellIsUsd) {
            return (
              Yup.number()
                .positive(errors.positiveAmount)
                .min(sellMin, errors.minSell)
                .max(sellMax, errors.maxSell)
                // validate the sell amount in USD is not more than the user's available balance
                .test('balanceCheck', errors.insufficientSellBalance, () => {
                  if (currencyAmount) {
                    return currencyAmount <= Big(sellAmountAvailableUsd).round(2).toNumber();
                  }

                  return true;
                })
            );
          }

          // validate asset input converted to USD
          if (transactionType === 'sell' && !sellIsUsd) {
            const usdAmount = Big(sellPrice)
              .mul(currencyAmount || 0)
              .toNumber();

            return (
              Yup.number()
                .positive(errors.positiveAmount)
                .test('minCheck', errors.minSell, () => {
                  if (currencyAmount) {
                    return usdAmount >= sellMin;
                  }

                  return true;
                })
                .test('maxCheck', errors.maxSell, () => {
                  if (currencyAmount) {
                    return usdAmount <= sellMax;
                  }

                  return true;
                })
                // validate the sell amount in crypto is not more than the user's available balance
                .test('balanceCheck', errors.insufficientSellBalance, () => {
                  if (currencyAmount) {
                    return currencyAmount <= sellAmountAvailableAsset;
                  }

                  return true;
                })
            );
          }

          if (transactionType === 'buy') {
            return Yup.mixed().optional();
          }

          return schema;
        },
      ),

    sellAccountId: Yup.string()
      .required(errors.accountRequired)
      .when('transactionType', ([transactionType], schema) => {
        if (transactionType === 'buy') {
          return Yup.string().optional();
        }

        return schema;
      }),
  };

  return Yup.object().shape({
    transactionType: Yup.string().required().oneOf(['buy', 'sell']),
    ...buyFields,
    ...sellFields,
  });
};

export type TransactionFormValues = Yup.InferType<ReturnType<typeof transactionFormSchema>>;
