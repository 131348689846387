import { Flex, Heading, Hide } from '@chakra-ui/react';

import { onboardingSteps } from '~/constants';

import ProgressBarSection from './ProgressBarSection';

const ProgressBar = ({
  currentStep,
  totalSteps,
  isOnboarded,
  getSectionProgress,
}: {
  currentStep: number;
  totalSteps: number;
  isOnboarded: boolean;
  getSectionProgress: (step: number) => 0 | 10 | 100 | undefined;
}) => {
  return (
    <Flex direction="column" gap="4">
      <Hide above="sm">
        <Heading as="h2" color="brand.500" size="sm">
          {onboardingSteps.find((step) => step.stepNumber === currentStep)?.title}
        </Heading>
      </Hide>

      <Flex gap={4} direction="row" justifyContent="center">
        {onboardingSteps.slice(0, totalSteps).map((stepData) => (
          <ProgressBarSection
            key={stepData.title}
            stepData={stepData}
            currentStep={currentStep}
            isOnboarded={isOnboarded}
            getSectionProgress={getSectionProgress}
          />
        ))}
      </Flex>
    </Flex>
  );
};

export default ProgressBar;
