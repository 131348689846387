'use client';
import { Flex, Heading } from '@chakra-ui/react';
import { CfModal } from '@cryptofi/core-ui';
import { useRouter } from 'next/navigation';

import { TokenEnum } from '~/codegen/types';
import { AssetsSearch } from '~/components';
import { uiRoutes } from '~/routes';

export default function AssetsSearchModal({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) {
  const router = useRouter();
  const handleCryptoAssetClick = (asset: TokenEnum) => {
    router.push(uiRoutes.assetDetailsCrypto(asset));
  };

  const handleSecurityClick = (ticker: string) => {
    router.push(uiRoutes.assetDetailsSecurities(ticker));
  };
  return (
    <CfModal
      bodyProps={{ maxHeight: 'unset' }}
      size="xl"
      headerContent={
        <Heading size="md" textAlign="center">
          Search
        </Heading>
      }
      isOpen={isOpen}
      onClose={onClose}
    >
      <Flex height="60vh">
        <AssetsSearch onClickCryptoAsset={handleCryptoAssetClick} onClickSecurity={handleSecurityClick} />
      </Flex>
    </CfModal>
  );
}
