'use client';

import { Box, Flex, Heading, IconButton, ModalProps, Text } from '@chakra-ui/react';
import { CfModal, IconCaretLeft, uiColors } from '@cryptofi/core-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { UseMutationResult } from '@tanstack/react-query';
import Big from 'big.js';
import { map } from 'lodash';
import { useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { FormProvider, useForm } from 'react-hook-form';

import { InvestAssetsSearch } from '~/components';
import {
  AllAssetIds,
  OpenSearchTransaction,
  TransactionCurrencies,
  TransactionModalViews,
  TransactionTypes,
} from '~/customTypes';
import {
  useGetFiInfo,
  useGetSecurity,
  useGetTokenPrices,
  useGetUser,
  useGetUserBankAccounts,
  usePostBuyCrypto,
  usePostBuySecurity,
  usePostSellCrypto,
  usePostSellSecurity,
} from '~/hooks';
import { isCrypto, logError } from '~/utils';

import { ModalContext } from './ModalContext';
import ReviewTransaction from './ReviewTransaction';
import SelectCryptoAsset from './SelectCryptoAsset';
import StartTransaction from './StartTransaction';
import TransactionResults from './TransactionResults';
import { transactionFormSchema, TransactionFormValues } from './transactionSchema';
import {
  getAvailableAmount,
  getSellQuantity,
  getTransactionQuantity,
  getTransactionState,
  getWatchedCurrencyAmount,
} from './utils';

interface Props extends Omit<ModalProps, 'children'> {
  defaultAssetId?: AllAssetIds;
  resumeOnboarding?: () => void;
}

const InvestModal = ({ defaultAssetId, onClose, isOpen, resumeOnboarding, ...rest }: Props) => {
  const tokenPrices = useGetTokenPrices();
  const [selectedAssetId, setSelectedAssetId] = useState<AllAssetIds | undefined>(defaultAssetId);
  const selectedSecurity = useGetSecurity({
    ticker: selectedAssetId,
    enabled: !isCrypto(selectedAssetId),
  });
  const user = useGetUser();
  const fiInfo = useGetFiInfo();
  const bankAccounts = useGetUserBankAccounts();

  const cryptoBalances = map(user.data?.balance?.tokens, (value, assetId) => ({
    assetId,
    amountAvailable: Number(value.amountAvailable),
  }));

  const securityBalances = map(user.data?.balance?.securities, (value, assetId) => ({
    assetId,
    amountAvailable: Number(value.amountAvailable),
  }));

  useEffect(() => {
    if (!isCrypto(selectedAssetId)) {
      selectedSecurity.refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAssetId]);

  const [buyPrice, setBuyPrice] = useState<number>(0);
  const [sellPrice, setSellPrice] = useState<number>(0);

  // lock in the current price when the modal is opened
  useEffect(
    () => {
      if (isOpen) {
        setBuyPrice(
          isCrypto(selectedAssetId)
            ? Number(tokenPrices?.data?.find((t) => t?.baseToken === selectedAssetId)?.buyPrice) || 0
            : Number(selectedSecurity.data?.currentPrice?.value) || 0,
        );
        setSellPrice(
          isCrypto(selectedAssetId)
            ? Number(tokenPrices?.data?.find((t) => t?.baseToken === selectedAssetId)?.sellPrice) || 0
            : Number(selectedSecurity.data?.currentPrice?.value) || 0,
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isOpen, selectedAssetId, selectedSecurity.data?.currentPrice?.value],
  );

  // refetch bank accounts when the modal is opened
  // temp fix for user trying to complete a transaction after KYC, but before bank accounts are loaded via refetch interval
  // TODO when can this be removed?
  useEffect(
    () => {
      bankAccounts.refetch();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isOpen],
  );

  const buyTransactionLimits = (isCrypto(selectedAssetId)
    ? fiInfo.data?.transactionLimits.crypto?.buy
    : fiInfo.data?.transactionLimits.security?.buy) ?? { min: '6', max: '5000' };
  const [netBuyQuantity, setNetBuyQuantity] = useState(0);

  const sellTransactionLimits = (isCrypto(selectedAssetId)
    ? fiInfo.data?.transactionLimits.crypto?.sell
    : fiInfo.data?.transactionLimits.security?.sell) ?? { min: '6', max: '5000' };

  const formMethods = useForm<TransactionFormValues>({
    resolver: yupResolver(transactionFormSchema({ buyPrice, sellPrice, buyTransactionLimits, sellTransactionLimits })),
    mode: 'onChange',
  });
  const [transactionType, setTransactionType] = useState<TransactionTypes>('buy');
  const [modalView, setModalView] = useState<TransactionModalViews>('startTransaction');

  // buy form
  const [buyCurrency, setBuyCurrency] = useState<TransactionCurrencies>('USD');
  const buyCurrencyAmount = getWatchedCurrencyAmount({
    watch: formMethods.watch,
    currency: buyCurrency,
    name: 'buyCurrencyAmount',
  });
  const buyCrypto = usePostBuyCrypto() as UseMutationResult<
    OpenSearchTransaction | undefined,
    unknown,
    unknown,
    unknown
  >;
  const buySecurity = usePostBuySecurity() as UseMutationResult<
    OpenSearchTransaction | undefined,
    unknown,
    unknown,
    unknown
  >;

  // sell form
  const [sellCurrency, setSellCurrency] = useState<TransactionCurrencies>('USD');
  const sellCurrencyAmount = getWatchedCurrencyAmount({
    watch: formMethods.watch,
    currency: sellCurrency,
    name: 'sellCurrencyAmount',
  });
  const sellCrypto = usePostSellCrypto() as UseMutationResult<
    OpenSearchTransaction | undefined,
    unknown,
    unknown,
    unknown
  >;
  const sellSecurity = usePostSellSecurity() as UseMutationResult<
    OpenSearchTransaction | undefined,
    unknown,
    unknown,
    unknown
  >;

  const { transactionState } = getTransactionState({
    assetId: selectedAssetId!,
    transactionType,
    buyCrypto,
    sellCrypto,
    buySecurity,
    sellSecurity,
  });

  const views = {
    startTransaction: {
      headerText: '',
      headerContent: bankAccounts.isSuccess && <StartTransaction.HeaderContent />,
      bodyContent: <StartTransaction isLoading={bankAccounts.isLoading} />,
      footerContent: bankAccounts.isSuccess && <StartTransaction.FooterContent resumeOnboarding={resumeOnboarding} />,
    },
    searchAssets: {
      headerText: 'Search',
      headerContent: null,
      bodyContent: <InvestAssetsSearch />,
      footerContent: null,
    },
    selectAsset: {
      headerText: 'Select asset',
      headerContent: null,
      bodyContent: <SelectCryptoAsset tokenPrices={tokenPrices?.data || []} />,
      footerContent: null,
    },
    reviewTransaction: {
      headerText: 'Preview order',
      headerContent: <ReviewTransaction.HeaderContent />,
      bodyContent: <ReviewTransaction />,
      footerContent: <ReviewTransaction.FooterContent />,
    },
    transactionResults: {
      headerText: transactionState?.isSuccess ? 'Order placed!' : 'Something went wrong',
      headerContent: null,
      bodyContent: <TransactionResults />,
      footerContent: transactionState?.isError ? <TransactionResults.FooterContent /> : null,
    },
  };

  const headerContent = (
    <>
      {(modalView === 'selectAsset' || modalView === 'reviewTransaction' || modalView === 'searchAssets') && (
        <IconButton
          isDisabled={buyCrypto.isPending || sellCrypto.isPending}
          icon={<IconCaretLeft __css={{ path: { fill: 'black !important' } }} />}
          position="absolute"
          left="3"
          top="2"
          variant="ghost"
          aria-label="Back to order entry"
          size="sm"
          onClick={() => {
            setModalView('startTransaction');
          }}
          _hover={{ bg: 'blackAlpha.100' }}
          _focus={{ bg: 'blackAlpha.100' }}
        />
      )}

      <Heading as="h1" fontSize="xl" textAlign="center" maxWidth="85%" marginX="auto">
        {views[modalView].headerText}
      </Heading>

      {views[modalView].headerContent}
    </>
  );

  const { amountAvailableUsd, amountAvailableAsset } = getAvailableAmount({
    securityBalances,
    cryptoBalances,
    assetId: selectedAssetId!,
    sellPrice,
  });

  const isMaxSell =
    amountAvailableUsd !== 0 &&
    Number(formMethods.getValues('sellCurrencyAmount') || 0) === Big(amountAvailableUsd).round(2).toNumber();

  const sellQuantity = getSellQuantity({
    isMaxSell,
    amountAvailableAsset,
    amountAvailableUsd,
    sellCurrency,
    sellCurrencyAmount,
    sellPrice,
    isAssetCrypto: isCrypto(selectedAssetId!),
  });

  const buyQuantity = getTransactionQuantity({
    currency: buyCurrency,
    currencyAmount: buyCurrencyAmount,
    price: buyPrice,
  });

  if (!selectedAssetId) {
    return null;
  }

  return (
    <ModalContext.Provider
      value={{
        // shared
        setModalView,
        transactionType,
        setTransactionType,
        bankAccounts: bankAccounts.data || [],
        selectedSecurity: selectedSecurity.data,
        selectedAssetId,
        setSelectedAssetId,
        transactionState,
        amountAvailableUsd,
        amountAvailableAsset,

        // buy
        buyCurrency,
        setBuyCurrency,
        buyPrice,
        buyCurrencyAmount,
        buyCrypto,
        buySecurity,
        buyQuantity,
        netBuyQuantity,
        setNetBuyQuantity,

        // sell
        sellCurrency,
        setSellCurrency,
        sellPrice,
        sellCurrencyAmount,
        sellCrypto,
        sellSecurity,
        sellQuantity,
        sellMaxTransactionLimit: Number(sellTransactionLimits.max),
      }}
    >
      <FormProvider {...formMethods}>
        <CfModal
          // returnFocusOnClose prevents scrolling back to the triggering element on close
          // TODO should be removed if we ever unwind blockScrollOnMount used in core UI
          returnFocusOnClose={false}
          closeOnEsc={!transactionState?.isPending}
          closeOnOverlayClick={!transactionState?.isPending}
          onClose={onClose}
          onCloseComplete={() => {
            // reset state
            setTransactionType('buy');
            setModalView('startTransaction');
            setSelectedAssetId(defaultAssetId);
            setBuyCurrency('USD');
            setNetBuyQuantity(0);
            setSellCurrency('USD');
            setBuyPrice(0);
            setSellPrice(0);

            // reset the form
            formMethods.reset();
          }}
          isDisabledCloseButton={transactionState?.isPending}
          isOpen={isOpen}
          headerContent={headerContent}
          footerContent={views[modalView].footerContent}
          {...rest}
        >
          <Box className="error-boundary" width="full">
            <ErrorBoundary
              onError={(error) => {
                logError({ error });
              }}
              fallback={
                isOpen ? (
                  <Flex>
                    <Text fontSize="md" color={uiColors.heroicRed()}>
                      Error: There was an unexpected error. Please try again.
                    </Text>
                  </Flex>
                ) : null
              }
            >
              {views[modalView].bodyContent}
            </ErrorBoundary>
          </Box>
        </CfModal>
      </FormProvider>
    </ModalContext.Provider>
  );
};

export default InvestModal;
