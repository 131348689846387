'use client';
import { Button, useDisclosure } from '@chakra-ui/react';
import { IconSearch } from '@cryptofi/core-ui';

import { AssetsSearchModal } from '~/components';

const AssetsSearchButton = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Button onClick={onOpen} rightIcon={<IconSearch />} variant="secondary">
        Search
      </Button>

      <AssetsSearchModal isOpen={isOpen} onClose={onClose} />
    </>
  );
};

export default AssetsSearchButton;
