import { Flex, Heading, Wrap } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import isDev from '~/utils/isDev';

import Button from './Button';

const Actions = ({
  uiColor,
  enableRefetch,
  setEnableRefetch,
  setShowBreakpointDebugger,
  onDrawerClose,
}: {
  uiColor: string;
  idToken: string | undefined;
  enableRefetch: boolean;
  setEnableRefetch: (value: boolean) => void;
  setShowBreakpointDebugger: (value: boolean) => void;
  onDrawerClose: () => void;
}) => {
  const [outlinesEnabled, setOutlinesEnabled] = useState(false);
  const [errorBoundariesEnabled, setErrorBoundariesEnabled] = useState(false);
  const [isOpenReactQueryConsole, setIsOpenReactQueryConsole] = useState(false);
  const { isLocalDev } = isDev();

  useEffect(() => {
    // check if outlines are displayed
    if (document.body.classList.contains('show-outlines')) {
      setOutlinesEnabled(true);
    }

    // check if error boundaries are displayed
    if (document.body.classList.contains('show-error-boundaries')) {
      setErrorBoundariesEnabled(true);
    }

    // check if React Query console is open
    const queryContainer = document.querySelector('.tsqd-queries-container') as HTMLElement;
    setIsOpenReactQueryConsole(Boolean(queryContainer));
  }, [setEnableRefetch]);

  const toggleReactQueryConsole = () => {
    const queryContainer = document.querySelector('.tsqd-queries-container') as HTMLElement;

    if (queryContainer) {
      const el: HTMLElement = document.querySelector('.tsqd-minimize-btn') as HTMLElement;
      el?.click();
      setIsOpenReactQueryConsole(false);
    } else {
      const el: HTMLElement = document.querySelector('.tsqd-open-btn') as HTMLElement;
      el?.click();
      setIsOpenReactQueryConsole(true);
    }
  };

  const toggleRefetch = () => {
    if (enableRefetch) {
      localStorage.setItem('enableRefetch', 'false');
    } else {
      localStorage.removeItem('enableRefetch');
    }

    setEnableRefetch(!enableRefetch);

    window.location.reload();
  };

  const showBreakpointDebugger = () => {
    onDrawerClose();
    setShowBreakpointDebugger(true);
  };

  const toggleOutlines = () => {
    setOutlinesEnabled(!outlinesEnabled);
    document.body.classList.toggle('show-outlines');
  };

  const toggleErrorBoundaries = () => {
    setErrorBoundariesEnabled(!errorBoundariesEnabled);
    document.body.classList.toggle('show-error-boundaries');
  };

  const buttonProps = {
    width: {
      base: 'full',
      md: 'auto',
    },
    uiColor,
  };

  return (
    <Flex flexDir="column">
      <Heading as="h3" fontSize="md" borderBottom="solid 1px" borderColor={`${uiColor}.700`} mb="2" fontFamily="mono">
        Actions
      </Heading>

      <Wrap>
        <Button onClick={toggleReactQueryConsole} isDisabled={!isLocalDev} {...buttonProps}>
          {isOpenReactQueryConsole ? 'Hide' : 'Show'} React Query console
        </Button>

        <Button
          onClick={toggleRefetch}
          position="relative"
          {...buttonProps}
          _after={
            !enableRefetch
              ? {
                  bg: 'purple.500',
                  borderRadius: 'full',
                  width: '3',
                  height: '3',
                  position: 'absolute',
                  top: '-0.25rem',
                  right: '-0.25rem',
                  zIndex: '1',
                  content: '""',
                }
              : {}
          }
        >
          {enableRefetch ? 'Disable' : 'Enable'} refetch intervals
        </Button>

        <Button onClick={showBreakpointDebugger} {...buttonProps}>
          Show breakpoint debugger
        </Button>

        <Button onClick={toggleOutlines} {...buttonProps}>
          {outlinesEnabled ? 'Hide' : 'Show'} outlines
        </Button>

        <Button onClick={toggleErrorBoundaries} {...buttonProps}>
          {errorBoundariesEnabled ? 'Hide' : 'Show'} error boundaries
        </Button>
      </Wrap>
    </Flex>
  );
};

export default Actions;
