import { camelize, Camelized } from 'humps';
import { every } from 'lodash';

import { TermsAndConditionTypes, User } from '~/codegen/types';
import { Product } from '~/customTypes';

const requiredTermsForFeatureSet: Record<Exclude<Product, 'combined'>, TermsAndConditionTypes[]> = {
  securities: [
    TermsAndConditionTypes.Securities,
    TermsAndConditionTypes.InvestifiBrokerageLicense,
    TermsAndConditionTypes.AdvisifiBrokerageLicense,
  ],
  crypto: [TermsAndConditionTypes.Crypto],
};

/**
 * @description For a given user and feature, ensure that the correct terms fields
 * for that feature have been accepted. The specific terms fields required for a given
 * feature are stored in this util.
 */
export const hasAcceptedTermsForFeature = ({
  user,
  featureSet,
}: {
  user: Camelized<User>;
  featureSet: Exclude<Product, 'combined'>;
}) =>
  every(
    requiredTermsForFeatureSet[featureSet].map((termsType) =>
      Boolean(user?.termsAndConditions?.[camelize(termsType)]?.dateAccepted),
    ),
  );
