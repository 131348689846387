import dayjs from 'dayjs';

const marketHolidaySchedule: { [key: string]: string[] } = {
  '2023': [
    '2023-01-01', // New years day
    '2023-01-16', // MLK day
    '2023-02-20', // President's day
    '2023-05-29', // Memorial day
    '2023-06-19', // Juneteenth
    '2023-07-04', // Independence day
    '2023-09-04', // Labor day
    '2023-11-23', // Thanksgiving day
    '2023-12-25', // Christmas day
  ],
  '2024': [
    '2024-01-01', // New years day
    '2024-01-15', // MLK day
    '2024-02-19', // President's day
    '2024-05-27', // Memorial day
    '2024-06-19', // Juneteenth
    '2024-07-04', // Independence day
    '2024-09-02', // Labor day
    '2024-11-28', // Thanksgiving day
    '2024-12-25', // Christmas day
  ],
  '2025': [
    '2025-01-01', // New years day
    '2025-01-20', // MLK day
    '2025-02-17', // President's day
    '2025-04-18', // Good friday
    '2025-05-26', // Memorial day
    '2025-06-19', // Juneteenth
    '2025-07-04', // Independence day
    '2025-09-01', // Labor day
    '2025-11-27', // Thanksgiving day
    '2025-12-25', // Christmas day
  ],
  '2026': [
    '2026-01-01', // New years day
    '2026-01-19', // MLK day
    '2026-02-16', // President's day
    '2026-04-03', // Good friday
    '2026-05-25', // Memorial day
    '2026-06-19', // Juneteenth
    '2026-07-03', // Independence day
    '2026-09-07', // Labor day
    '2026-11-26', // Thanksgiving day
    '2026-12-25', // Christmas day
  ],
  '2027': [
    '2027-01-01', // New years day
    '2027-01-18', // MLK day
    '2027-02-15', // President's day
    '2027-03-26', // Good friday
    '2027-05-31', // Memorial day
    '2027-06-19', // Juneteenth
    '2027-07-04', // Independence day
    '2027-09-06', // Labor day
    '2027-11-25', // Thanksgiving day
    '2027-12-25', // Christmas day
  ],
};

export function isHoliday(date: dayjs.Dayjs): boolean {
  const year = date.format('YYYY');
  return marketHolidaySchedule[year]?.includes(date.format('YYYY-MM-DD')) || false;
}

export function isWeekend(date: dayjs.Dayjs): boolean {
  return date.day() === 0 || date.day() === 6;
}

export const isHolidayOrWeekend = (date: dayjs.Dayjs) => {
  return isHoliday(date) || isWeekend(date);
};

export const getTimeUntilMarketOpen = () => {
  const marketOpenTime = dayjs().tz('America/New_York').hour(9).minute(30).second(0).millisecond(0);
  const minutesSinceMarketOpen = dayjs().diff(marketOpenTime, 'minutes');
  const hoursSinceMarketOpen = dayjs().diff(marketOpenTime, 'hours');
  const formattedTimeUntilOpen = `${Math.floor(Math.abs(hoursSinceMarketOpen))}h ${Math.abs(minutesSinceMarketOpen % 60)}m`;
  const priceInterval: '1m' | '15m' = minutesSinceMarketOpen <= 60 ? '1m' : '15m';
  return { formattedTimeUntilOpen, minutesSinceMarketOpen, priceInterval };
};

export const getLastStockMarketTradingDay = (): string => {
  let date = dayjs().tz('America/New_York');
  // If it's before 4am, we need to go back one day as the pricing feed doesnt start till 4am est
  if (date.hour() < 4) {
    date = date.subtract(1, 'day');
  }

  while (isHolidayOrWeekend(date)) {
    date = date.subtract(1, 'day');
  }
  return date.format('YYYY-MM-DD');
};
