import { useMutation } from '@tanstack/react-query';
import { Camelized, camelizeKeys, decamelizeKeys } from 'humps';

import { CryptoSellOrderRequestBody } from '~/codegen/types';
import { OpenSearchTransaction } from '~/customTypes';
import { useAxiosContext } from '~/hooks';
import { apiRoutes } from '~/routes';
import { ignoreUpperCaseKeys } from '~/utils';

const usePostSellCrypto = () => {
  const { apiClient } = useAxiosContext();

  return useMutation({
    mutationFn: async (body: Camelized<CryptoSellOrderRequestBody>) => {
      return apiClient!.post(apiRoutes.sellCrypto(), decamelizeKeys(body)).then((r) => {
        const data = camelizeKeys(r.data, {
          process: ignoreUpperCaseKeys,
        }) as OpenSearchTransaction;

        return data;
      });
    },
  });
};

export default usePostSellCrypto;
