import { createIcon } from '@chakra-ui/react';

const IconPiggyBank = createIcon({
  displayName: 'Icon Piggy Bank',
  viewBox: '0 0 500 500',
  path: (
    <>
      <g>
        <path
          fill="#CECECE"
          d="M468.92 287.66c-14.34 38.4-43.63 80-84.86 92.27-62.29 18.6-69.73 68.81-181.3 64.15a225.11 225.11 0 01-48.24-7.08 185.49 185.49 0 01-22.68-7.54c-65.18-26.4-99.18-84.5-102-145.29-3.73-80 52.06-118.07 88.32-140.39S198.12 35 307.83 38.72A218.12 218.12 0 01357.42 46c36.91 10 74.44 32.09 96.25 64.4 1.64 2.42 3.2 4.91 4.69 7.44 29.19 49.47 30.11 117.46 10.56 169.82z"
        ></path>

        <path
          fill="#fff"
          d="M468.92 287.66c-14.34 38.4-43.63 80-84.86 92.27-62.29 18.6-69.73 68.81-181.3 64.15a225.11 225.11 0 01-48.24-7.08 185.49 185.49 0 01-22.68-7.54c-65.18-26.4-99.18-84.5-102-145.29-3.73-80 52.06-118.07 88.32-140.39S198.12 35 307.83 38.72A218.12 218.12 0 01357.42 46c36.91 10 74.44 32.09 96.25 64.4 1.64 2.42 3.2 4.91 4.69 7.44 29.19 49.47 30.11 117.46 10.56 169.82z"
          opacity="0.7"
        ></path>
      </g>

      <g>
        <ellipse
          cx="125.27"
          cy="151.36"
          fill="#fff"
          stroke="#263238"
          strokeMiterlimit="10"
          rx="19.81"
          ry="51.96"
        ></ellipse>

        <path
          fill="#fff"
          stroke="#263238"
          strokeMiterlimit="10"
          d="M186.81 119.32c-6.19 19.49-18.08 33.11-26.57 30.41s-10.34-20.68-4.14-40.17 18.08-33.1 26.57-30.4 10.33 20.67 4.14 40.16zM41.47 236.84c-20.16-3.41-35.3-13.3-33.81-22.07s19-13.12 39.19-9.7 35.31 13.29 33.82 22.07-19.04 13.12-39.2 9.7zM205.46 188.81c-16.84 16.7-36.74 23.94-44.46 16.19s-.3-27.61 16.54-44.31S214.3 136.74 222 144.5s.3 27.61-16.54 44.31zM89.42 143c16.29 23.63 21.14 48.53 10.84 55.63s-31.85-6.3-48.13-29.93S31 120.17 41.29 113.07s31.85 6.3 48.13 29.93z"
        ></path>

        <path
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeMiterlimit="10"
          d="M126.66 200.73L128.19 358.78"
        ></path>

        <path
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeMiterlimit="10"
          d="M124.81 132.05L126.66 200.73"
        ></path>

        <path
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeMiterlimit="10"
          d="M124.94 120.84L124.81 126.1"
        ></path>

        <path
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeMiterlimit="10"
          d="M100.18 195.26a199.38 199.38 0 0119.57 74.95V368M66.16 143.2a235.17 235.17 0 0134 52.06M55.45 131.88s2.06 1.88 5.43 5.47M162.25 202.59c-11.55 16-23.16 36.88-32.11 63.73M185 176.4a188.42 188.42 0 00-22.79 26.19M209.37 157.86s-7.06 3.62-17.35 12.29M161.42 144.9c-11 34.54-25.71 85.57-32.58 129.86M168.1 124.42c-1.95 5.82-4.22 12.74-6.68 20.48M175 104.6s-2 5.29-5 14.4M79.65 222.35c13.71 5.2 32.46 19.7 39 38.7M54.33 217.2a86.82 86.82 0 0125.32 5.15M37.92 217.61s2.44-.35 6.46-.51"
        ></path>

        <path
          fill="#fff"
          stroke="#263238"
          strokeMiterlimit="10"
          d="M81 312.81c-18.53 8.66-36.6 9.13-40.37 1.07s8.21-21.62 26.73-30.27 36.61-9.13 40.37-1.06-8.18 21.61-26.73 30.26z"
        ></path>

        <path
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeMiterlimit="10"
          d="M80.43 289.35a86.31 86.31 0 0123.77-10.16 11.86 11.86 0 0112.75.24c2.5 1.67 1.67 14.2 1.67 14.2v65.15M67.16 299s1.81-1.67 5-4.09"
        ></path>

        <path
          fill="#fff"
          stroke="#263238"
          strokeMiterlimit="10"
          d="M214 236.84c20.17-3.41 35.3-13.3 33.82-22.07s-19-13.12-39.2-9.7-35.3 13.29-33.82 22.07 19.05 13.12 39.2 9.7z"
        ></path>

        <path
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeMiterlimit="10"
          d="M176.26 222.19a65.58 65.58 0 00-7.18 3.16c-16.66 8.58-26.85 26.06-26.85 44.8v86.13M203.28 217.1a88.24 88.24 0 00-27 5.09M217.57 217.61s-1.61-.23-4.37-.4"
        ></path>

        <path
          fill="#fff"
          stroke="#263238"
          strokeMiterlimit="10"
          d="M193.77 295.84c17.72 10.21 35.68 12.24 40.13 4.53s-6.32-22.24-24-32.45-35.69-12.24-40.13-4.53 6.28 22.24 24 32.45z"
        ></path>

        <path
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeMiterlimit="10"
          d="M198.07 273.78A88.3 88.3 0 00174 260.5s-22-3.63-27 10.57M208.76 283.28s-1.09-1.2-3.11-3.09"
        ></path>

        <path
          fill="#fff"
          stroke="#263238"
          strokeMiterlimit="10"
          d="M150.36 271.91s-25.9-5-25.9 12.53 18.38 31.74 25.9 51.79 21.72 20.88 29.24.83-3.34-56.8-29.24-65.15z"
        ></path>

        <path fill="none" stroke="#263238" strokeMiterlimit="10" d="M132.82 272.74s22.55 9.19 29.23 38.43"></path>

        <path
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeMiterlimit="10"
          d="M134.49 311.17L134.49 358.78"
        ></path>

        <path
          fill="#263238"
          d="M176 451.51a203.59 203.59 0 009.36-60.95 183.42 183.42 0 00-3.74-37.27H76.09a182.69 182.69 0 00-3.75 37.27 203.27 203.27 0 009.37 60.95z"
        ></path>

        <path
          fill="#7a7a7a"
          d="M73.38 370.71q-.45 4.31-.71 8.72H185q-.25-4.41-.71-8.72zM183.81 415.24c.35-2.89.66-5.79.89-8.71H73c.23 2.92.54 5.82.89 8.71z"
        ></path>

        <path
          fill="#8c8c8c"
          d="M86 391a4.55 4.55 0 11-4.55-4.39A4.47 4.47 0 0186 391zM108.71 391a4.55 4.55 0 11-4.55-4.39 4.47 4.47 0 014.55 4.39zM131.44 391a4.55 4.55 0 11-4.55-4.39 4.47 4.47 0 014.55 4.39zM154.17 391a4.55 4.55 0 11-4.55-4.39 4.47 4.47 0 014.55 4.39zM176.9 391a4.55 4.55 0 11-4.55-4.39 4.46 4.46 0 014.55 4.39z"
        ></path>
      </g>

      <g stroke="#263238" strokeMiterlimit="10">
        <path fill="#e0e0e0" d="M404.61 442.25H459.42V450.78H404.61z" transform="rotate(180 432.02 446.515)"></path>

        <path fill="#e0e0e0" d="M455.83 442.41L455.83 450.16"></path>

        <path fill="#e0e0e0" d="M451.96 442.41L451.96 450.16"></path>

        <path fill="#e0e0e0" d="M448.08 442.41L448.08 450.16"></path>

        <path fill="#e0e0e0" d="M444.2 442.41L444.2 450.16"></path>

        <path fill="#e0e0e0" d="M440.33 447.5L440.33 450.16"></path>

        <path fill="#e0e0e0" d="M440.33 442.41L440.33 444.35"></path>

        <path fill="#e0e0e0" d="M436.45 442.41L436.45 450.16"></path>

        <path fill="#e0e0e0" d="M432.58 447.74L432.58 450.16"></path>

        <path fill="#e0e0e0" d="M432.58 442.41L432.58 446.52"></path>

        <path fill="#e0e0e0" d="M425.55 442.41L425.55 450.16"></path>

        <path fill="#e0e0e0" d="M417.07 447.74L417.07 450.16"></path>

        <path fill="#e0e0e0" d="M417.07 442.41L417.07 445.07"></path>

        <path fill="#e0e0e0" d="M414.17 442.41L414.17 450.16"></path>

        <path fill="#e0e0e0" d="M409.32 447.01L409.32 450.16"></path>

        <path fill="#e0e0e0" d="M409.32 442.41L409.32 445.07"></path>

        <path fill="#e0e0e0" d="M407.72 433.53H462.13V442.05999999999995H407.72z"></path>

        <path fill="#e0e0e0" d="M411.28 433.69L411.28 441.44"></path>

        <path fill="#e0e0e0" d="M415.13 433.69L415.13 441.44"></path>

        <path fill="#e0e0e0" d="M418.98 433.69L418.98 441.44"></path>

        <path fill="#e0e0e0" d="M422.83 433.69L422.83 441.44"></path>

        <path fill="#e0e0e0" d="M426.68 438.77L426.68 441.44"></path>

        <path fill="#e0e0e0" d="M426.68 433.69L426.68 435.63"></path>

        <path fill="#e0e0e0" d="M430.52 433.69L430.52 441.44"></path>

        <path fill="#e0e0e0" d="M434.37 439.02L434.37 441.44"></path>

        <path fill="#e0e0e0" d="M434.37 433.69L434.37 437.8"></path>

        <path fill="#e0e0e0" d="M441.35 433.69L441.35 441.44"></path>

        <path fill="#e0e0e0" d="M449.76 439.02L449.76 441.44"></path>

        <path fill="#e0e0e0" d="M449.76 433.69L449.76 436.35"></path>

        <path fill="#e0e0e0" d="M452.65 433.69L452.65 441.44"></path>

        <path fill="#e0e0e0" d="M457.46 438.29L457.46 441.44"></path>

        <path fill="#e0e0e0" d="M457.46 433.69L457.46 436.35"></path>

        <path
          fill="#e0e0e0"
          d="M402.19 424.57H457V433.09999999999997H402.19z"
          transform="rotate(180 429.595 428.835)"
        ></path>

        <path fill="#e0e0e0" d="M453.41 424.72L453.41 432.48"></path>

        <path fill="#e0e0e0" d="M449.53 424.72L449.53 432.48"></path>

        <path fill="#e0e0e0" d="M445.66 424.72L445.66 432.48"></path>

        <path fill="#e0e0e0" d="M441.78 424.72L441.78 432.48"></path>

        <path fill="#e0e0e0" d="M437.91 429.81L437.91 432.48"></path>

        <path fill="#e0e0e0" d="M437.91 424.72L437.91 426.66"></path>

        <path fill="#e0e0e0" d="M434.03 424.72L434.03 432.48"></path>

        <path fill="#e0e0e0" d="M430.15 430.05L430.15 432.48"></path>

        <path fill="#e0e0e0" d="M430.15 424.72L430.15 428.83"></path>

        <path fill="#e0e0e0" d="M423.13 424.72L423.13 432.48"></path>

        <path fill="#e0e0e0" d="M414.65 430.05L414.65 432.48"></path>

        <path fill="#e0e0e0" d="M414.65 424.72L414.65 427.39"></path>

        <path fill="#e0e0e0" d="M411.74 424.72L411.74 432.48"></path>

        <path fill="#e0e0e0" d="M406.9 429.33L406.9 432.48"></path>

        <path fill="#e0e0e0" d="M406.9 424.72L406.9 427.39"></path>

        <path fill="#e0e0e0" d="M401.18 415.85H455.59000000000003V424.38H401.18z"></path>

        <path fill="#e0e0e0" d="M404.74 416L404.74 423.76"></path>

        <path fill="#e0e0e0" d="M408.59 416L408.59 423.76"></path>

        <path fill="#e0e0e0" d="M412.44 416L412.44 423.76"></path>

        <path fill="#e0e0e0" d="M416.29 416L416.29 423.76"></path>

        <path fill="#e0e0e0" d="M420.14 421.09L420.14 423.76"></path>

        <path fill="#e0e0e0" d="M420.14 416L420.14 417.94"></path>

        <path fill="#e0e0e0" d="M423.98 416L423.98 423.76"></path>

        <path fill="#e0e0e0" d="M427.83 421.33L427.83 423.76"></path>

        <path fill="#e0e0e0" d="M427.83 416L427.83 420.11"></path>

        <path fill="#e0e0e0" d="M434.81 416L434.81 423.76"></path>

        <path fill="#e0e0e0" d="M443.22 421.33L443.22 423.76"></path>

        <path fill="#e0e0e0" d="M443.22 416L443.22 418.67"></path>

        <path fill="#e0e0e0" d="M446.11 416L446.11 423.76"></path>

        <path fill="#e0e0e0" d="M450.92 420.61L450.92 423.76"></path>

        <path fill="#e0e0e0" d="M450.92 416L450.92 418.67"></path>

        <path
          fill="#e0e0e0"
          d="M402.92 406.88H457.73V415.40999999999997H402.92z"
          transform="rotate(180 430.325 411.15)"
        ></path>

        <path fill="#e0e0e0" d="M454.14 407.04L454.14 414.79"></path>

        <path fill="#e0e0e0" d="M450.26 407.04L450.26 414.79"></path>

        <path fill="#e0e0e0" d="M446.38 407.04L446.38 414.79"></path>

        <path fill="#e0e0e0" d="M442.51 407.04L442.51 414.79"></path>

        <path fill="#e0e0e0" d="M438.63 412.13L438.63 414.79"></path>

        <path fill="#e0e0e0" d="M438.63 407.04L438.63 408.98"></path>

        <path fill="#e0e0e0" d="M434.76 407.04L434.76 414.79"></path>

        <path fill="#e0e0e0" d="M430.88 412.37L430.88 414.79"></path>

        <path fill="#e0e0e0" d="M430.88 407.04L430.88 411.15"></path>

        <path fill="#e0e0e0" d="M423.86 407.04L423.86 414.79"></path>

        <path fill="#e0e0e0" d="M415.38 412.37L415.38 414.79"></path>

        <path fill="#e0e0e0" d="M415.38 407.04L415.38 409.71"></path>

        <path fill="#e0e0e0" d="M412.47 407.04L412.47 414.79"></path>

        <path fill="#e0e0e0" d="M407.63 411.64L407.63 414.79"></path>

        <path fill="#e0e0e0" d="M407.63 407.04L407.63 409.71"></path>

        <path fill="#e0e0e0" d="M407.73 398.16H462.14V406.69H407.73z"></path>

        <path fill="#e0e0e0" d="M411.3 398.32L411.3 406.07"></path>

        <path fill="#e0e0e0" d="M415.15 398.32L415.15 406.07"></path>

        <path fill="#e0e0e0" d="M418.99 398.32L418.99 406.07"></path>

        <path fill="#e0e0e0" d="M422.84 398.32L422.84 406.07"></path>

        <path fill="#e0e0e0" d="M426.69 403.41L426.69 406.07"></path>

        <path fill="#e0e0e0" d="M426.69 398.32L426.69 400.26"></path>

        <path fill="#e0e0e0" d="M430.54 398.32L430.54 406.07"></path>

        <path fill="#e0e0e0" d="M434.39 403.65L434.39 406.07"></path>

        <path fill="#e0e0e0" d="M434.39 398.32L434.39 402.43"></path>

        <path fill="#e0e0e0" d="M441.36 398.32L441.36 406.07"></path>

        <path fill="#e0e0e0" d="M449.78 403.65L449.78 406.07"></path>

        <path fill="#e0e0e0" d="M449.78 398.32L449.78 400.98"></path>

        <path fill="#e0e0e0" d="M452.66 398.32L452.66 406.07"></path>

        <path fill="#e0e0e0" d="M457.47 402.92L457.47 406.07"></path>

        <path fill="#e0e0e0" d="M457.47 398.32L457.47 400.98"></path>

        <path
          fill="#e0e0e0"
          d="M407.52 389.2H462.33V397.72999999999996H407.52z"
          transform="rotate(180 434.925 393.465)"
        ></path>

        <path fill="#e0e0e0" d="M458.74 389.36L458.74 397.11"></path>

        <path fill="#e0e0e0" d="M454.86 389.36L454.86 397.11"></path>

        <path fill="#e0e0e0" d="M450.99 389.36L450.99 397.11"></path>

        <path fill="#e0e0e0" d="M447.11 389.36L447.11 397.11"></path>

        <path fill="#e0e0e0" d="M443.24 394.44L443.24 397.11"></path>

        <path fill="#e0e0e0" d="M443.24 389.36L443.24 391.29"></path>

        <path fill="#e0e0e0" d="M439.36 389.36L439.36 397.11"></path>

        <path fill="#e0e0e0" d="M435.48 394.69L435.48 397.11"></path>

        <path fill="#e0e0e0" d="M435.48 389.36L435.48 393.46"></path>

        <path fill="#e0e0e0" d="M428.46 389.36L428.46 397.11"></path>

        <path fill="#e0e0e0" d="M419.98 394.69L419.98 397.11"></path>

        <path fill="#e0e0e0" d="M419.98 389.36L419.98 392.02"></path>

        <path fill="#e0e0e0" d="M417.07 389.36L417.07 397.11"></path>

        <path fill="#e0e0e0" d="M412.23 393.96L412.23 397.11"></path>

        <path fill="#e0e0e0" d="M412.23 389.36L412.23 392.02"></path>

        <path fill="#e0e0e0" d="M404.81 380.48H459.22V389.01H404.81z"></path>

        <path fill="#e0e0e0" d="M408.38 380.64L408.38 388.39"></path>

        <path fill="#e0e0e0" d="M412.23 380.64L412.23 388.39"></path>

        <path fill="#e0e0e0" d="M416.07 380.64L416.07 388.39"></path>

        <path fill="#e0e0e0" d="M419.92 380.64L419.92 388.39"></path>

        <path fill="#e0e0e0" d="M423.77 385.72L423.77 388.39"></path>

        <path fill="#e0e0e0" d="M423.77 380.64L423.77 382.57"></path>

        <path fill="#e0e0e0" d="M427.62 380.64L427.62 388.39"></path>

        <path fill="#e0e0e0" d="M431.46 385.97L431.46 388.39"></path>

        <path fill="#e0e0e0" d="M431.46 380.64L431.46 384.74"></path>

        <path fill="#e0e0e0" d="M438.44 380.64L438.44 388.39"></path>

        <path fill="#e0e0e0" d="M446.86 385.97L446.86 388.39"></path>

        <path fill="#e0e0e0" d="M446.86 380.64L446.86 383.3"></path>

        <path fill="#e0e0e0" d="M449.74 380.64L449.74 388.39"></path>

        <path fill="#e0e0e0" d="M454.55 385.24L454.55 388.39"></path>

        <path fill="#e0e0e0" d="M454.55 380.64L454.55 383.3"></path>

        <path
          fill="#e0e0e0"
          d="M404.61 371.51H459.42V380.03999999999996H404.61z"
          transform="rotate(180 432.02 375.78)"
        ></path>

        <path fill="#e0e0e0" d="M455.83 371.67L455.83 379.42"></path>

        <path fill="#e0e0e0" d="M451.96 371.67L451.96 379.42"></path>

        <path fill="#e0e0e0" d="M448.08 371.67L448.08 379.42"></path>

        <path fill="#e0e0e0" d="M444.2 371.67L444.2 379.42"></path>

        <path fill="#e0e0e0" d="M440.33 376.76L440.33 379.42"></path>

        <path fill="#e0e0e0" d="M440.33 371.67L440.33 373.61"></path>

        <path fill="#e0e0e0" d="M436.45 371.67L436.45 379.42"></path>

        <path fill="#e0e0e0" d="M432.58 377L432.58 379.42"></path>

        <path fill="#e0e0e0" d="M432.58 371.67L432.58 375.78"></path>

        <path fill="#e0e0e0" d="M425.55 371.67L425.55 379.42"></path>

        <path fill="#e0e0e0" d="M417.07 377L417.07 379.42"></path>

        <path fill="#e0e0e0" d="M417.07 371.67L417.07 374.34"></path>

        <path fill="#e0e0e0" d="M414.17 371.67L414.17 379.42"></path>

        <path fill="#e0e0e0" d="M409.32 376.28L409.32 379.42"></path>

        <path fill="#e0e0e0" d="M409.32 371.67L409.32 374.34"></path>

        <path fill="#e0e0e0" d="M401.18 362.79H455.59000000000003V371.32H401.18z"></path>

        <path fill="#e0e0e0" d="M404.74 362.95L404.74 370.7"></path>

        <path fill="#e0e0e0" d="M408.59 362.95L408.59 370.7"></path>

        <path fill="#e0e0e0" d="M412.44 362.95L412.44 370.7"></path>

        <path fill="#e0e0e0" d="M416.29 362.95L416.29 370.7"></path>

        <path fill="#e0e0e0" d="M420.14 368.04L420.14 370.7"></path>

        <path fill="#e0e0e0" d="M420.14 362.95L420.14 364.89"></path>

        <path fill="#e0e0e0" d="M423.98 362.95L423.98 370.7"></path>

        <path fill="#e0e0e0" d="M427.83 368.28L427.83 370.7"></path>

        <path fill="#e0e0e0" d="M427.83 362.95L427.83 367.06"></path>

        <path fill="#e0e0e0" d="M434.81 362.95L434.81 370.7"></path>

        <path fill="#e0e0e0" d="M443.22 368.28L443.22 370.7"></path>

        <path fill="#e0e0e0" d="M443.22 362.95L443.22 365.62"></path>

        <path fill="#e0e0e0" d="M446.11 362.95L446.11 370.7"></path>

        <path fill="#e0e0e0" d="M450.92 367.55L450.92 370.7"></path>

        <path fill="#e0e0e0" d="M450.92 362.95L450.92 365.62"></path>

        <path fill="#e0e0e0" d="M404.61 353.74H459.42V362.27H404.61z" transform="rotate(180 432.02 358.01)"></path>

        <path fill="#e0e0e0" d="M455.83 353.9L455.83 361.65"></path>

        <path fill="#e0e0e0" d="M451.96 353.9L451.96 361.65"></path>

        <path fill="#e0e0e0" d="M448.08 353.9L448.08 361.65"></path>

        <path fill="#e0e0e0" d="M444.2 353.9L444.2 361.65"></path>

        <path fill="#e0e0e0" d="M440.33 358.99L440.33 361.65"></path>

        <path fill="#e0e0e0" d="M440.33 353.9L440.33 355.84"></path>

        <path fill="#e0e0e0" d="M436.45 353.9L436.45 361.65"></path>

        <path fill="#e0e0e0" d="M432.58 359.23L432.58 361.65"></path>

        <path fill="#e0e0e0" d="M432.58 353.9L432.58 358.01"></path>

        <path fill="#e0e0e0" d="M425.55 353.9L425.55 361.65"></path>

        <path fill="#e0e0e0" d="M417.07 359.23L417.07 361.65"></path>

        <path fill="#e0e0e0" d="M417.07 353.9L417.07 356.57"></path>

        <path fill="#e0e0e0" d="M414.17 353.9L414.17 361.65"></path>

        <path fill="#e0e0e0" d="M409.32 358.5L409.32 361.65"></path>

        <path fill="#e0e0e0" d="M409.32 353.9L409.32 356.57"></path>

        <path fill="#e0e0e0" d="M407.72 345.02H462.13V353.54999999999995H407.72z"></path>

        <path fill="#e0e0e0" d="M411.28 345.18L411.28 352.93"></path>

        <path fill="#e0e0e0" d="M415.13 345.18L415.13 352.93"></path>

        <path fill="#e0e0e0" d="M418.98 345.18L418.98 352.93"></path>

        <path fill="#e0e0e0" d="M422.83 345.18L422.83 352.93"></path>

        <path fill="#e0e0e0" d="M426.68 350.27L426.68 352.93"></path>

        <path fill="#e0e0e0" d="M426.68 345.18L426.68 347.12"></path>

        <path fill="#e0e0e0" d="M430.52 345.18L430.52 352.93"></path>

        <path fill="#e0e0e0" d="M434.37 350.51L434.37 352.93"></path>

        <path fill="#e0e0e0" d="M434.37 345.18L434.37 349.29"></path>

        <path fill="#e0e0e0" d="M441.35 345.18L441.35 352.93"></path>

        <path fill="#e0e0e0" d="M449.76 350.51L449.76 352.93"></path>

        <path fill="#e0e0e0" d="M449.76 345.18L449.76 347.84"></path>

        <path fill="#e0e0e0" d="M452.65 345.18L452.65 352.93"></path>

        <path fill="#e0e0e0" d="M457.46 349.78L457.46 352.93"></path>

        <path fill="#e0e0e0" d="M457.46 345.18L457.46 347.84"></path>

        <path fill="#fff" d="M368.68 442.25H423.49V450.78H368.68z"></path>

        <path fill="none" d="M372.27 442.41L372.27 450.16"></path>

        <path fill="none" d="M376.15 442.41L376.15 450.16"></path>

        <path fill="none" d="M380.02 442.41L380.02 450.16"></path>

        <path fill="none" d="M383.9 442.41L383.9 450.16"></path>

        <path fill="none" d="M387.78 447.5L387.78 450.16"></path>

        <path fill="none" d="M387.78 442.41L387.78 444.35"></path>

        <path fill="none" d="M391.65 442.41L391.65 450.16"></path>

        <path fill="none" d="M395.53 447.74L395.53 450.16"></path>

        <path fill="none" d="M395.53 442.41L395.53 446.52"></path>

        <path fill="none" d="M402.55 442.41L402.55 450.16"></path>

        <path fill="none" d="M411.03 447.74L411.03 450.16"></path>

        <path fill="none" d="M411.03 442.41L411.03 445.07"></path>

        <path fill="none" d="M413.94 442.41L413.94 450.16"></path>

        <path fill="none" d="M418.78 447.01L418.78 450.16"></path>

        <path fill="none" d="M418.78 442.41L418.78 445.07"></path>

        <path
          fill="#fff"
          d="M365.97 433.53H420.38V442.05999999999995H365.97z"
          transform="rotate(180 393.18 437.795)"
        ></path>

        <path fill="none" d="M416.82 433.69L416.82 441.44"></path>

        <path fill="none" d="M412.97 433.69L412.97 441.44"></path>

        <path fill="none" d="M409.12 433.69L409.12 441.44"></path>

        <path fill="none" d="M405.28 433.69L405.28 441.44"></path>

        <path fill="none" d="M401.43 438.77L401.43 441.44"></path>

        <path fill="none" d="M401.43 433.69L401.43 435.63"></path>

        <path fill="none" d="M397.58 433.69L397.58 441.44"></path>

        <path fill="none" d="M393.73 439.02L393.73 441.44"></path>

        <path fill="none" d="M393.73 433.69L393.73 437.8"></path>

        <path fill="none" d="M386.76 433.69L386.76 441.44"></path>

        <path fill="none" d="M378.34 439.02L378.34 441.44"></path>

        <path fill="none" d="M378.34 433.69L378.34 436.35"></path>

        <path fill="none" d="M375.46 433.69L375.46 441.44"></path>

        <path fill="none" d="M370.65 438.29L370.65 441.44"></path>

        <path fill="none" d="M370.65 433.69L370.65 436.35"></path>

        <path fill="#fff" d="M371.1 424.57H425.91V433.09999999999997H371.1z"></path>

        <path fill="none" d="M374.69 424.72L374.69 432.48"></path>

        <path fill="none" d="M378.57 424.72L378.57 432.48"></path>

        <path fill="none" d="M382.45 424.72L382.45 432.48"></path>

        <path fill="none" d="M386.32 424.72L386.32 432.48"></path>

        <path fill="none" d="M390.2 429.81L390.2 432.48"></path>

        <path fill="none" d="M390.2 424.72L390.2 426.66"></path>

        <path fill="none" d="M394.07 424.72L394.07 432.48"></path>

        <path fill="none" d="M397.95 430.05L397.95 432.48"></path>

        <path fill="none" d="M397.95 424.72L397.95 428.83"></path>

        <path fill="none" d="M404.98 424.72L404.98 432.48"></path>

        <path fill="none" d="M413.45 430.05L413.45 432.48"></path>

        <path fill="none" d="M413.45 424.72L413.45 427.39"></path>

        <path fill="none" d="M416.36 424.72L416.36 432.48"></path>

        <path fill="none" d="M421.21 429.33L421.21 432.48"></path>

        <path fill="none" d="M421.21 424.72L421.21 427.39"></path>

        <path
          fill="#fff"
          d="M372.51 415.85H426.91999999999996V424.38H372.51z"
          transform="rotate(180 399.72 420.11)"
        ></path>

        <path fill="none" d="M423.36 416L423.36 423.76"></path>

        <path fill="none" d="M419.51 416L419.51 423.76"></path>

        <path fill="none" d="M415.66 416L415.66 423.76"></path>

        <path fill="none" d="M411.82 416L411.82 423.76"></path>

        <path fill="none" d="M407.97 421.09L407.97 423.76"></path>

        <path fill="none" d="M407.97 416L407.97 417.94"></path>

        <path fill="none" d="M404.12 416L404.12 423.76"></path>

        <path fill="none" d="M400.27 421.33L400.27 423.76"></path>

        <path fill="none" d="M400.27 416L400.27 420.11"></path>

        <path fill="none" d="M393.3 416L393.3 423.76"></path>

        <path fill="none" d="M384.88 421.33L384.88 423.76"></path>

        <path fill="none" d="M384.88 416L384.88 418.67"></path>

        <path fill="none" d="M382 416L382 423.76"></path>

        <path fill="none" d="M377.19 420.61L377.19 423.76"></path>

        <path fill="none" d="M377.19 416L377.19 418.67"></path>

        <path fill="#fff" d="M370.38 406.88H425.19V415.40999999999997H370.38z"></path>

        <path fill="none" d="M373.97 407.04L373.97 414.79"></path>

        <path fill="none" d="M377.84 407.04L377.84 414.79"></path>

        <path fill="none" d="M381.72 407.04L381.72 414.79"></path>

        <path fill="none" d="M385.6 407.04L385.6 414.79"></path>

        <path fill="none" d="M389.47 412.13L389.47 414.79"></path>

        <path fill="none" d="M389.47 407.04L389.47 408.98"></path>

        <path fill="none" d="M393.35 407.04L393.35 414.79"></path>

        <path fill="none" d="M397.22 412.37L397.22 414.79"></path>

        <path fill="none" d="M397.22 407.04L397.22 411.15"></path>

        <path fill="none" d="M404.25 407.04L404.25 414.79"></path>

        <path fill="none" d="M412.73 412.37L412.73 414.79"></path>

        <path fill="none" d="M412.73 407.04L412.73 409.71"></path>

        <path fill="none" d="M415.63 407.04L415.63 414.79"></path>

        <path fill="none" d="M420.48 411.64L420.48 414.79"></path>

        <path fill="none" d="M420.48 407.04L420.48 409.71"></path>

        <path fill="#fff" d="M365.96 398.16H420.37V406.69H365.96z" transform="rotate(180 393.165 402.43)"></path>

        <path fill="none" d="M416.81 398.32L416.81 406.07"></path>

        <path fill="none" d="M412.96 398.32L412.96 406.07"></path>

        <path fill="none" d="M409.11 398.32L409.11 406.07"></path>

        <path fill="none" d="M405.26 398.32L405.26 406.07"></path>

        <path fill="none" d="M401.41 403.41L401.41 406.07"></path>

        <path fill="none" d="M401.41 398.32L401.41 400.26"></path>

        <path fill="none" d="M397.57 398.32L397.57 406.07"></path>

        <path fill="none" d="M393.72 403.65L393.72 406.07"></path>

        <path fill="none" d="M393.72 398.32L393.72 402.43"></path>

        <path fill="none" d="M386.75 398.32L386.75 406.07"></path>

        <path fill="none" d="M378.33 403.65L378.33 406.07"></path>

        <path fill="none" d="M378.33 398.32L378.33 400.98"></path>

        <path fill="none" d="M375.44 398.32L375.44 406.07"></path>

        <path fill="none" d="M370.63 402.92L370.63 406.07"></path>

        <path fill="none" d="M370.63 398.32L370.63 400.98"></path>

        <path fill="#fff" d="M365.77 389.2H420.58V397.72999999999996H365.77z"></path>

        <path fill="none" d="M369.36 389.36L369.36 397.11"></path>

        <path fill="none" d="M373.24 389.36L373.24 397.11"></path>

        <path fill="none" d="M377.12 389.36L377.12 397.11"></path>

        <path fill="none" d="M380.99 389.36L380.99 397.11"></path>

        <path fill="none" d="M384.87 394.44L384.87 397.11"></path>

        <path fill="none" d="M384.87 389.36L384.87 391.29"></path>

        <path fill="none" d="M388.74 389.36L388.74 397.11"></path>

        <path fill="none" d="M392.62 394.69L392.62 397.11"></path>

        <path fill="none" d="M392.62 389.36L392.62 393.46"></path>

        <path fill="none" d="M399.65 389.36L399.65 397.11"></path>

        <path fill="none" d="M408.12 394.69L408.12 397.11"></path>

        <path fill="none" d="M408.12 389.36L408.12 392.02"></path>

        <path fill="none" d="M411.03 389.36L411.03 397.11"></path>

        <path fill="none" d="M415.88 393.96L415.88 397.11"></path>

        <path fill="none" d="M415.88 389.36L415.88 392.02"></path>

        <path
          fill="#fff"
          d="M368.88 380.48H423.28999999999996V389.01H368.88z"
          transform="rotate(180 396.085 384.745)"
        ></path>

        <path fill="none" d="M419.73 380.64L419.73 388.39"></path>

        <path fill="none" d="M415.88 380.64L415.88 388.39"></path>

        <path fill="none" d="M412.03 380.64L412.03 388.39"></path>

        <path fill="none" d="M408.18 380.64L408.18 388.39"></path>

        <path fill="none" d="M404.34 385.72L404.34 388.39"></path>

        <path fill="none" d="M404.34 380.64L404.34 382.57"></path>

        <path fill="none" d="M400.49 380.64L400.49 388.39"></path>

        <path fill="none" d="M396.64 385.97L396.64 388.39"></path>

        <path fill="none" d="M396.64 380.64L396.64 384.74"></path>

        <path fill="none" d="M389.67 380.64L389.67 388.39"></path>

        <path fill="none" d="M381.25 385.97L381.25 388.39"></path>

        <path fill="none" d="M381.25 380.64L381.25 383.3"></path>

        <path fill="none" d="M378.36 380.64L378.36 388.39"></path>

        <path fill="none" d="M373.55 385.24L373.55 388.39"></path>

        <path fill="none" d="M373.55 380.64L373.55 383.3"></path>

        <path fill="#fff" d="M368.68 371.51H423.49V380.03999999999996H368.68z"></path>

        <path fill="none" d="M372.27 371.67L372.27 379.42"></path>

        <path fill="none" d="M376.15 371.67L376.15 379.42"></path>

        <path fill="none" d="M380.02 371.67L380.02 379.42"></path>

        <path fill="none" d="M383.9 371.67L383.9 379.42"></path>

        <path fill="none" d="M387.78 376.76L387.78 379.42"></path>

        <path fill="none" d="M387.78 371.67L387.78 373.61"></path>

        <path fill="none" d="M391.65 371.67L391.65 379.42"></path>

        <path fill="none" d="M395.53 377L395.53 379.42"></path>

        <path fill="none" d="M395.53 371.67L395.53 375.78"></path>

        <path fill="none" d="M402.55 371.67L402.55 379.42"></path>

        <path fill="none" d="M411.03 377L411.03 379.42"></path>

        <path fill="none" d="M411.03 371.67L411.03 374.34"></path>

        <path fill="none" d="M413.94 371.67L413.94 379.42"></path>

        <path fill="none" d="M418.78 376.28L418.78 379.42"></path>

        <path fill="none" d="M418.78 371.67L418.78 374.34"></path>

        <path
          fill="#fff"
          d="M372.51 362.79H426.91999999999996V371.32H372.51z"
          transform="rotate(180 399.72 367.06)"
        ></path>

        <path fill="none" d="M423.36 362.95L423.36 370.7"></path>

        <path fill="none" d="M419.51 362.95L419.51 370.7"></path>

        <path fill="none" d="M415.66 362.95L415.66 370.7"></path>

        <path fill="none" d="M411.82 362.95L411.82 370.7"></path>

        <path fill="none" d="M407.97 368.04L407.97 370.7"></path>

        <path fill="none" d="M407.97 362.95L407.97 364.89"></path>

        <path fill="none" d="M404.12 362.95L404.12 370.7"></path>

        <path fill="none" d="M400.27 368.28L400.27 370.7"></path>

        <path fill="none" d="M400.27 362.95L400.27 367.06"></path>

        <path fill="none" d="M393.3 362.95L393.3 370.7"></path>

        <path fill="none" d="M384.88 368.28L384.88 370.7"></path>

        <path fill="none" d="M384.88 362.95L384.88 365.62"></path>

        <path fill="none" d="M382 362.95L382 370.7"></path>

        <path fill="none" d="M377.19 367.55L377.19 370.7"></path>

        <path fill="none" d="M377.19 362.95L377.19 365.62"></path>

        <path fill="#fff" d="M368.68 353.74H423.49V362.27H368.68z"></path>

        <path fill="none" d="M372.27 353.9L372.27 361.65"></path>

        <path fill="none" d="M376.15 353.9L376.15 361.65"></path>

        <path fill="none" d="M380.02 353.9L380.02 361.65"></path>

        <path fill="none" d="M383.9 353.9L383.9 361.65"></path>

        <path fill="none" d="M387.78 358.99L387.78 361.65"></path>

        <path fill="none" d="M387.78 353.9L387.78 355.84"></path>

        <path fill="none" d="M391.65 353.9L391.65 361.65"></path>

        <path fill="none" d="M395.53 359.23L395.53 361.65"></path>

        <path fill="none" d="M395.53 353.9L395.53 358.01"></path>

        <path fill="none" d="M402.55 353.9L402.55 361.65"></path>

        <path fill="none" d="M411.03 359.23L411.03 361.65"></path>

        <path fill="none" d="M411.03 353.9L411.03 356.57"></path>

        <path fill="none" d="M413.94 353.9L413.94 361.65"></path>

        <path fill="none" d="M418.78 358.5L418.78 361.65"></path>

        <path fill="none" d="M418.78 353.9L418.78 356.57"></path>

        <path
          fill="#fff"
          d="M365.97 345.02H420.38V353.54999999999995H365.97z"
          transform="rotate(180 393.18 349.29)"
        ></path>

        <path fill="none" d="M416.82 345.18L416.82 352.93"></path>

        <path fill="none" d="M412.97 345.18L412.97 352.93"></path>

        <path fill="none" d="M409.12 345.18L409.12 352.93"></path>

        <path fill="none" d="M405.28 345.18L405.28 352.93"></path>

        <path fill="none" d="M401.43 350.27L401.43 352.93"></path>

        <path fill="none" d="M401.43 345.18L401.43 347.12"></path>

        <path fill="none" d="M397.58 345.18L397.58 352.93"></path>

        <path fill="none" d="M393.73 350.51L393.73 352.93"></path>

        <path fill="none" d="M393.73 345.18L393.73 349.29"></path>

        <path fill="none" d="M386.76 345.18L386.76 352.93"></path>

        <path fill="none" d="M378.34 350.51L378.34 352.93"></path>

        <path fill="none" d="M378.34 345.18L378.34 347.84"></path>

        <path fill="none" d="M375.46 345.18L375.46 352.93"></path>

        <path fill="none" d="M370.65 349.78L370.65 352.93"></path>

        <path fill="none" d="M370.65 345.18L370.65 347.84"></path>

        <path fill="#fff" d="M371.1 336.06H425.91V344.59H371.1z"></path>

        <path fill="none" d="M374.69 336.22L374.69 343.97"></path>

        <path fill="none" d="M378.57 336.22L378.57 343.97"></path>

        <path fill="none" d="M382.45 336.22L382.45 343.97"></path>

        <path fill="none" d="M386.32 336.22L386.32 343.97"></path>

        <path fill="none" d="M390.2 341.3L390.2 343.97"></path>

        <path fill="none" d="M390.2 336.22L390.2 338.15"></path>

        <path fill="none" d="M394.07 336.22L394.07 343.97"></path>

        <path fill="none" d="M397.95 341.55L397.95 343.97"></path>

        <path fill="none" d="M397.95 336.22L397.95 340.32"></path>

        <path fill="none" d="M404.98 336.22L404.98 343.97"></path>

        <path fill="none" d="M413.45 341.55L413.45 343.97"></path>

        <path fill="none" d="M413.45 336.22L413.45 338.88"></path>

        <path fill="none" d="M416.36 336.22L416.36 343.97"></path>

        <path fill="none" d="M421.21 340.82L421.21 343.97"></path>

        <path fill="none" d="M421.21 336.22L421.21 338.88"></path>

        <path
          fill="#fff"
          d="M372.51 327.34H426.91999999999996V335.86999999999995H372.51z"
          transform="rotate(180 399.72 331.605)"
        ></path>

        <path fill="none" d="M423.36 327.5L423.36 335.25"></path>

        <path fill="none" d="M419.51 327.5L419.51 335.25"></path>

        <path fill="none" d="M415.66 327.5L415.66 335.25"></path>

        <path fill="none" d="M411.82 327.5L411.82 335.25"></path>

        <path fill="none" d="M407.97 332.58L407.97 335.25"></path>

        <path fill="none" d="M407.97 327.5L407.97 329.43"></path>

        <path fill="none" d="M404.12 327.5L404.12 335.25"></path>

        <path fill="none" d="M400.27 332.82L400.27 335.25"></path>

        <path fill="none" d="M400.27 327.5L400.27 331.6"></path>

        <path fill="none" d="M393.3 327.5L393.3 335.25"></path>

        <path fill="none" d="M384.88 332.82L384.88 335.25"></path>

        <path fill="none" d="M384.88 327.5L384.88 330.16"></path>

        <path fill="none" d="M382 327.5L382 335.25"></path>

        <path fill="none" d="M377.19 332.1L377.19 335.25"></path>

        <path fill="none" d="M377.19 327.5L377.19 330.16"></path>

        <path fill="#fff" d="M370.38 318.37H425.19V326.9H370.38z"></path>

        <path fill="none" d="M373.97 318.53L373.97 326.28"></path>

        <path fill="none" d="M377.84 318.53L377.84 326.28"></path>

        <path fill="none" d="M381.72 318.53L381.72 326.28"></path>

        <path fill="none" d="M385.6 318.53L385.6 326.28"></path>

        <path fill="none" d="M389.47 323.62L389.47 326.28"></path>

        <path fill="none" d="M389.47 318.53L389.47 320.47"></path>

        <path fill="none" d="M393.35 318.53L393.35 326.28"></path>

        <path fill="none" d="M397.22 323.86L397.22 326.28"></path>

        <path fill="none" d="M397.22 318.53L397.22 322.64"></path>

        <path fill="none" d="M404.25 318.53L404.25 326.28"></path>

        <path fill="none" d="M412.73 323.86L412.73 326.28"></path>

        <path fill="none" d="M412.73 318.53L412.73 321.2"></path>

        <path fill="none" d="M415.63 318.53L415.63 326.28"></path>

        <path fill="none" d="M420.48 323.14L420.48 326.28"></path>

        <path fill="none" d="M420.48 318.53L420.48 321.2"></path>

        <path
          fill="#fff"
          d="M365.96 309.65H420.37V318.17999999999995H365.96z"
          transform="rotate(180 393.165 313.92)"
        ></path>

        <path fill="none" d="M416.81 309.81L416.81 317.56"></path>

        <path fill="none" d="M412.96 309.81L412.96 317.56"></path>

        <path fill="none" d="M409.11 309.81L409.11 317.56"></path>

        <path fill="none" d="M405.26 309.81L405.26 317.56"></path>

        <path fill="none" d="M401.41 314.9L401.41 317.56"></path>

        <path fill="none" d="M401.41 309.81L401.41 311.75"></path>

        <path fill="none" d="M397.57 309.81L397.57 317.56"></path>

        <path fill="none" d="M393.72 315.14L393.72 317.56"></path>

        <path fill="none" d="M393.72 309.81L393.72 313.92"></path>

        <path fill="none" d="M386.75 309.81L386.75 317.56"></path>

        <path fill="none" d="M378.33 315.14L378.33 317.56"></path>

        <path fill="none" d="M378.33 309.81L378.33 312.48"></path>

        <path fill="none" d="M375.44 309.81L375.44 317.56"></path>

        <path fill="none" d="M370.63 314.41L370.63 317.56"></path>

        <path fill="none" d="M370.63 309.81L370.63 312.48"></path>

        <path fill="#fff" d="M365.77 300.69H420.58V309.21999999999997H365.77z"></path>

        <path fill="none" d="M369.36 300.85L369.36 308.6"></path>

        <path fill="none" d="M373.24 300.85L373.24 308.6"></path>

        <path fill="none" d="M377.12 300.85L377.12 308.6"></path>

        <path fill="none" d="M380.99 300.85L380.99 308.6"></path>

        <path fill="none" d="M384.87 305.94L384.87 308.6"></path>

        <path fill="none" d="M384.87 300.85L384.87 302.79"></path>

        <path fill="none" d="M388.74 300.85L388.74 308.6"></path>

        <path fill="none" d="M392.62 306.18L392.62 308.6"></path>

        <path fill="none" d="M392.62 300.85L392.62 304.96"></path>

        <path fill="none" d="M399.65 300.85L399.65 308.6"></path>

        <path fill="none" d="M408.12 306.18L408.12 308.6"></path>

        <path fill="none" d="M408.12 300.85L408.12 303.51"></path>

        <path fill="none" d="M411.03 300.85L411.03 308.6"></path>

        <path fill="none" d="M415.88 305.45L415.88 308.6"></path>

        <path fill="none" d="M415.88 300.85L415.88 303.51"></path>

        <path
          fill="#fff"
          d="M368.88 291.97H423.28999999999996V300.5H368.88z"
          transform="rotate(180 396.085 296.235)"
        ></path>

        <path fill="none" d="M419.73 292.13L419.73 299.88"></path>

        <path fill="none" d="M415.88 292.13L415.88 299.88"></path>

        <path fill="none" d="M412.03 292.13L412.03 299.88"></path>

        <path fill="none" d="M408.18 292.13L408.18 299.88"></path>

        <path fill="none" d="M404.34 297.21L404.34 299.88"></path>

        <path fill="none" d="M404.34 292.13L404.34 294.07"></path>

        <path fill="none" d="M400.49 292.13L400.49 299.88"></path>

        <path fill="none" d="M396.64 297.46L396.64 299.88"></path>

        <path fill="none" d="M396.64 292.13L396.64 296.24"></path>

        <path fill="none" d="M389.67 292.13L389.67 299.88"></path>

        <path fill="none" d="M381.25 297.46L381.25 299.88"></path>

        <path fill="none" d="M381.25 292.13L381.25 294.79"></path>

        <path fill="none" d="M378.36 292.13L378.36 299.88"></path>

        <path fill="none" d="M373.55 296.73L373.55 299.88"></path>

        <path fill="none" d="M373.55 292.13L373.55 294.79"></path>

        <path fill="#fff" d="M368.68 283.01H423.49V291.53999999999996H368.68z"></path>

        <path fill="none" d="M372.27 283.16L372.27 290.92"></path>

        <path fill="none" d="M376.15 283.16L376.15 290.92"></path>

        <path fill="none" d="M380.02 283.16L380.02 290.92"></path>

        <path fill="none" d="M383.9 283.16L383.9 290.92"></path>

        <path fill="none" d="M387.78 288.25L387.78 290.92"></path>

        <path fill="none" d="M387.78 283.16L387.78 285.1"></path>

        <path fill="none" d="M391.65 283.16L391.65 290.92"></path>

        <path fill="none" d="M395.53 288.49L395.53 290.92"></path>

        <path fill="none" d="M395.53 283.16L395.53 287.27"></path>

        <path fill="none" d="M402.55 283.16L402.55 290.92"></path>

        <path fill="none" d="M411.03 288.49L411.03 290.92"></path>

        <path fill="none" d="M411.03 283.16L411.03 285.83"></path>

        <path fill="none" d="M413.94 283.16L413.94 290.92"></path>

        <path fill="none" d="M418.78 287.77L418.78 290.92"></path>

        <path fill="none" d="M418.78 283.16L418.78 285.83"></path>

        <path
          fill="#fff"
          d="M372.51 274.29H426.91999999999996V282.82H372.51z"
          transform="rotate(180 399.72 278.55)"
        ></path>

        <path fill="none" d="M423.36 274.44L423.36 282.2"></path>

        <path fill="none" d="M419.51 274.44L419.51 282.2"></path>

        <path fill="none" d="M415.66 274.44L415.66 282.2"></path>

        <path fill="none" d="M411.82 274.44L411.82 282.2"></path>

        <path fill="none" d="M407.97 279.53L407.97 282.2"></path>

        <path fill="none" d="M407.97 274.44L407.97 276.38"></path>

        <path fill="none" d="M404.12 274.44L404.12 282.2"></path>

        <path fill="none" d="M400.27 279.77L400.27 282.2"></path>

        <path fill="none" d="M400.27 274.44L400.27 278.55"></path>

        <path fill="none" d="M393.3 274.44L393.3 282.2"></path>

        <path fill="none" d="M384.88 279.77L384.88 282.2"></path>

        <path fill="none" d="M384.88 274.44L384.88 277.11"></path>

        <path fill="none" d="M382 274.44L382 282.2"></path>

        <path fill="none" d="M377.19 279.05L377.19 282.2"></path>

        <path fill="none" d="M377.19 274.44L377.19 277.11"></path>
      </g>

      <g stroke="#263238" strokeMiterlimit="10">
        <ellipse
          cx="204.98"
          cy="298.78"
          fill="#CECECE"
          rx="33.03"
          ry="25.77"
          transform="rotate(-78.94 205.007 298.789)"
        ></ellipse>

        <path fill="#CECECE" d="M327.42 267.64s20.53-31.7 44.45-26.57c15.93 3.42 6.57 33.73 6.57 33.73"></path>

        <path
          fill="#263238"
          d="M373.1 277.77h.07l1.46-.38s9.15-27.8-4.18-27.45c-11.25.29-26.38 26.26-30.83 34.34 8.67-6.03 20.73-8.73 33.48-6.51zM274 413.12l-5.61 24.76A16.91 16.91 0 01251.92 451h0a16.89 16.89 0 01-16.48-13.16l-9.88-43.57M359.76 413.12L354.13 438a16.78 16.78 0 01-16.37 13h0a16.78 16.78 0 01-16.42-13.29l-5.62-26.35"
        ></path>

        <path
          fill="#CECECE"
          d="M409.22 339.09c0 30.23-16 57.32-41.25 75.55-20.6 14.88-47.35 23.86-76.59 23.86-65.08 0-117.84-44.51-117.84-99.41s52.76-99.42 117.84-99.42 117.84 44.51 117.84 99.42z"
        ></path>

        <path fill="none" d="M387.56 387.44a100.89 100.89 0 01-23.42 23.42 115.29 115.29 0 01-19.94 11.52"></path>

        <path fill="#fff" d="M400.33 360.87a83.58 83.58 0 01-6.06 15.48"></path>

        <path
          fill="none"
          d="M197 288.28a98.52 98.52 0 0112.61-13.69M191.39 381.6a81.55 81.55 0 010-85.05M344.2 422.38a128.33 128.33 0 01-52.82 11.15 127 127 0 01-59.47-14.42M345.34 284.44c0 5.81-2.52 10.51-5.63 10.51-2.81 0-5.15-3.84-5.57-8.87M374.42 281.94c0 4.3-1.87 7.78-4.17 7.78-2.09 0-3.81-2.85-4.12-6.58"
        ></path>

        <path
          fill="#fff"
          d="M315.66 247.62A50.27 50.27 0 00310 250c-1.13.55-2 1.07-2.75 1.5-1.49-.18-3-.33-4.49-.45-3.73-.33-7.52-.49-11.35-.49a129.78 129.78 0 00-48.19 9.13q-3.84 1.53-7.51 3.3a61.89 61.89 0 0114.12-10.87 130.53 130.53 0 0141.58-6.7 132.49 132.49 0 0124.25 2.2z"
        ></path>

        <path
          fill="#263238"
          d="M310 250l-7.27 1c-3.73-.33-7.52-.49-11.35-.49a129.78 129.78 0 00-48.19 9.13 65 65 0 016.61-4.29 130.81 130.81 0 0141.58-6.71A133.81 133.81 0 01310 250z"
        ></path>

        <ellipse cx="372.17" cy="326.53" fill="#263238" rx="12.46" ry="29.32"></ellipse>

        <circle cx="380.75" cy="317.27" r="29.32" fill="#CECECE"></circle>

        <circle cx="388.77" cy="317.27" r="29.32" fill="#CECECE"></circle>

        <ellipse cx="380.31" cy="317.71" fill="#263238" rx="6.17" ry="10.18"></ellipse>

        <ellipse cx="402.4" cy="316.13" fill="#263238" rx="6.17" ry="10.18"></ellipse>

        <path
          fill="#CECECE"
          d="M245.58 413.12L240 437.88A16.9 16.9 0 01223.48 451h0A16.9 16.9 0 01207 437.88l-9.88-43.57M329.58 413.12L323.94 438a16.78 16.78 0 01-16.37 13h0a16.79 16.79 0 01-16.42-13.29l-5.62-26.35M280.47 283.85s3-36.78 28.18-47.16c16.79-6.92 23.64 22.1 23.64 22.1"
        ></path>

        <path
          fill="#263238"
          d="M328.78 264.28h.08l1.18-1.17s-5.74-26.94-18.13-18.68c-10.46 7-11.31 36.09-11.32 45 5.05-9.9 15.02-19.22 28.19-25.15z"
        ></path>
      </g>

      <g>
        <circle cx="275.94" cy="216.36" r="27.36" fill="#fff" stroke="#263238" strokeLinejoin="round"></circle>

        <circle cx="272.72" cy="216.36" r="27.36" fill="#fff" stroke="#263238" strokeLinejoin="round"></circle>

        <circle cx="272.72" cy="216.36" r="21.99" fill="#fff" stroke="#263238" strokeLinejoin="round"></circle>

        <path
          fill="#fff"
          stroke="#263238"
          strokeLinejoin="round"
          d="M252.62 216.36a22 22 0 0121.05-22h-.95a22 22 0 100 44h.95a22 22 0 01-21.05-22z"
        ></path>

        <path fill="none" stroke="#263238" strokeLinejoin="round" d="M293.11 195.26L291.02 195.26"></path>

        <path fill="none" stroke="#263238" strokeLinejoin="round" d="M295.77 197.73L294.25 197.73"></path>

        <path fill="none" stroke="#263238" strokeLinejoin="round" d="M299.18 201.53L297.48 201.53"></path>

        <path fill="none" stroke="#263238" strokeLinejoin="round" d="M301.08 206.28L298.8 206.28"></path>

        <path fill="none" stroke="#263238" strokeLinejoin="round" d="M302.79 211.41L300.7 211.41"></path>

        <path fill="none" stroke="#263238" strokeLinejoin="round" d="M302.79 222.24L300.51 222.24"></path>

        <path fill="none" stroke="#263238" strokeLinejoin="round" d="M293.87 235.16L295.77 235.16"></path>

        <path fill="none" stroke="#263238" strokeLinejoin="round" d="M290.64 237.63L292.73 237.63"></path>

        <path fill="none" stroke="#263238" strokeLinejoin="round" d="M287.22 240.29L289.31 240.29"></path>

        <path fill="none" stroke="#263238" strokeLinejoin="round" d="M289.5 192.61L286.27 192.61"></path>

        <path
          fill="#263238"
          d="M271.59 208.87c0-2 .79-2.72 2.17-2.72s2.17.75 2.17 2.72V210H280v-.86c0-3.75-1.58-6.12-4.65-6.75v-2.05h-3.55v2.05c-3 .67-4.57 3-4.57 6.75 0 7.88 8.47 8.16 8.47 13.84 0 2-.86 2.68-2.24 2.68s-2.25-.71-2.25-2.68v-2.17h-4.1v1.89c0 3.75 1.57 6.15 4.69 6.78v2h3.55v-2c3.07-.63 4.69-3 4.69-6.78.03-7.88-8.45-8.15-8.45-13.83z"
        ></path>

        <circle
          cx="322.66"
          cy="157.4"
          r="27.36"
          fill="#fff"
          stroke="#263238"
          strokeLinejoin="round"
          transform="rotate(-58.6 322.645 157.406)"
        ></circle>

        <circle
          cx="319.44"
          cy="157.4"
          r="27.36"
          fill="#fff"
          stroke="#263238"
          strokeLinejoin="round"
          transform="rotate(-22.7 319.423 157.404)"
        ></circle>

        <circle
          cx="319.44"
          cy="157.4"
          r="21.99"
          fill="#fff"
          stroke="#263238"
          strokeLinejoin="round"
          transform="rotate(-67.81 319.468 157.4)"
        ></circle>

        <path
          fill="#fff"
          stroke="#263238"
          strokeLinejoin="round"
          d="M299.35 157.4a22 22 0 0121-22h-.95a22 22 0 000 44h.95a22 22 0 01-21-22z"
        ></path>

        <path fill="none" stroke="#263238" strokeLinejoin="round" d="M339.83 136.3L337.74 136.3"></path>

        <path fill="none" stroke="#263238" strokeLinejoin="round" d="M342.49 138.77L340.97 138.77"></path>

        <path fill="none" stroke="#263238" strokeLinejoin="round" d="M345.91 142.57L344.2 142.57"></path>

        <path fill="none" stroke="#263238" strokeLinejoin="round" d="M347.81 147.32L345.53 147.32"></path>

        <path fill="none" stroke="#263238" strokeLinejoin="round" d="M349.52 152.45L347.43 152.45"></path>

        <path fill="none" stroke="#263238" strokeLinejoin="round" d="M349.52 163.27L347.24 163.27"></path>

        <path fill="none" stroke="#263238" strokeLinejoin="round" d="M340.59 176.19L342.49 176.19"></path>

        <path fill="none" stroke="#263238" strokeLinejoin="round" d="M337.36 178.66L339.45 178.66"></path>

        <path fill="none" stroke="#263238" strokeLinejoin="round" d="M333.94 181.32L336.03 181.32"></path>

        <path fill="none" stroke="#263238" strokeLinejoin="round" d="M336.22 133.64L332.99 133.64"></path>

        <path
          fill="#263238"
          d="M326.05 152.37c1.82-.77 2.81-.33 3.35.94s.15 2.29-1.66 3.05l-1.06.45 1.6 3.78.8-.34c3.45-1.46 5-3.83 4.4-6.91l1.89-.8-1.37-3.27-1.89.8c-1.8-2.54-4.58-3-8-1.59-7.27 3.06-4.23 11-9.46 13.19-1.81.77-2.81.24-3.34-1s-.22-2.34 1.6-3.11l2-.84-1.59-3.78-1.74.73c-3.46 1.46-5.06 3.85-4.43 7l-1.85.79 1.38 3.27 1.85-.79c1.78 2.59 4.62 3.15 8.07 1.69 7.26-3.14 4.22-11.06 9.45-13.26z"
        ></path>

        <circle
          cx="276.38"
          cy="103.41"
          r="27.36"
          fill="#fff"
          stroke="#263238"
          strokeLinejoin="round"
          transform="rotate(-45 276.383 103.408)"
        ></circle>

        <circle
          cx="273.16"
          cy="103.41"
          r="27.36"
          fill="#fff"
          stroke="#263238"
          strokeLinejoin="round"
          transform="rotate(-22.7 273.147 103.415)"
        ></circle>

        <circle
          cx="273.16"
          cy="103.41"
          r="21.99"
          fill="#fff"
          stroke="#263238"
          strokeLinejoin="round"
          transform="rotate(-22.66 273.16 103.4)"
        ></circle>

        <path
          fill="#fff"
          stroke="#263238"
          strokeLinejoin="round"
          d="M253.07 103.41a22 22 0 0121-22h-.95a22 22 0 000 44h.95a22 22 0 01-21-22z"
        ></path>

        <path fill="none" stroke="#263238" strokeLinejoin="round" d="M293.55 82.31L291.46 82.31"></path>

        <path fill="none" stroke="#263238" strokeLinejoin="round" d="M296.21 84.78L294.69 84.78"></path>

        <path fill="none" stroke="#263238" strokeLinejoin="round" d="M299.63 88.58L297.92 88.58"></path>

        <path fill="none" stroke="#263238" strokeLinejoin="round" d="M301.53 93.33L299.25 93.33"></path>

        <path fill="none" stroke="#263238" strokeLinejoin="round" d="M303.24 98.45L301.15 98.45"></path>

        <path fill="none" stroke="#263238" strokeLinejoin="round" d="M303.24 109.28L300.96 109.28"></path>

        <path fill="none" stroke="#263238" strokeLinejoin="round" d="M294.31 122.2L296.21 122.2"></path>

        <path fill="none" stroke="#263238" strokeLinejoin="round" d="M291.08 124.67L293.17 124.67"></path>

        <path fill="none" stroke="#263238" strokeLinejoin="round" d="M287.66 127.33L289.75 127.33"></path>

        <path fill="none" stroke="#263238" strokeLinejoin="round" d="M289.94 79.65L286.71 79.65"></path>

        <path
          fill="#263238"
          d="M268.52 98.15c-1.08-1.65-.82-2.71.33-3.46s2.23-.56 3.3 1.1l.63.95 3.44-2.23-.48-.73c-2-3.14-4.65-4.26-7.57-3.12l-1.12-1.72-3 1.94 1.12 1.72c-2.18 2.21-2.18 5-.16 8.14 4.3 6.62 11.56 2.23 14.65 7 1.07 1.65.73 2.72-.42 3.47s-2.27.63-3.35-1l-1.18-1.82-3.44 2.24 1 1.58c2 3.14 4.68 4.3 7.63 3.13l1.1 1.69 3-1.93-1.09-1.69c2.23-2.2 2.28-5.1.24-8.24-4.28-6.65-11.54-2.26-14.63-7.02z"
        ></path>
      </g>

      <g fill="none" stroke="#263238" strokeMiterlimit="10">
        <path d="M45.07 451.04L23.89 451.04"></path>

        <path d="M479.4 451.04L56.73 451.04"></path>
      </g>

      <rect width="500" height="500" fill="currentColor" style={{ mixBlendMode: 'overlay' }} />
    </>
  ),
});
export default IconPiggyBank;
