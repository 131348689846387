'use client';

import { Button, CardHeader, Flex, Heading, Skeleton, Text } from '@chakra-ui/react';
import { CfCard, IconCaretRight, IconInfo } from '@cryptofi/core-ui';
import { range } from 'lodash';
/* eslint-disable-next-line no-restricted-imports */
import NextLink from 'next/link';

import { InvestmentCard } from '~/components';
import { useGetUser, useGlobalModalContext, useOnboardingInfo, useUserInvestments } from '~/hooks';
import { uiRoutes } from '~/routes';
import { fiConfigs } from '~/theme';

import NewProductAvailable from './NewProductAvailable';

const InvestmentsList = () => {
  const { onOpenModal } = useGlobalModalContext();
  const {
    data: { securityInvestments, cryptoInvestments, investmentCount },
    isLoading,
    isSuccess,
    isError,
  } = useUserInvestments();
  const user = useGetUser();
  const { isOnboardedSecurities } = useOnboardingInfo();

  const handleClickViewAllInvestments = () => {
    // TODO: track telemtry event for investments page
  };

  const getFlexBasis = () => {
    if (investmentCount === 1) {
      return '100%';
    }
    if (investmentCount === 2) {
      return { base: '100%', md: 'calc(50% - 0.25rem)' };
    }
    return { base: '100%', md: 'calc(50% - 0.25rem)', xl: 'calc(33.33% - 0.333rem)' };
  };

  const hasInvestments = investmentCount > 0;
  const showViewAllLink = (user.data?.balance?.totalOrders || 0) > 0 || investmentCount > 0;

  return (
    <CfCard>
      <CardHeader p="0" display="flex" justifyContent="space-between" alignItems="center">
        <Heading as="h1" size="md">
          Your investments
        </Heading>

        {showViewAllLink && (
          <Button
            rightIcon={<IconCaretRight />}
            variant="ghost"
            as={NextLink}
            href={uiRoutes.investments()}
            onClick={handleClickViewAllInvestments}
            flexShrink="0"
          >
            View all
          </Button>
        )}
      </CardHeader>

      {fiConfigs?.ufcu?.id === user.data?.fiId && !isOnboardedSecurities && (
        <NewProductAvailable product="securities" />
      )}

      <>
        {isSuccess && !hasInvestments && (
          <Flex direction="column" gap="4">
            <Text fontSize="sm">
              Welcome to your investments section! Ready to take the first step towards building your portfolio?
            </Text>

            <Text fontSize="sm">Place your first trade now and kickstart your investment journey.</Text>

            <Flex gap="4" mt="4" justifyContent="right">
              <Button
                as={NextLink}
                href={uiRoutes.learn()}
                px="6"
                w={{ md: 'auto', sm: 'full' }}
                rightIcon={<IconInfo />}
                variant="secondary"
              >
                Learn
              </Button>

              <Button onClick={onOpenModal} px="6" w={{ md: 'auto', sm: 'full' }}>
                Invest
              </Button>
            </Flex>
          </Flex>
        )}

        {isLoading && (
          <Flex flexWrap="wrap" gap="2" w="full">
            {range(6).map((_, i) => (
              <Flex
                key={i}
                flexBasis={getFlexBasis()}
                minW="14rem"
                h="7.25rem" //hardcoded to match height of populated InvestmentCard
              >
                <Skeleton w="full">
                  <InvestmentCard investment={{ assetId: 'BTC' }} />
                </Skeleton>
              </Flex>
            ))}
          </Flex>
        )}

        {isSuccess && hasInvestments && (
          <Flex flexWrap="wrap" gap="2" w="full">
            {[...cryptoInvestments, ...securityInvestments]
              // eslint-disable-next-line no-restricted-syntax
              .sort((a, b) => (Number(b?.amountUsd) || 0) - (Number(a?.amountUsd) || 0))
              .slice(0, 6)
              .map((investment) => (
                <Flex key={investment.assetId} flexBasis={getFlexBasis()} minW="14rem">
                  <InvestmentCard investment={investment} variant={investmentCount === 1 ? 'detail' : 'simple'} />
                </Flex>
              ))}
          </Flex>
        )}

        {isError && (
          <Flex flexDir="column" gap="3" alignItems="center">
            <Heading as="h2" size="sm">
              Something went wrong
            </Heading>

            <Text>Your investments could not be loaded. Please try again later.</Text>
          </Flex>
        )}
      </>
    </CfCard>
  );
};

export default InvestmentsList;
