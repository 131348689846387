/**
 * @description Set the FI brand theme, favicon, and title based on the subdomain.
 */

'use client';

import { useEffect, useState } from 'react';

import { fiConfigs } from '~/theme';
import { getFiName } from '~/utils';

const useBrandTheme = () => {
  const [brandTheme, setBrandTheme] = useState(fiConfigs.doge.brand);

  useEffect(() => {
    const fiName = getFiName();

    const brandTheme = fiConfigs[fiName].brand;
    setBrandTheme(brandTheme);

    //set FI Favicon
    let link = document.querySelector("link[rel~='icon']") as HTMLLinkElement | null;
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.head.appendChild(link);
    }
    link.href = fiConfigs[fiName].iconPath;

    //set FI metadata title
    let title = document.querySelector('title') as HTMLTitleElement | null;
    if (!title) {
      title = document.createElement('title');
      document.head.appendChild(title);
    }
    title.innerHTML = fiConfigs[fiName].title;
  }, []);

  return {
    brandTheme,
  };
};

export default useBrandTheme;
