import { useQuery } from '@tanstack/react-query';
import { camelizeKeys } from 'humps';

import { useAxiosContext, useFeatureSetEnabled, useGlobalStore } from '~/hooks';
import { apiRoutes } from '~/routes';

const useGetSecurityMetrics = ({ ticker }: { ticker: string; enabled?: boolean }) => {
  const { apiClient } = useAxiosContext();
  const url = apiRoutes.intrinio.getSecurityMetrics({ ticker });
  const [userAuthInfo] = useGlobalStore((state) => [state.userAuthInfo]);
  const { isEnabled } = useFeatureSetEnabled();

  return useQuery({
    enabled: Boolean(userAuthInfo) && isEnabled(['securities']),
    queryKey: [url, ticker, apiClient],
    queryFn: async () => {
      const response = await apiClient!.get(url);

      return camelizeKeys(response?.data);
    },
  });
};

export default useGetSecurityMetrics;
