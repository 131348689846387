'use client';
import { Flex, Text } from '@chakra-ui/react';
import { CfCard, IconArrowUp } from '@cryptofi/core-ui';

import { AssetLogo } from '~/components';

export default function TopPerformersCard() {
  return (
    <CfCard gap={0}>
      <AssetLogo showId assetId="BTC" />

      <Text>Bitcoin</Text>

      <Flex alignItems="end" justifyContent="space-between" flexDir="row">
        <Text>$64,007.72</Text>

        <Flex flexDir="column" alignItems="end">
          <IconArrowUp />

          <Text color="brand.500">+2.5%</Text>
        </Flex>
      </Flex>
    </CfCard>
  );
}
