import { isIframe } from '@cryptofi/core-ui';
import * as Sentry from '@sentry/nextjs';

export const logError = ({ error, context }: { error: Error; context?: any }) => {
  Sentry.captureException(error, {
    extra: {
      ...context,
      isIframe: isIframe(),
    },
  });
};
