import { Flex, Heading, Table, Tbody, Td, Text, Tr } from '@chakra-ui/react';
import { CfLineChart, formatPercentage, formatUsd, getDelta, uiColors } from '@cryptofi/core-ui';
import { useFormContext } from 'react-hook-form';

import { AssetLogo } from '~/components';
import { AllAssetIds, CryptoAsset } from '~/customTypes';
import { uiConstants } from '~/theme';

import { useModalContext } from './ModalContext';

const SelectCryptoAsset = ({ tokenPrices }: { tokenPrices: CryptoAsset[] }) => {
  const {
    setModalView,
    setBuyCurrency,
    setSelectedAssetId,
    transactionType,
    setSellCurrency,
    amountAvailableUsd,
    amountAvailableAsset,
  } = useModalContext();
  const { setValue } = useFormContext();

  const setSelectedAsset = ({ baseToken }: { baseToken: AllAssetIds }) => {
    setModalView('startTransaction');
    setSelectedAssetId(baseToken);

    // update hidden inputs
    setValue('sellAmountAvailableUsd', amountAvailableUsd);
    setValue('sellAmountAvailableAsset', amountAvailableAsset);

    if (transactionType === 'buy') {
      setBuyCurrency('USD');
      setValue('buyIsUsd', true);
    }

    if (transactionType === 'sell') {
      setSellCurrency('USD');
      setValue('sellIsUsd', true);
    }
  };

  return (
    <Flex py="4">
      <Flex flexDir="column" w="full" mb="12">
        <Heading as="h2" fontSize="md" mb="6">
          Available assets
        </Heading>

        <Table>
          <Tbody>
            {tokenPrices?.map(({ baseToken, sparkline, buyPrice }) => {
              const { isNetGain, percentChange } = getDelta({
                data: sparkline,
                dataKey: 'value',
              });

              return (
                <Tr
                  key={baseToken}
                  cursor="pointer"
                  onClick={() => setSelectedAsset({ baseToken })}
                  backgroundColor="transparent"
                  transition={`background-color ${uiConstants.transitionDuration}`}
                  data-testid={`available-asset-${baseToken}`}
                  _hover={{
                    backgroundColor: uiConstants.hoverBackground,
                  }}
                >
                  <Td p="0" pl={4}>
                    <AssetLogo assetId={baseToken} showName showId />
                  </Td>

                  {/* height must be set on this table cell for charts to render */}
                  <Td p="2" height="4.5rem">
                    <CfLineChart
                      data={sparkline}
                      dataKey="value"
                      sampleRate={4}
                      style={{ pointerEvents: 'none', maxWidth: '12rem', marginLeft: 'auto', marginRight: 'auto' }}
                    />
                  </Td>

                  <Td textAlign="right" fontFamily="heading" p="0" pr="4" fontSize="sm">
                    <Flex
                      outline="solid 2px white"
                      bg={isNetGain ? uiColors.aijiroWhite() : uiColors.redRemains()}
                      borderRadius="sm"
                      p={1}
                      flexDir="column"
                      width="24"
                      ml="auto"
                    >
                      <Text>
                        {formatUsd({
                          amount: buyPrice,
                          autoPrecision: true,
                        })}
                      </Text>

                      <Text fontSize="xs" color={isNetGain ? uiColors.mint() : uiColors.heroicRed()}>
                        {formatPercentage({ number: percentChange, isDecimal: false })}
                      </Text>
                    </Flex>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </Flex>
    </Flex>
  );
};

export default SelectCryptoAsset;
