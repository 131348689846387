'use client';
import { Skeleton, Td, Tr } from '@chakra-ui/react';

const AssetListItemLoading = ({ applyBorderRadius = false }) => {
  return (
    <Tr backgroundColor="transparent" width="full">
      <Td p="4" borderBottomLeftRadius={applyBorderRadius ? 'xl' : 'none'} colSpan={2} height="4.5rem">
        <Skeleton width="8rem" height="full" />
      </Td>

      <Td
        textAlign="right"
        fontFamily="heading"
        p="4"
        pr="0"
        borderBottomRightRadius={applyBorderRadius ? 'xl' : 'none'}
        height="4.5rem"
      >
        <Skeleton width="24" height="100%" ml="auto" mr="4" />
      </Td>
    </Tr>
  );
};

export default AssetListItemLoading;
