import { useQuery } from '@tanstack/react-query';
import { Camelized, camelizeKeys } from 'humps';

import { TokenHistoricalPricingResponse } from '~/customTypes';
import { useAxiosContext, useGlobalStore } from '~/hooks';
import { apiRoutes } from '~/routes';
import { ignoreUpperCaseKeys } from '~/utils';

const useGetTokenPriceHistory = () => {
  const userAuthInfo = useGlobalStore((state) => state.userAuthInfo);
  const { apiClient } = useAxiosContext();

  return useQuery({
    enabled: Boolean(userAuthInfo),
    queryKey: [apiRoutes.priceHistory(), apiClient],
    queryFn: async () => {
      return apiClient!.get(apiRoutes.priceHistory()).then((r) => {
        return camelizeKeys(r.data, { process: ignoreUpperCaseKeys }) as Camelized<TokenHistoricalPricingResponse>;
      });
    },
  });
};

export default useGetTokenPriceHistory;
