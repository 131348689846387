'use client';

import { Flex, Heading, Table, Tbody, Td, Text, Tr } from '@chakra-ui/react';
import { CfLineChart, uiColors } from '@cryptofi/core-ui';
import { size } from 'lodash';
import { useRouter } from 'next/navigation';

import { TelemetryClientSideEventsEnum } from '~/codegen/types';
import { AssetListItem, AssetsSearchButton } from '~/components';
import { securitiesRefetchInterval } from '~/constants';
import {
  useFeatureSetEnabled,
  useGetFiInfo,
  useGetSecurities,
  useGetTokenPrices,
  useGetUser,
  usePostTelemetryEvent,
} from '~/hooks';
import { uiRoutes } from '~/routes';
import { getExploreAssetsSecurities } from '~/utils';

import AssetListItemLoading from './AssetListItemLoading';

// TODO error and loading states
const AssetsList = () => {
  const { isEnabled } = useFeatureSetEnabled();
  const user = useGetUser();
  const router = useRouter();

  const { trackEvent } = usePostTelemetryEvent();

  const featuredSecurities = getExploreAssetsSecurities(user?.data?.securitiesRiskProfile);

  const tokenPrices = useGetTokenPrices();
  const securitiesPrices = useGetSecurities({
    tickers: featuredSecurities.join(','),
    refetchInterval: securitiesRefetchInterval,
  });
  const handleAssetClick = ({ type, assetId }: { type: 'token' | 'security'; assetId: string }) => {
    trackEvent(TelemetryClientSideEventsEnum.ClickedCryptoHomeAssetButtonClient, { asset: assetId });
    if (type === 'security') {
      router.push(uiRoutes.assetDetailsSecurities(assetId));
    }
    if (type === 'token') {
      router.push(uiRoutes.assetDetailsCrypto(assetId));
    }
  };

  const fiInfo = useGetFiInfo();

  return (
    <>
      <Flex flexDir="column">
        <Flex
          justifyContent="space-between"
          borderBottom="solid 1px"
          borderColor={uiColors.coolElegance()}
          alignItems="center"
          pr={4}
        >
          <Heading as="h2" size="md" borderColor={uiColors.coolElegance()} p="4">
            {isEnabled(['securities']) ? 'Explore assets' : 'Assets'}
          </Heading>

          {isEnabled(['securities']) && <AssetsSearchButton />}
        </Flex>

        <Table>
          <Tbody>
            {isEnabled(['securities']) && (
              <>
                <Tr>
                  <Td colSpan={100} px="4" py="3">
                    <Flex justifyContent="space-between">
                      <Heading as="h3" fontSize="md" color={uiColors.sonicSilver()}>
                        Stocks
                      </Heading>

                      <Text fontSize="2xs" color={uiColors.sonicSilver()}>
                        Offered by CDS1 Securities LLC
                      </Text>
                    </Flex>
                  </Td>
                </Tr>

                {securitiesPrices.isLoading && (
                  <>
                    {Array.from({ length: featuredSecurities.length }).map((_, i) => (
                      <AssetListItemLoading key={i} />
                    ))}
                  </>
                )}

                {securitiesPrices.data?.map((security, i) => (
                  <AssetListItem
                    key={security.securitiesTicker}
                    assetId={security.securitiesTicker || ''}
                    assetName={security.name || ''}
                    previousClosingPrice={security.lastTradingDayInfo?.closePrice || undefined}
                    buyPrice={security.currentPrice?.value || undefined}
                    onAssetClick={() =>
                      handleAssetClick({ type: 'security', assetId: security.securitiesTicker || '' })
                    }
                    // eslint-disable-next-line no-restricted-syntax
                    isLastRow={!isEnabled(['crypto']) && i + 1 === size(securitiesPrices.data)}
                  />
                ))}
              </>
            )}

            {isEnabled(['crypto']) && (
              <>
                <Tr>
                  <Td colSpan={100} px="4" py="3">
                    <Flex justifyContent="space-between">
                      <Heading as="h3" fontSize="md" color={uiColors.sonicSilver()}>
                        Cryptocurrency
                      </Heading>

                      <Text fontSize="2xs" color={uiColors.sonicSilver()}>
                        Offered by SAFE Trust Co.
                      </Text>
                    </Flex>
                  </Td>
                </Tr>

                {tokenPrices.isLoading && (
                  <>
                    {Array.from({ length: fiInfo.data?.enabledFeatureSet.enabledAssets?.length || 0 }).map((_, i) => (
                      <AssetListItemLoading key={i} />
                    ))}
                  </>
                )}

                {tokenPrices.data?.map((token, i) => {
                  const hasChange = token.sparkline?.every((item, _, array) => item.value !== array[0].value);

                  return (
                    <AssetListItem
                      key={token.baseToken}
                      assetId={token.baseToken}
                      previousClosingPrice={token.sparkline?.[0].value}
                      buyPrice={token.buyPrice}
                      onAssetClick={() => handleAssetClick({ type: 'token', assetId: token.baseToken })}
                      // eslint-disable-next-line no-restricted-syntax
                      isLastRow={i + 1 === size(tokenPrices.data)}
                    >
                      {token.sparkline && hasChange && (
                        <CfLineChart
                          data={token.sparkline}
                          dataKey="value"
                          sampleRate={4}
                          style={{ pointerEvents: 'none', maxWidth: '12rem', marginLeft: 'auto', marginRight: 'auto' }}
                        />
                      )}
                    </AssetListItem>
                  );
                })}
              </>
            )}
          </Tbody>
        </Table>
      </Flex>
    </>
  );
};

export default AssetsList;
