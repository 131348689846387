import { AxiosInstance } from 'axios';
import { createContext, useState } from 'react';

// eslint-disable-next-line no-restricted-imports
import useAxios from '~/hooks/api/useAxios';

export const AxiosContext = createContext<{
  apiClient?: AxiosInstance;
  setHeaders?: React.Dispatch<React.SetStateAction<any>>;
}>({});

export const AxiosProvider = ({ children }: { children: React.ReactElement }) => {
  const apiClient = useAxios();
  const [headers, setHeaders] = useState<any>();

  if (headers && apiClient) {
    apiClient.defaults.headers.common.Authorization = headers.Authorization;
    apiClient.defaults.headers.common['fi-id'] = headers['fi-id'];
    apiClient.defaults.headers.common['user-account-id'] = headers['user-account-id'];
  }

  return <AxiosContext.Provider value={{ apiClient, setHeaders }}>{children}</AxiosContext.Provider>;
};
