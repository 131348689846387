'use client';

import { Box, Flex, FlexProps, Heading, Skeleton, Text } from '@chakra-ui/react';
import { formatPercentage, formatUsd, IconArrowDown, IconArrowUp, uiColors } from '@cryptofi/core-ui';

interface Props extends FlexProps {
  isLoading?: boolean;
  amount?: number | string;
  percent?: number | string;
  heading: string;
  isNetGain: boolean;
}

const TrendIndicator = ({ isLoading, amount, percent, heading, isNetGain, ...rest }: Props) => {
  return (
    <Skeleton display="flex" gap="4" alignItems="center" w="fit-content" isLoaded={!isLoading} {...rest}>
      <Box bg={isNetGain ? uiColors.aijiroWhite() : uiColors.redRemains()} p="1" borderRadius="md" flexGrow="0">
        {isNetGain && <IconArrowUp __css={{ path: { fill: uiColors.mint() } }} />}

        {!isNetGain && <IconArrowDown __css={{ path: { fill: uiColors.heroicRed() } }} />}
      </Box>

      <Flex
        flexDir={{ base: 'column', sm: 'row' }}
        gap={{ base: 0, sm: 2 }}
        alignItems={{ base: 'flex-start', sm: 'center' }}
      >
        <Heading as="h2" size="xs" color={uiColors.sonicSilver()}>
          {heading}
        </Heading>

        {amount && Number.isFinite(amount) && (
          <Text fontFamily="heading" fontSize="lg">
            {formatUsd({ amount })}
          </Text>
        )}

        {percent && Number.isFinite(percent) && (
          <Text fontFamily="heading" fontSize="lg">
            {formatPercentage({ number: percent })}
          </Text>
        )}
      </Flex>
    </Skeleton>
  );
};

export default TrendIndicator;
