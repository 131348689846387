import { useQuery } from '@tanstack/react-query';
import { camelize, Camelized, camelizeKeys } from 'humps';
import { capitalize, upperFirst } from 'lodash';

import { KYCFieldsResponse } from '~/codegen/types';
import { usStates } from '~/constants';
import { KycFieldTypes, KycFormField, KycFormFieldCollection, Product } from '~/customTypes';
import { useAxiosContext, useGlobalStore } from '~/hooks';
import { apiRoutes } from '~/routes';

// transform select options string[] to object with text and value
const transformSelectOptions = (option: string) => {
  if (option === 'selfemployed') {
    return { name: 'Self-employed', value: option };
  }

  return { name: upperFirst(option), value: option };
};

const useGetKycFields = (args?: { enabled?: boolean; onboardingProducts: Product[] }) => {
  const userAuthInfo = useGlobalStore((state) => state.userAuthInfo);
  const { apiClient } = useAxiosContext();

  // product of undefined will make the request for all products
  const product = args?.onboardingProducts?.length === 1 ? args.onboardingProducts[0] : undefined;
  return useQuery({
    queryKey: [apiRoutes.kycFields(product), apiClient],
    enabled: args ? args.enabled && Boolean(userAuthInfo) : Boolean(userAuthInfo),
    queryFn: async () => {
      return apiClient!.get(apiRoutes.kycFields(product)).then((r) => {
        const data = camelizeKeys(r.data) as Camelized<KYCFieldsResponse>;
        const transformedFields: KycFormField[] = [];

        // TODO move these transformations to a separate module
        data.need?.forEach((field) => {
          // create a copy of the field object with camelization and user friendly label
          const transformedField = {
            ...field,
            label: capitalize(field.label),
            name: camelize(field.name) as KycFormField['name'],
            valueType: camelize(field.valueType!) as KycFieldTypes,
          };

          // handle select options
          if (transformedField.options) {
            transformedFields.push({
              ...transformedField,
              options: transformedField.options.map(transformSelectOptions),
            });

            // add user friendly state names to abbreviated options
            if (field.name === 'state') {
              const transformedState = transformedFields.find((f) => f.name === 'state');

              transformedState!.options = transformedState!.options.map(({ value }) => {
                return {
                  value,
                  name: `${value} - ${usStates[value as keyof typeof usStates]}`,
                };
              });
            }

            // add two letter country code to country names, currently limited to US only
            if (field.name === 'country') {
              const transformedCountry = transformedFields.find((f) => f.name === 'country');

              transformedCountry!.options = transformedCountry!.options.map(({ name }) => {
                return {
                  value: 'US',
                  name,
                };
              });
            }
          }
        });

        const updatedData: KycFormFieldCollection = {
          ...data,
          need: transformedFields,
        };

        return updatedData;
      });
    },
  });
};

export default useGetKycFields;
