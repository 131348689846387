import { Box, Button, Flex, Heading, Text } from '@chakra-ui/react';
import { IconAlert, IconCheck, uiColors } from '@cryptofi/core-ui';

import { Product } from '~/customTypes';

const KycResultsView = ({
  isOnboarded,
  onboardingError,
  onClose,
}: {
  isOnboarded: boolean;
  onboardingError: string | null;
  setOnboardingProducts: (products: Product[]) => void;
  onClose: () => void;
}) => {
  return (
    <>
      {isOnboarded && (
        <Flex gap="4" flexDirection="column" alignItems="center" justifyContent="center" pb="4">
          <Box backgroundColor="brand.500" borderRadius="full" p="4">
            <IconCheck
              __css={{
                path: { fill: 'white' },
              }}
            />
          </Box>

          <Heading as="h1" fontSize="xx-large" color="brand.500">
            You&apos;re all set!
          </Heading>

          <Text textAlign="center">Click below to get started investing directly from your bank account!</Text>

          <Button
            w="full"
            onClick={() => {
              onClose();
            }}
          >
            Start investing
          </Button>
        </Flex>
      )}

      {!isOnboarded && (
        <Flex gap="4" flexDirection="column" alignItems="center" justifyContent="center" py="4">
          <Box backgroundColor={uiColors.coolElegance()} borderRadius="full" p="4">
            <IconAlert
              w="6"
              h="6"
              __css={{
                path: { fill: 'white' },
              }}
            />
          </Box>

          <Text textAlign="center">
            {onboardingError ||
              'Your application is currently under review. You will receive an email from our support team if additional information is required.'}
          </Text>
        </Flex>
      )}
    </>
  );
};

export default KycResultsView;
