/* eslint-disable no-restricted-syntax */
import { useEffect, useMemo, useState } from 'react';

// cycle through loading messages and stop on the last one
const useLoadingMessages = ({ messages, interval = 3_000 }: { messages: string[]; interval?: number }) => {
  const loadingMessages = useMemo(() => messages, [messages]);

  const [currentMessage, setCurrentMessage] = useState(loadingMessages[0]);
  const [, setMessageIndex] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setMessageIndex((prevIndex) => {
        if (prevIndex + 1 === loadingMessages.length) {
          clearInterval(timer);
          return prevIndex;
        }

        const newIndex = prevIndex + 1;
        setCurrentMessage(loadingMessages[newIndex]);
        return newIndex;
      });
    }, interval);

    return () => clearInterval(timer);
  }, [loadingMessages, interval]);

  return {
    loadingMessages: currentMessage,
  };
};

export default useLoadingMessages;
