import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { Camelized, camelizeKeys } from 'humps';

import { User } from '~/codegen/types';
import { Product } from '~/customTypes';
import { useAxiosContext } from '~/hooks';
import { apiRoutes } from '~/routes';
import { ignoreUpperCaseKeys } from '~/utils';

const usePostTerms = ({
  options,
  onboardingProducts,
  setOnboardingProducts,
}: {
  options?: UseMutationOptions<Camelized<User>, void, void>;
  onboardingProducts: Product[];
  setOnboardingProducts: (products: Product[]) => void;
}) => {
  const queryClient = useQueryClient();
  const { apiClient } = useAxiosContext();

  // product of undefined will make the request for all products
  const product = onboardingProducts.length === 1 ? onboardingProducts[0] : undefined;

  return useMutation({
    mutationFn: async () => {
      return apiClient!.post(apiRoutes.terms(product)).then((r) => {
        const data = camelizeKeys(r.data, {
          process: ignoreUpperCaseKeys,
        }) as Camelized<User>;

        return data;
      });
    },
    onSuccess: () => {
      queryClient
        .invalidateQueries({
          predicate: (query) => {
            return query.queryKey.includes(apiRoutes.user());
          },
        })
        .then(() => {
          setOnboardingProducts([]);
        });
    },
    ...options,
  });
};

export default usePostTerms;
