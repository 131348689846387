import { AxiosError } from 'axios';
import get from 'lodash/get';

interface ErrorDetail {
  msg: string;
}

export const getErrorDetail = (error: AxiosError) => {
  const details = get(error, 'response.data.detail') as unknown as ErrorDetail[];
  const hasDetails = Array.isArray(details) && details.length > 0;

  // 400s and 451s should have human readable error messages, formatted as an array of strings
  if (hasDetails && (error?.response?.status === 400 || error?.response?.status === 451)) {
    return details.map((detail) => detail.msg);
  }

  if (!hasDetails || error?.response?.status !== 400) {
    return 'There was an error with the request';
  }
};
