import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  Flex,
  FlexProps,
  Heading,
  IconButton,
  useDisclosure,
} from '@chakra-ui/react';
import { IconCaretLeft, IconMenu, layoutMinWidth, uiColors, useCfBreakpoint } from '@cryptofi/core-ui';
import { useRouter } from 'next/navigation';
import { useEffect } from 'react';

import NavItems from './NavItems';

interface Props extends FlexProps {
  heading?: string;
  subHeading?: string;
  isSubPage?: boolean;
}

const MobileNav = ({ heading, subHeading, isSubPage, ...rest }: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isLargeBreakpoint } = useCfBreakpoint();
  const router = useRouter();

  // close the drawer if the window has been resized to the large breakpoint (or past)
  useEffect(() => {
    if (isLargeBreakpoint) {
      onClose();
    }
  }, [isLargeBreakpoint, onClose]);

  return (
    <Flex {...rest}>
      <Flex
        w="full"
        bg="white"
        flexDirection="row"
        px="4"
        py="4"
        alignItems="center"
        justifyContent="space-between"
        borderBottom={heading ? '1px' : ''}
        borderBottomColor={uiColors.coolElegance()}
        position="relative"
        left={0}
      >
        <Box position="absolute" left="4" top="4">
          {!isSubPage && (
            <Box
              cursor="pointer"
              width="8"
              onClick={() => {
                onOpen();
              }}
            >
              <IconMenu />
            </Box>
          )}

          {isSubPage && (
            <IconButton
              aria-label="Back to previous page"
              icon={<IconCaretLeft />}
              variant="ghost"
              size="sm"
              marginRight="4"
              onClick={() => router.back()}
            />
          )}
        </Box>

        <Flex flex={1} justifyContent="center" alignItems="center" gap={2} maxW="70vw" margin="0 auto">
          <Heading as="h1" fontSize="2xl">
            {heading}
          </Heading>

          {subHeading && (
            <Heading as="h2" fontSize="md" noOfLines={1} color={uiColors.sonicSilver()} fontFamily="body">
              {subHeading}
            </Heading>
          )}
        </Flex>
      </Flex>

      <Drawer
        placement="left"
        onClose={onClose}
        isOpen={isOpen}
        size={{
          base: 'full',
          sm: 'sm',
        }}
      >
        <DrawerContent minW={layoutMinWidth} pt="14" borderRight="solid 1px" borderColor={uiColors.coolElegance()}>
          <DrawerCloseButton />

          <DrawerBody>
            <Flex gap="4" flexDir="column" as="nav" h="full" pb={{ base: '32', sm: '4' }}>
              <NavItems />
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Flex>
  );
};

export default MobileNav;
